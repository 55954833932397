import React from 'react'
import Hero from '../Components/Hero/Hero'
import NoticeBoard from '../Components/NoticeBoard/NoticeBoard'
import About from '../Components/About/About';
import Characteristic from '../Components/Characteristic/Characteristic';
import Query from '../Components/Query/Query';

const Main = () => {
  return (
    <div>
        <Hero/>
        <NoticeBoard/>
      <Characteristic/>
      <About/>
      <Query/>
    </div>
  )
}

export default Main