
import './App.css';
import { BrowserRouter, Routes , Route } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import Main from './Pages/Main';
import Login from './Pages/Login';
import Books from './Pages/Books';
import Chapter from './Pages/Chapter';
import About from './Components/About/About';
import Query from './Components/Query/Query';
import Register from './Pages/Register';
import Chapterwisenotes from './Pages/Chapterwisenotes';
import Chapterwisenotessubject from './Pages/Chapterwisenotessubject';
import Exercisesolun from './Pages/Excercisesolun';
import Excercisesolunsubject from './Pages/Exercisesolunsubject';
import Sryinconv from './Components/SryInconvy/Sryinconv';
import Feedback from './Components/Feedback/Feedback';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path='/contactus' element={<Query/>}/>
        <Route path="/query" element={<Query/>}/>
        <Route path='/feedback' element={<Feedback/>}/>
        <Route path='/class1' element={< Books class="1"/>}/>
        <Route path='/class2' element={< Books class="2"/>}/>
        <Route path='/class3' element={< Books class="3"/>}/>
        <Route path='/class4' element={< Books class="4"/>}/>
        <Route path='/class5' element={< Books class="5"/>}/>
        <Route path='/class6' element={< Books class="6"/>}/>
        <Route path='/class7' element={< Books class="7"/>}/>
        <Route path='/class8' element={< Books class="8"/>}/>
        <Route path='/class9' element={< Books class="9"/>}/>
        <Route path='/class10' element={< Books class="10"/>}/>
        <Route path='/class11' element={< Books class="11"/>}/>
        <Route path='/class12' element={< Books class="12"/>}/>
        <Route path='/class1/maths' element={<Chapter class="1" name="Maths"/>}/>
        <Route path='/class1/hindi' element={<Chapter class="1" name="Hindi"/>}/>
        <Route path='/class1/english' element={<Chapter class="1" name="English"/>}/>
        <Route path='/class2/english' element={<Chapter class="2" name="English"/>}/>
        <Route path='/class2/maths' element={<Chapter class="2" name="Maths"/>}/>
        <Route path='/class2/hindi' element={<Chapter class="2" name="Hindi"/>}/>
        <Route path='/class3/english' element={<Chapter class="3" name="English"/>}/>
        <Route path='/class3/maths' element={<Chapter class="3" name="Maths"/>}/>
        <Route path='/class3/hindi' element={<Chapter class="3" name="Hindi"/>}/>
        <Route path='/class4/english' element={<Chapter class="4" name="English"/>}/>
        <Route path='/class4/maths' element={<Chapter class="4" name="Maths"/>}/>
        <Route path='/class4/hindi' element={<Chapter class="4" name="Hindi"/>}/>
        <Route path='/class4/evs' element={<Chapter class="4" name="Evs"/>}/>
        <Route path='/class5/english' element={<Chapter class="5" name="English"/>}/>
        <Route path='/class5/maths' element={<Chapter class="5" name="Maths"/>}/>
        <Route path='/class5/hindi' element={<Chapter class="5" name="Hindi"/>}/>
        <Route path='/class5/evs' element={<Chapter class="5" name="Evs"/>}/>
        <Route path='/class6/english' element={<Chapter class="6" name="English"/>}/>
        <Route path='/class6/sst' element={<Chapter class="6" name="Sst"/>}/>
        <Route path='/class6/hindi' element={<Chapter class="6" name="Hindi"/>}/>
        <Route path='/class6/science' element={<Chapter class="6" name="Science"/>}/>
        <Route path='/class6/sanskrit' element={<Chapter class="6" name="Sanskrit"/>}/>
        <Route path='/class7/an alien hand' element={<Chapter class="7" name="An Alien Hand"/>}/>
        <Route path='/class7/honeycomb' element={<Chapter class="7" name="Honeycomb"/>}/>
        <Route path='/class7/science' element={<Chapter class="7" name="Science"/>}/>
        <Route path='/class7/maths' element={<Chapter class="7" name="Maths"/>}/>
        <Route path='/class7/sanskrit' element={<Chapter class="7" name="Sanskrit"/>}/>
        <Route path='/class7/vasant' element={<Chapter class="7" name="Vasant"/>}/>
        <Route path='/class7/mahabharat' element={<Chapter class="7" name="Mahabharat"/>}/>
        <Route path='/class7/History' element={<Chapter class="7" name="History"/>}/>
        <Route path='/class7/Geography' element={<Chapter class="7" name="Geo"/>}/>
        <Route path='/class7/civics' element={<Chapter class="7" name="Civics"/>}/>

        <Route path='/class8/it so happened' element={<Chapter class="8" name="It So Happened"/>}/>
        <Route path='/class8/honeydew' element={<Chapter class="8" name="Honeydew"/>}/>
        <Route path='/class8/science' element={<Chapter class="8" name="Science"/>}/>
        <Route path='/class8/maths' element={<Chapter class="8" name="Maths"/>}/>
        <Route path='/class8/sanskrit' element={<Chapter class="8" name="Sanskrit"/>}/>
        <Route path='/class8/vasant' element={<Chapter class="8" name="Vasant"/>}/>
        <Route path='/class8/Bharat ki khoj' element={<Chapter class="8" name="Bharat ki Khoj"/>}/>
        <Route path='/class8/History' element={<Chapter class="8" name="History"/>}/>
        <Route path='/class8/Geography' element={<Chapter class="8" name="Geo"/>}/>
        <Route path='/class8/civics' element={<Chapter class="8" name="Civics"/>}/>

        <Route path='/class9/beehive' element={<Chapter class="9" name="Beehive"/>}/>
        <Route path='/class9/moments' element={<Chapter class="9" name="Moments"/>}/>
        <Route path='/class9/science' element={<Chapter class="9" name="Science"/>}/>
        <Route path='/class9/maths' element={<Chapter class="9" name="Maths"/>}/>
        <Route path='/class9/sanskrit' element={<Chapter class="9" name="Sanskrit"/>}/>
        <Route path='/class9/kritika' element={<Chapter class="9" name="Kritika"/>}/>
        <Route path='/class9/shitiz' element={<Chapter class="9" name="Shitiz"/>}/>
        <Route path='/class9/History' element={<Chapter class="9" name="History"/>}/>
        <Route path='/class9/Geography' element={<Chapter class="9" name="Geo"/>}/>
        <Route path='/class9/civics' element={<Chapter class="9" name="Civics"/>}/>
        <Route path='/class9/economics' element={<Chapter class="9" name="Economics"/>}/>

        <Route path='/class10/first flight' element={<Chapter class="10" name="First Flight"/>}/>
        <Route path='/class10/footprint' element={<Chapter class="10" name="Foot Print"/>}/>
        <Route path='/class10/science' element={<Chapter class="10" name="Science"/>}/>
        <Route path='/class10/maths' element={<Chapter class="10" name="Maths"/>}/>
        <Route path='/class10/sanskrit' element={<Chapter class="10" name="Sanskrit"/>}/>
        <Route path='/class10/kritika' element={<Chapter class="10" name="Kritika"/>}/>
        <Route path='/class10/shitiz' element={<Chapter class="10" name="Shitiz"/>}/>
        <Route path='/class10/History' element={<Chapter class="10" name="History"/>}/>
        <Route path='/class10/Geography' element={<Chapter class="10" name="Geo"/>}/>
        <Route path='/class10/civics' element={<Chapter class="10" name="Civics"/>}/>
        <Route path='/class10/economics' element={<Chapter class="10" name="Economics"/>}/>

        <Route path='/class11/maths' element={<Chapter class="11" name="Maths"/>}/>
        <Route path='/class11/hornbill' element={<Chapter class="11" name="Hornbill"/>}/>
        <Route path='/class11/snapshot' element={<Chapter class="11" name="Snapshot"/>}/>
        <Route path='/class11/antra' element={<Chapter class="11" name="Antra"/>}/>
        <Route path='/class11/vitan' element={<Chapter class="11" name="Vitan"/>}/>
        <Route path='/class11/chemistry part 1' element={<Chapter class="11" name="Chemistry Part 1"/>}/>
        <Route path='/class11/chemistry part 2' element={<Chapter class="11" name="Chemistry Part 2"/>}/>
        <Route path='/class11/Physics part 1' element={<Chapter class="11" name="Physics Part 1"/>}/>
        <Route path='/class11/Physics part 2' element={<Chapter class="11" name="Physics Part 2"/>}/>
        <Route path='/class11/computer science' element={<Chapter class="11" name="Computer Science"/>}/>
        <Route path='/class11/Information Technology' element={<Chapter class="11" name="Information Technology"/>}/>
        <Route path='/class11/Biology' element={<Chapter class="11" name="Biology"/>}/>
        <Route path='/class11/Accounts' element={<Chapter class="11" name="Accounts"/>}/>
        <Route path='/class11/Bussiness Studies' element={<Chapter class="11" name="Business Studies"/>}/>
        <Route path='/class11/Economics' element={<Chapter class="11" name="Economics"/>}/>
        <Route path='/class11/History' element={<Chapter class="11" name="History"/>}/>
        <Route path='/class11/Political Science' element={<Chapter class="11" name="Political Science"/>}/>
        <Route path='/class11/Bio technology' element={<Chapter class="11" name="Biotechnology"/>}/>
        <Route path='/class11/Human Studies 1' element={<Chapter class="11" name="Human Studies 1"/>}/>
        <Route path='/class11/Human Studies 2' element={<Chapter class="11" name="Human Studies 2"/>}/>
        <Route path='/class11/Geography' element={<Chapter class="11" name="Geography"/>}/>
        <Route path='/class11/Physical Education' element={<Chapter class="11" name="Physical Education"/>}/>
        <Route path='/class11/Fine Art' element={<Chapter class="11" name="Fienart"/>}/>
        <Route path='/class11/Srijan' element={<Chapter class="11" name="Srijan"/>}/>
        <Route path='/class11/Vistas' element={<Chapter class="11" name="Vistas"/>}/>
        <Route path='/class11/Sangeet' element={<Chapter class="11" name="Sangeet"/>}/>
        <Route path='/class11/Sanskrit' element={<Chapter class="11" name="Sanskrit"/>}/>
        <Route path='/class11/Sociology' element={<Chapter class="11" name="Sociology"/>}/>


        <Route path='/class12/maths 1' element={<Chapter class="12" name="Maths 1"/>}/>
        <Route path='/class12/maths 2' element={<Chapter class="12" name="Maths 2"/>}/>
        <Route path='/class12/Flamingo' element={<Chapter class="12" name="Flamingo"/>}/>
        <Route path='/class12/snapshot' element={<Chapter class="12" name="Snapshot"/>}/>
        <Route path='/class12/antra' element={<Chapter class="12" name="Antra"/>}/>
        <Route path='/class12/vitan' element={<Chapter class="12" name="Vitan"/>}/>
        <Route path='/class12/chemistry 1' element={<Chapter class="12" name="Chemistry 1"/>}/>
        <Route path='/class12/chemistry 2' element={<Chapter class="12" name="Chemistry 2"/>}/>
        <Route path='/class12/Physics 1' element={<Chapter class="12" name="Physics 1"/>}/>
        <Route path='/class12/Physics 2' element={<Chapter class="12" name="Physics 2"/>}/>
        <Route path='/class12/computer science' element={<Chapter class="12" name="Computer Science"/>}/>
        <Route path='/class12/Information Technology' element={<Chapter class="12" name="Information Technology"/>}/>
        <Route path='/class12/Biology' element={<Chapter class="12" name="Biology"/>}/>
        <Route path='/class12/Accounts 1' element={<Chapter class="12" name="Accounts 1"/>}/>
        <Route path='/class12/Accounts 2' element={<Chapter class="12" name="Accounts 2"/>}/>
        <Route path='/class12/Bussiness Studies 1' element={<Chapter class="12" name="Business Studies 1"/>}/>
        <Route path='/class12/Bussiness Studies 2' element={<Chapter class="12" name="Business Studies 2"/>}/>
        <Route path='/class12/Economics 1' element={<Chapter class="12" name="Economics 1"/>}/>
        <Route path='/class12/Economics 2' element={<Chapter class="12" name="Economics 2"/>}/>
        <Route path='/class12/History 1' element={<Chapter class="12" name="History 1"/>}/>
        <Route path='/class12/History 2' element={<Chapter class="12" name="History 2"/>}/>
        <Route path='/class12/Political Science' element={<Chapter class="12" name="Political Science"/>}/>
        <Route path='/class12/Bio technology' element={<Chapter class="12" name="Biotechnology"/>}/>
        <Route path='/class12/Human Studies 1' element={<Chapter class="12" name="Human Studies 1"/>}/>
        <Route path='/class12/Human Studies 2' element={<Chapter class="12" name="Human Studies 2"/>}/>
        <Route path='/class12/Geography' element={<Chapter class="12" name="Geography"/>}/>
        <Route path='/class12/Physical Education' element={<Chapter class="12" name="Physical Education"/>}/>
        <Route path='/class12/Fine Art' element={<Chapter class="12" name="Fineart"/>}/>
        <Route path='/class12/Srijan' element={<Chapter class="12" name="Srijan"/>}/>
        <Route path='/class12/Vistas' element={<Chapter class="12" name="Vistas"/>}/>
        <Route path='/class12/Sangeet' element={<Chapter class="12" name="Sangeet"/>}/>
        <Route path='/class12/Sanskrit' element={<Chapter class="12" name="Sanskrit"/>}/>
        <Route path='/class12/Sociology' element={<Chapter class="12" name="Sociology"/>}/>
        <Route path='/class12/psycology' element={<Chapter class="12" name="Psycology"/>}/>



        {/* chapterwise notes  */}
        <Route path='/chapterwisenotes/class6' element={< Chapterwisenotes class="6"/>}/>
        <Route path='/chapterwisenotes/class7' element={< Chapterwisenotes class="7"/>}/>
        <Route path='/chapterwisenotes/class8' element={<Sryinconv/>}/>
        <Route path='/chapterwisenotes/class9' element={<Sryinconv/>}/>
        <Route path='/chapterwisenotes/class10' element={<Sryinconv/>}/>
        <Route path='/chapterwisenotes/class11' element={<Sryinconv/>}/>
        <Route path='/chapterwisenotes/class12' element={<Sryinconv/>}/>

        <Route path='/chapterwisenotes/class6/Science' element={<Chapterwisenotessubject class="6" name="Science"/>}/>
        <Route path='/chapterwisenotes/class6/Geography' element={<Chapterwisenotessubject class="6" name="Geography"/>}/>
        <Route path='/chapterwisenotes/class7/Science' element={<Chapterwisenotessubject class="7" name="Science"/>}/>
        <Route path='/chapterwisenotes/class7/An Alien Hand' element={<Chapterwisenotessubject class="7" name="An Alien Hand"/>}/>
        <Route path='/chapterwisenotes/class7/Honeycomb' element={<Chapterwisenotessubject class="7" name="Honeycomb"/>}/>
        <Route path='/chapterwisenotes/class7/maths' element={<Sryinconv/>}/>
        <Route path='/chapterwisenotes/class7/sanskrit' element={<Sryinconv/>}/>
        <Route path='/chapterwisenotes/class7/vasant' element={<Sryinconv/>}/>
        <Route path='/chapterwisenotes/class7/mahabharat' element={<Sryinconv/>}/>
        <Route path='/chapterwisenotes/class7/History' element={<Sryinconv/>}/>
        <Route path='/chapterwisenotes/class7/Geography' element={<Sryinconv/>}/>
        <Route path='/chapterwisenotes/class7/civics' element={<Sryinconv/>}/>

        {/* Exercise solutions */}
        
        <Route path='/exercisesolutions/class6' element={< Exercisesolun class="6"/>}/>
        <Route path='/exercisesolutions/class7' element={< Exercisesolun class="7"/>}/>
        <Route path='/exercisesolutions/class8' element={<Sryinconv/>}/>
        <Route path='/exercisesolutions/class9' element={<Sryinconv/>}/>
        <Route path='/exercisesolutions/class10' element={<Sryinconv/>}/>
        <Route path='/exercisesolutions/class11' element={<Sryinconv/>}/>
        <Route path='/exercisesolutions/class12' element={<Sryinconv/>}/>

        
        <Route path='/exercisesolutions/class6/Science' element={<Excercisesolunsubject class="6" name="Science"/>}/>
        <Route path='/exercisesolutions/class6/Geography' element={<Excercisesolunsubject class="6" name="Geography"/>}/>

        <Route path='/exercisesolutions/class7/Science' element={<Excercisesolunsubject class="7" name="Science"/>}/>
        <Route path='/exercisesolutions/class7/An Alien Hand' element={<Excercisesolunsubject class="7" name="An Alien Hand"/>}/>
        <Route path='/exercisesolutions/class7/Honeycomb' element={<Excercisesolunsubject class="7" name="Honeycomb"/>}/>
        <Route path='/exercisesolutions/class7/maths' element={<Sryinconv/>}/>
        <Route path='/exercisesolutions/class7/sanskrit' element={<Sryinconv/>}/>
        <Route path='/exercisesolutions/class7/vasant' element={<Sryinconv/>}/>
        <Route path='/exercisesolutions/class7/mahabharat' element={<Sryinconv/>}/>
        <Route path='/exercisesolutions/class7/History' element={<Sryinconv/>}/>
        <Route path='/exercisesolutions/class7/Geography' element={<Sryinconv/>}/>
        <Route path='/exercisesolutions/class7/civics' element={<Sryinconv/>}/>
        {/* question bank and mock test */}

        <Route path='/Question Bank' element={<Sryinconv/>}/>
        <Route path='/Mock Test' element={<Sryinconv/>}/>

      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
