import React from 'react'
import './Books.css'
const Book = (props) => {
  return (
    <div >
      <div className='book'>
      <img src={props.image} alt='' />
      <p>{props.name}</p>
      </div>
    </div>
  )
}

export default Book