import React from "react";
import "./Hero.css";
import img1 from "../../Assets/Ncert book.png";
import img2 from "../../Assets/Mock Test.png";
import img3 from "../../Assets/Contact Us.png";
import img4 from "../../Assets/Ncert book.png";
import img5 from "../../Assets/Chapterwise ques banks.png";

const Hero = () => {
  return (
    <div className="hero">
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner slideshower">
          <div class="carousel-item active ">
            <img src={img1} class="hero-img" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img2} class="hero-img" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img3} class="hero-img" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img4} class="hero-img" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img5} class="hero-img" alt="..." />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon icons" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon icons" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Hero;
