import { useContext } from "react"
import React from 'react'
import { Bookcontext } from "../Context/Bookscontext";
import Book from "../Components/Book/Book";
import { Link } from "react-router-dom";
const Exercisesolun=(props)=>{
    const {all_books} = useContext(Bookcontext);
    return(
        <div className="all-books">
            <div className="Books-heading">
                <p>Class {props.class} NCERT</p> 
            </div>
            <div className="books">
                {all_books.map((item,i)=>{
                    if(props.class===item.class){
                        return <Link style={{textDecoration:'none' , color:'black'} } to={`/exercisesolutions/class${item.class}/${item.name}`}><Book name={item.name} image={item.image}/></Link>;
                    }
                    else{
                        return null;
                    }
                })}
            </div>
        </div>
    )
}
export default Exercisesolun;