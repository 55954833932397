import React from 'react'
import './Chapterwisenotes.css'
import { useContext } from "react"

import ChapterDisplay from '../Components/Chapter/ChapterDisplay'
import {Chapterwisecontext} from '../Context/Chapterwisenotes'

const Chapterwisenotessubject = (props) => {
    const {allchapterwisenotes} = useContext(Chapterwisecontext);
    return (
        <div>
          <div className="main_c">
            <div className="chapter-heading">
            <h1>Class {props.class} {props.name}</h1>
            </div>
            <div className="chapters">
              {allchapterwisenotes.map((item,i)=>{
                if(props.class===item.class && props.name===item.name) {
                  return <ChapterDisplay chapter={item.chapter}  chaptername={item.chaptername} pdf={item.pdf}/>;
                }else{
                  return null;
                }
              })}
            </div>
          </div>
        </div>
      )
}
export default Chapterwisenotessubject;