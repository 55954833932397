import React, { createContext } from "react";
import allchapterwisenotes from '../Assets/allchapterwisenotes'
export const Chapterwisecontext= createContext(null);

const ChapterwisenotescontextProvider =(props)=>{

    const contextvalue = {allchapterwisenotes};
     return(
        <Chapterwisecontext.Provider value={contextvalue}>
            {props.children}
        </Chapterwisecontext.Provider>
     )
}
export default ChapterwisenotescontextProvider;