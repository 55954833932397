import React, { useState } from "react";
import logo from "../../Assets/sss logo.png";
import menu from '../../Assets/menu.png';
import "./Navbar.css";
import { Link } from "react-router-dom";
// import cross from '../../Assets/cross.png'
// import send from '../../Assets/paper-plane.png'
// import angry from '../../Assets/angry.png'
// import sad from '../../Assets/sad.png'
// import neutral from '../../Assets/normal.png'
// import happy from '../../Assets/happy.png'
// import veryhappy from '../../Assets/very happy.png'
import feedback from '../../Assets/feedback.png'
import all_chapters from '../../Assets/allchapters'
const Navbar = () => {
  const [mobileMenu , setMobileMenu] = useState(true);
  // const [feedbackvisi, setfeedbackvisi] = useState(true);
  const toggleMenu =()=>{
    mobileMenu? setMobileMenu( false ) : setMobileMenu( true );
  }

//   const [feedbackform , setfeedbackform] = useState(false);
//   const userfeedback =()=>{
//     feedbackform? setfeedbackform(false):setfeedbackform(true);
    
//   }
// //   function delay(ms) {
// //     return new Promise(resolve => setTimeout(resolve, ms));
// // }
// //   async function submittedclicked(){
// //     await delay(300);
// //     alert("Your feedback has been Submitted....")
// //   }
  
//   const submitted=()=>{
//     userfeedback();
//     // submittedclicked();

//   }


  // const [result, setResult] = React.useState("");

  // const onSubmit = async (event) => {
  //   event.preventDefault();
  //   setResult("Sending....");
  //   const formData = new FormData(event.target);

  //   formData.append("access_key", "dcb25ac1-c962-4fa9-bebe-b77988c2a08b");

  //   const response = await fetch("https://api.web3forms.com/submit", {
  //     method: "POST",
  //     body: formData
  //   });

  //   const data = await response.json();

  //   if (data.success) {
  //     setResult("Form Submitted Successfully");
  //     event.target.reset();
  //   } else {
  //     console.log("Error", data);
  //     setResult(data.message);
  //   }
  // };

  //search

  const [input , setInput] = useState("");
  const fetchdata=(value)=>{
    const results = (all_chapters.filter((user)=>{
      return value && user && user.class && user.class.includes(value);
    }))
    console.log(results);
  }
  const handlechange=(value)=>{
    setInput(value);
    fetchdata(value);
  }

  return (
    <div>
      <div className="navbar1">
        <Link to={'/'}><img src={logo} className="logo" alt="logo" id="logologo"></img></Link>
        <div className="second-nav">
          <form class="d-flex search" role="search">
            <input
              class="form-control me-2 search-input"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={input}
              
            />
            <button class="btn btn-outline-success search-btn" type="submit" onClick={(e)=>handlechange(e.target.value)}>
              Search
            </button>
            
          </form>
          <Link style={{textDecoration:'none' , color:'white'} } to={'/register'}><button className="register-btn">Register</button></Link>
          <Link style={{textDecoration:'none' , color:'white'} } to={'/login'}><button className="login-btn">Log In</button></Link>
        </div>
      </div>
      {/* <div className='main-feedback'> */}
        <Link style={{textDecoration:'none' , color:'white'} } to={'/feedback'}><div className='feedback'>
          <img src={feedback}/>
        </div>
        </Link>
        {/* <div className={feedbackform?'user-form ': 'user-form hide_feedback' }>
          <form onSubmit={onSubmit}>
            <div className='cross'><img  src={cross} alt=''  onClick={userfeedback}/></div>
            <p>Based on your recent Experience, please Rate Us. This way we can improve our Website</p>
            


            <input type="radio" id='bad' name='rating' value="bad"></input>
            <label className='label-form' for="bad">Bad <img className='emoji' src={angry}/></label><br/>
            <input type='radio' id='Dislike' name='rating' value="Dislike"></input>
            <label className='label-form' for="Dislike">Dislike <img className='emoji' src={sad}/></label><br/>
            <input type='radio' id='Neutral' name='rating' value="Neutral"></input>
            <label className='label-form' for="Neutral">Neutral <img className='emoji' src={neutral}/></label><br/>
            <input type='radio' id='Like' name='rating' value="Like"></input>
            <label className='label-form' for="Like">Like <img className='emoji' src={happy}/></label><br/>
            <input type='radio' id='Happy' name='rating' value="Happy"></input>
            <label className='label-form' for="Happy">Happy <img className='emoji' src={veryhappy}/></label><br/>

            <p className='wouldulike'>Would you like to add a comment/suggestion?</p> 
            <textarea className='mesaage' placeholder='Write your Message/Suggestion Here...' name="message" rows="2" cols="30"></textarea>
            <div className='submit_div'><button className='submit-feedback' type="submit" onClick={userfeedback}>Submit <img src={send} alt=''/></button></div>
          </form>
        </div> */}
      {/* </div> */}
      <div className="menu">
          <form class="search-2" role="search">
            <input
              class="form-control me-2 search-input"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={input}
              onChange={(e)=>handlechange(e.target.value)}
            />
            <button class="btn btn-outline-success search-btn" type="submit">
              Search
            </button>
          </form>
        <img src={menu} className="menu-icon" onClick={toggleMenu} alt=""/>
        <ul className={mobileMenu? 'menu1' : 'menu1 hide_menu' }>
          <li className="menu2" onClick={toggleMenu}><Link style={{textDecoration:'none' ,color:'black' }} className="home-menu2" to={'/'}>Home</Link></li>
          <li className="menu2" onClick={toggleMenu}><Link style={{textDecoration:'none' ,color:'black' }} to="/about">About Us</Link></li>
          
          {/* <li className="menu2 dropdown">
          Dropdown 
          {/* <div className="dropdown-content">
            <a href="#">Link 1</a>
            <a href="#">Link 2</a>
            <a href="#">Link 3</a> 
            </div>
            <ul class=" dropdown-menu drp-menu">
              <li onClick={toggleMenu}>
                <a class="dropdown-item" href="/">
                <Link to={'/class1'}  className="link">Class 1</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class2'} className="link" >Class 2</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                  <Link to={'/class3'} className="link" >Class 3</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                  <Link to={'/class4'} className="link" >Class 4</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                  <Link to={'/class5'} className="link" >Class 5</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class6'} className="link" >Class 6</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class7'} className="link" >Class 7</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class8'} className="link" >Class 8</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class9'} className="link" >Class 9</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class10'} className="link" >Class 10</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class11'} className="link" >Class 11</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class12'} className="link" >Class 12</Link>
                </a>
              </li>

            </ul>
          
          </li> */}
          <li class="menu2 dropdown" >
            
              NCERT
            <ul class="dropdown-menu drp-menu">
              <li onClick={toggleMenu}>
                <a class="dropdown-item" href="/">
                <Link to={'/class1'}  className="link" onClick={toggleMenu}>Class 1</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class2'} className="link" onClick={toggleMenu}>Class 2</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                  <Link to={'/class3'} className="link" onClick={toggleMenu}>Class 3</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                  <Link to={'/class4'} className="link" onClick={toggleMenu}>Class 4</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                  <Link to={'/class5'} className="link" onClick={toggleMenu}>Class 5</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class6'} className="link" onClick={toggleMenu}>Class 6</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class7'} className="link" onClick={toggleMenu}>Class 7</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class8'} className="link" onClick={toggleMenu}>Class 8</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class9'} className="link" onClick={toggleMenu}>Class 9</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class10'} className="link" onClick={toggleMenu}>Class 10</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class11'} className="link" onClick={toggleMenu}>Class 11</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/class12'} className="link" onClick={toggleMenu}>Class 12</Link>
                </a>
              </li>

            </ul>
          </li>
          <li class="dropdown menu2" >
              ClasswiseNotes
            <ul class="dropdown-menu drp-menu">
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/chapterwisenotes/class6'} className="link" onClick={toggleMenu}>Class 6</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/chapterwisenotes/class7'} className="link" onClick={toggleMenu}>Class 7</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/chapterwisenotes/class8'} className="link" onClick={toggleMenu}>Class 8</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/chapterwisenotes/class9'} className="link" onClick={toggleMenu}>Class 9</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/chapterwisenotes/class10'} className="link" onClick={toggleMenu}>Class 10</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/chapterwisenotes/class11'} className="link" onClick={toggleMenu}>Class 11</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/chapterwisenotes/class12'} className="link" onClick={toggleMenu}>Class 12</Link>
                </a>
              </li>

            </ul>
          </li>
          <li class=" dropdown menu2" >
              Exercise Solutions
            <ul class="dropdown-menu drp-menu">
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/exercisesolutions/class6'} className="link" onClick={toggleMenu}>Class 6</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/exercisesolutions/class7'} className="link" onClick={toggleMenu}>Class 7</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/exercisesolutions/class8'} className="link" onClick={toggleMenu}>Class 8</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/exercisesolutions/class9'} className="link" onClick={toggleMenu}>Class 9</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/exercisesolutions/class10'} className="link" onClick={toggleMenu}>Class 10</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/exercisesolutions/class11'} className="link" onClick={toggleMenu}>Class 11</Link>
                </a>
              </li>
              <li>
                <a class="dropdown-item" href="/">
                <Link to={'/exercisesolutions/class12'} className="link" onClick={toggleMenu}>Class 12</Link>
                </a>
              </li>

            </ul>
          </li>
          {/* <li className="menu2">Chapterwise Notes</li> */}
          {/* <li className="menu2">Exercise Solutions</li> */}
          <li className="menu2" onClick={toggleMenu}><Link style={{textDecoration:'none' ,color:'black' }} to="/Question Bank">Question Bank</Link></li>
          <li className="menu2" onClick={toggleMenu}><Link style={{textDecoration:'none' ,color:'black' }} to="/Mock Test">Mock Tests</Link></li>
          <li className="menu2"><Link style={{textDecoration:'none' ,color:'black' }} to="/contactus" onClick={toggleMenu}>Contact Us</Link></li>
          <li className="menu2"><Link style={{textDecoration:'none' ,color:'black' }} to="/Query" onClick={toggleMenu}>Query</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
