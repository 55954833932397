import React from "react";
import "./Query.css";
import msg from "../../Assets/comments.png";
import mail from "../../Assets/gmail.png";
import phone from "../../Assets/telephone.png";
import address from "../../Assets/placeholder.png";

const Query = () => {
  return (
    <div className="query-full">
      <h2 className="getintouch">GET IN TOUCH</h2>
      <div className="main-query">
          <div className="query">
            <form action="https://api.web3forms.com/submit" method="POST">
              <h3>Query</h3>
              <input
                type="hidden"
                name="access_key"
                value="dcb25ac1-c962-4fa9-bebe-b77988c2a08b"
              />
              <label for="name" required>
                Your Name (Required)
              </label>
              <input
                type="text"
                id="username"
                name="name"
                placeholder="Your name.."
                class="queryInput"
              />

              <label for="email" required>
                Your Email (Required)
              </label>
              <input
                type="email"
                id="useremail"
                name="email"
                placeholder="Your number.."
              />

              <label for="class" required>
                Your Class
              </label>
              <select id="class" name="class">
                <option value="selectclass">Select Class</option>
                <option value="class6">Class 6</option>
                <option value="class7">Class 7</option>
                <option value="class8">Class 8</option>
                <option value="class9">Class 9</option>
                <option value="class10">Class 10</option>
                <option value="class11">Class 11</option>
                <option value="class12">Class 12</option>
              </select>

              <label for="message">Your Message</label>
              <textarea
                id="usermessage"
                name="submessageject"
                placeholder="Your Message..."
              ></textarea>

              <button class="querysubmit" type="submit">
                Submit
              </button>
            </form>
          </div>
        <div className="contact-col">
          <h3>
            Send us Message <img src={msg} alt="" />
          </h3>
          <p>
            Feel free to reach out thorught contact form. We guarantee to solve
            your query within 5-6 hours.
          </p>
          <ul>
            <li>
              {" "}
              <img src={mail} alt="" />
              sss@saatvikstudies.com
            </li>
            <li>
              {" "}
              <img src={phone} alt="" />
              <a href="tel:9773888338">+91 9773888338 </a>,<a href="tel:8383030389">+91 8383030389</a>
            </li>
            <li>
              {" "}
              <img src={address} alt="" />
              400 , SultanPur Dabas , N-delhi 110039
            </li>
          </ul>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6994.94127731259!2d77.042762!3d28.765218!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d070059d42753%3A0xcdba2a72c2cb3344!2sSaatvik%20Study%20Station!5e0!3m2!1sen!2sin!4v1721968603263!5m2!1sen!2sin"
            width="400"
            height="300"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Query;
