import React, { useEffect, useState } from 'react'
import './About.css'
import aboutus from '../../Assets/Aboutus Image.png'
import aboutweb from '../../Assets/final website img.png'
const About = () => {
    const [scrolltoimg , setscrolltoimg] =useState('');
    useEffect(()=>{
        window.addEventListener('scroll' , ()=>{
            if(window.scrollY < 1600){
                setscrolltoimg(' ');
            }else{
                setscrolltoimg('aniAboutUsImg');
            }
        })
    })
    const [scrolltoab , setscrolltoab] =useState('');
    useEffect(()=>{
        window.addEventListener('scroll' , ()=>{
            if(window.scrollY < 1600){
                setscrolltoab(' ');
            }else{
                setscrolltoab('aniAboutUsMain');
            }
        })
    })
    const [scrolltowebimg , setscrolltowebimg] =useState('');
    useEffect(()=>{
        window.addEventListener('scroll' , ()=>{
            if(window.scrollY < 2300){
                setscrolltowebimg(' ');
            }else{
                setscrolltowebimg('aniAboutWebImg');
            }
        })
    })
    const [scrolltoweb , setscrolltoweb] =useState('');
    useEffect(()=>{
        window.addEventListener('scroll' , ()=>{
            if(window.scrollY < 2300){
                setscrolltoweb(' ');
            }else{
                setscrolltoweb('aniAboutWebMain');
            }
        })
    })
    
  return (
    <div className='about'>
        <div className="Aboutus" id="AboutUS">
        <img src={aboutus} alt="about us img" className={`aboutusimg ${scrolltoimg}`}/>
        <div className={`aboutMain ${scrolltoab}`}>
          <p className="mainheadingaboutus"><b>SAATVIK STUDY STATION</b></p>
            Saatvik Study Station is the best coaching for unlocking students potential and maximizing their growth. Our main focus is to let each student discover their True Self , Brake Barriers and design their Extraordinary life.
            <br/>All class students can join us via Online & Offline mode of Coaching . Study Materials available are designed under the Guidance of Experts.
            <br/>
            <div className="notes">
              A Good Coaching can change the Game but Saatvik Study Station can change your Life.
            </div>
        </div>      
      </div>
      <div className="Aboutweb" id="Aboutweb">
        <div className={`aboutMainWeb ${scrolltoweb}`}>
          <p className="mainheadingaboutweb"><b>SAATVIK STUDIES WEBSITE</b></p>
            Saatvik Studies is an online platform that provides information or contents on topics realted to education .
            It contains :-
            <br/>• Latest NCERT chapterwise PDFs 
            <br/>• All classes chapterwise notes 
            <br/>• Exercise Solutions
            <br/>• Question Banks
            <br/>• Previous year Questions
            <br/>• Mock Tests
            <br/>Saatvik Studies webiste contains a Query section in which we guarantee to solve your query within 5-6 hours.
            <div className="notes">
              JOIN US in the journey to empower individuals to reach their full potentials & make a positive impact on our society.
            </div>
        </div>
        <img src={aboutweb} alt="about webiste img" className={`aboutWebimg ${scrolltowebimg}`}/>      
      </div>
    </div>
  )
}

export default About