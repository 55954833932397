import React, { createContext } from "react";
import allexercisesolun from '../Assets/allexercisesolutions'
export const Exercisesoluncontext= createContext(null);

const ExercisesoluncontextProvider =(props)=>{
    const contextvalue = {allexercisesolun};
     return(
        <Exercisesoluncontext.Provider value={contextvalue}>
            {props.children}
        </Exercisesoluncontext.Provider>
     )
}
export default ExercisesoluncontextProvider;