import class1mathsch1 from './class 1/books/maths/Ncert class 1 maths ch1.pdf'
import class1mathsch2 from './class 1/books/maths/Ncert class 1 maths ch2.pdf'
import class1mathsch3 from './class 1/books/maths/Ncert class 1 maths ch3.pdf'
import class1mathsch4 from './class 1/books/maths/Ncert class 1 maths ch4.pdf'
import class1mathsch5 from './class 1/books/maths/Ncert class 1 maths ch5.pdf'
import class1mathsch6 from './class 1/books/maths/Ncert class 1 maths ch6.pdf'
import class1mathsch7 from './class 1/books/maths/Ncert class 1 maths ch7.pdf'
import class1mathsch8 from './class 1/books/maths/Ncert class 1 maths ch8.pdf'
import class1mathsch9 from './class 1/books/maths/Ncert class 1 maths ch9.pdf'
import class1mathsch10 from './class 1/books/maths/Ncert class 1 maths ch10.pdf'
import class1mathsch11 from './class 1/books/maths/Ncert class 1 maths ch11.pdf'
import class1mathsch12 from './class 1/books/maths/Ncert class 1 maths ch12.pdf'
import class1mathsch13 from './class 1/books/maths/Ncert class 1 maths ch13.pdf'
import class1hindich1 from './class 1/books/hindi/Ncert class 1 hindi ch1.pdf'
import class1hindich2 from './class 1/books/hindi/Ncert class 1 hindi ch2.pdf'
import class1hindich3 from './class 1/books/hindi/Ncert class 1 hindi ch3.pdf'
import class1hindich4 from './class 1/books/hindi/Ncert class 1 hindi ch4.pdf'
import class1hindich5 from './class 1/books/hindi/Ncert class 1 hindi ch5.pdf'
import class1hindich6 from './class 1/books/hindi/Ncert class 1 hindi ch6.pdf'
import class1hindich7 from './class 1/books/hindi/Ncert class 1 hindi ch7.pdf'
import class1hindich8 from './class 1/books/hindi/Ncert class 1 hindi ch8.pdf'
import class1hindich9 from './class 1/books/hindi/Ncert class 1 hindi ch9.pdf'
import class1hindich10 from './class 1/books/hindi/Ncert class 1 hindi ch10.pdf'
import class1engch1 from './class 1/books/english/Ncert class 1 english ch1.pdf'
import class1engch2 from './class 1/books/english/Ncert class 1 english ch2.pdf'
import class1engch3 from './class 1/books/english/Ncert class 1 english ch3.pdf'
import class1engch4 from './class 1/books/english/Ncert class 1 english ch4.pdf'
import class1engch5 from './class 1/books/english/Ncert class 1 english ch5.pdf'
import class1engch6 from './class 1/books/english/Ncert class 1 english ch6.pdf'
import class1engch7 from './class 1/books/english/Ncert class 1 english ch7.pdf'
import class1engch8 from './class 1/books/english/Ncert class 1 english ch8.pdf'
import class1engch9 from './class 1/books/english/Ncert class 1 english ch9.pdf'
import class2mathsch1 from './class 2/books/maths/NCERT class 2 maths ch1.pdf'
import class2mathsch2 from './class 2/books/maths/NCERT class 2 maths ch2.pdf'
import class2mathsch3 from './class 2/books/maths/NCERT class 2 maths ch3.pdf'
import class2mathsch4 from './class 2/books/maths/NCERT class 2 maths ch4.pdf'
import class2mathsch5 from './class 2/books/maths/NCERT class 2 maths ch5.pdf'
import class2mathsch6 from './class 2/books/maths/NCERT class 2 maths ch6.pdf'
import class2mathsch7 from './class 2/books/maths/NCERT class 2 maths ch7.pdf'
import class2mathsch8 from './class 2/books/maths/NCERT class 2 maths ch8.pdf'
import class2mathsch9 from './class 2/books/maths/NCERT class 2 maths ch9.pdf'
import class2mathsch10 from './class 2/books/maths/NCERT class 2 maths ch10.pdf'
import class2mathsch11 from './class 2/books/maths/NCERT class 2 maths ch11.pdf'
import class2hindich1 from './class 2/books/hindi/NCERT class 2 hindi ch1.pdf'
import class2hindich2 from './class 2/books/hindi/NCERT class 2 hindi ch2.pdf'
import class2hindich3 from './class 2/books/hindi/NCERT class 2 hindi ch2.pdf'
import class2hindich4 from './class 2/books/hindi/NCERT class 2 hindi ch4.pdf'
import class2hindich5 from './class 2/books/hindi/NCERT class 2 hindi ch5.pdf'
import class2hindich6 from './class 2/books/hindi/NCERT class 2 hindi ch6.pdf'
import class2hindich7 from './class 2/books/hindi/NCERT class 2 hindi ch7.pdf'
import class2hindich8 from './class 2/books/hindi/NCERT class 2 hindi ch8.pdf'
import class2hindich9 from './class 2/books/hindi/NCERT class 2 hindi ch9.pdf'
import class2hindich10 from './class 2/books/hindi/NCERT class 2 hindi ch10.pdf'
import class2hindich11 from './class 2/books/hindi/NCERT class 2 hindi ch11.pdf'
import class2hindich12 from './class 2/books/hindi/NCERT class 2 hindi ch12.pdf'
import class2hindich13 from './class 2/books/hindi/NCERT class 2 hindi ch13.pdf'
import class2hindich14 from './class 2/books/hindi/NCERT class 2 hindi ch14.pdf'
import class2hindich15 from './class 2/books/hindi/NCERT class 2 hindi ch15.pdf'
import class2hindich16 from './class 2/books/hindi/NCERT class 2 hindi ch16.pdf'
import class2hindich17 from './class 2/books/hindi/NCERT class 2 hindi ch17.pdf'
import class2hindich18 from './class 2/books/hindi/NCERT class 2 hindi ch18.pdf'
import class2hindich19 from './class 2/books/hindi/NCERT class 2 hindi ch19.pdf'
import class2hindich20 from './class 2/books/hindi/NCERT class 2 hindi ch20.pdf'
import class2hindich21 from './class 2/books/hindi/NCERT class 2 hindi ch21.pdf'
import class2hindich22 from './class 2/books/hindi/NCERT class 2 hindi ch22.pdf'
import class2hindich23 from './class 2/books/hindi/NCERT class 2 hindi ch23.pdf'
import class2hindich24 from './class 2/books/hindi/NCERT class 2 hindi ch24.pdf'
import class2hindich25 from './class 2/books/hindi/NCERT class 2 hindi ch25.pdf'
import class2hindich26 from './class 2/books/hindi/NCERT class 2 hindi ch26.pdf'
import class2engch1 from './class 2/books/english/NCERT class 2 english ch1.pdf'
import class2engch2 from './class 2/books/english/NCERT class 2 english ch2.pdf'
import class2engch3 from './class 2/books/english/NCERT class 2 english ch3.pdf'
import class2engch4 from './class 2/books/english/NCERT class 2 english ch4.pdf'
import class2engch5 from './class 2/books/english/NCERT class 2 english ch5.pdf'
import class2engch6 from './class 2/books/english/NCERT class 2 english ch6.pdf'
import class2engch7 from './class 2/books/english/NCERT class 2 english ch7.pdf'
import class2engch8 from './class 2/books/english/NCERT class 2 english ch8.pdf'
import class2engch9 from './class 2/books/english/NCERT class 2 english ch9.pdf'
import class2engch10 from './class 2/books/english/NCERT class 2 english ch10.pdf'
import class2engch11 from './class 2/books/english/NCERT class 2 english ch11.pdf'
import class2engch12 from './class 2/books/english/NCERT class 2 english ch12.pdf'
import class2engch13 from './class 2/books/english/NCERT class 2 english ch13.pdf'
import class3mathsch1 from './class 3/books/maths/NCERT Class 3 Maths ch1.pdf'
import class3mathsch2 from './class 3/books/maths/NCERT Class 3 Maths ch2.pdf'
import class3mathsch3 from './class 3/books/maths/NCERT Class 3 Maths ch3.pdf'
import class3mathsch4 from './class 3/books/maths/NCERT Class 3 Maths ch4.pdf'
import class3mathsch5 from './class 3/books/maths/NCERT Class 3 Maths ch5.pdf'
import class3mathsch6 from './class 3/books/maths/NCERT Class 3 Maths ch6.pdf'
import class3mathsch7 from './class 3/books/maths/NCERT Class 3 Maths ch7.pdf'
import class3mathsch8 from './class 3/books/maths/NCERT Class 3 Maths ch8.pdf'
import class3mathsch9 from './class 3/books/maths/NCERT Class 3 Maths ch9.pdf'
import class3mathsch10 from './class 3/books/maths/NCERT Class 3 Maths ch10.pdf'
import class3mathsch11 from './class 3/books/maths/NCERT Class 3 Maths ch11.pdf'
import class3mathsch12 from './class 3/books/maths/NCERT Class 3 Maths ch12.pdf'
import class3mathsch13 from './class 3/books/maths/NCERT Class 3 Maths ch13.pdf'
import class3mathsch14 from './class 3/books/maths/NCERT Class 3 Maths ch14.pdf'
import class3engch1 from './class 3/books/english/NCERT Class 3 English ch1.pdf'
import class3engch2 from './class 3/books/english/NCERT Class 3 English ch2.pdf'
import class3engch3 from './class 3/books/english/NCERT Class 3 English ch3.pdf'
import class3engch4 from './class 3/books/english/NCERT Class 3 English ch4.pdf'
import class3engch5 from './class 3/books/english/NCERT Class 3 English ch5.pdf'
import class3engch6 from './class 3/books/english/NCERT Class 3 English ch6.pdf'
import class3engch7 from './class 3/books/english/NCERT Class 3 English ch7.pdf'
import class3engch8 from './class 3/books/english/NCERT Class 3 English ch8.pdf'
import class3engch9 from './class 3/books/english/NCERT Class 3 English ch9.pdf'
import class3engch10 from './class 3/books/english/NCERT Class 3 English ch10.pdf'
import class3engch11 from './class 3/books/english/NCERT Class 3 English ch11.pdf'
import class3engch12 from './class 3/books/english/NCERT Class 3 English ch12.pdf'
import class3hindich1 from './class 3/books/hindi/NCERT Class 3 Hindi ch1.pdf'
import class3hindich2 from './class 3/books/hindi/NCERT Class 3 Hindi ch2.pdf'
import class3hindich3 from './class 3/books/hindi/NCERT Class 3 Hindi ch3.pdf'
import class3hindich4 from './class 3/books/hindi/NCERT Class 3 Hindi ch4.pdf'
import class3hindich5 from './class 3/books/hindi/NCERT Class 3 Hindi ch5.pdf'
import class3hindich6 from './class 3/books/hindi/NCERT Class 3 Hindi ch6.pdf'
import class3hindich7 from './class 3/books/hindi/NCERT Class 3 Hindi ch7.pdf'
import class3hindich8 from './class 3/books/hindi/NCERT Class 3 Hindi ch8.pdf'
import class3hindich9 from './class 3/books/hindi/NCERT Class 3 Hindi ch9.pdf'
import class3hindich10 from './class 3/books/hindi/NCERT Class 3 Hindi ch10.pdf'
import class3hindich11 from './class 3/books/hindi/NCERT Class 3 Hindi ch11.pdf'
import class3hindich12 from './class 3/books/hindi/NCERT Class 3 Hindi ch12.pdf'
import class3hindich13 from './class 3/books/hindi/NCERT Class 3 Hindi ch13.pdf'
import class3hindich14 from './class 3/books/hindi/NCERT Class 3 Hindi ch14.pdf'
import class3hindich15 from './class 3/books/hindi/NCERT Class 3 Hindi ch15.pdf'
import class3hindich16 from './class 3/books/hindi/NCERT Class 3 Hindi ch16.pdf'
import class3hindich17 from './class 3/books/hindi/NCERT Class 3 Hindi ch17.pdf'
import class3hindich18 from './class 3/books/hindi/NCERT Class 3 Hindi ch18.pdf'
import class4evsch1 from './class 4/books/evs/NCERT class 4 evs ch1.pdf'
import class4evsch2 from './class 4/books/evs/NCERT class 4 evs ch2.pdf'
import class4evsch3 from './class 4/books/evs/NCERT class 4 evs ch3.pdf'
import class4evsch4 from './class 4/books/evs/NCERT class 4 evs ch4.pdf'
import class4evsch5 from './class 4/books/evs/NCERT class 4 evs ch5.pdf'
import class4evsch6 from './class 4/books/evs/NCERT class 4 evs ch6.pdf'
import class4evsch7 from './class 4/books/evs/NCERT class 4 evs ch7.pdf'
import class4evsch8 from './class 4/books/evs/NCERT class 4 evs ch8.pdf'
import class4evsch9 from './class 4/books/evs/NCERT class 4 evs ch9.pdf'
import class4evsch10 from './class 4/books/evs/NCERT class 4 evs ch10.pdf'
import class4evsch11 from './class 4/books/evs/NCERT class 4 evs ch11.pdf'
import class4evsch12 from './class 4/books/evs/NCERT class 4 evs ch12.pdf'
import class4evsch13 from './class 4/books/evs/NCERT class 4 evs ch13.pdf'
import class4evsch14 from './class 4/books/evs/NCERT class 4 evs ch14.pdf'
import class4evsch15 from './class 4/books/evs/NCERT class 4 evs ch15.pdf'
import class4evsch16 from './class 4/books/evs/NCERT class 4 evs ch16.pdf'
import class4evsch17 from './class 4/books/evs/NCERT class 4 evs ch17.pdf'
import class4evsch18 from './class 4/books/evs/NCERT class 4 evs ch18.pdf'
import class4evsch19 from './class 4/books/evs/NCERT class 4 evs ch19.pdf'
import class4evsch20 from './class 4/books/evs/NCERT class 4 evs ch20.pdf'
import class4evsch21 from './class 4/books/evs/NCERT class 4 evs ch21.pdf'
import class4evsch22 from './class 4/books/evs/NCERT class 4 evs ch22.pdf'
import class4evsch23 from './class 4/books/evs/NCERT class 4 evs ch23.pdf'
import class4evsch24 from './class 4/books/evs/NCERT class 4 evs ch24.pdf'
import class4evsch25 from './class 4/books/evs/NCERT class 4 evs ch25.pdf'
import class4evsch26 from './class 4/books/evs/NCERT class 4 evs ch26.pdf'
import class4evsch27 from './class 4/books/evs/NCERT class 4 evs ch27.pdf'
import class4mathsch1 from'./class 4/books/maths/NCERT class 4 maths ch1.pdf'
import class4mathsch2 from'./class 4/books/maths/NCERT class 4 maths ch2.pdf'
import class4mathsch3 from'./class 4/books/maths/NCERT class 4 maths ch3.pdf'
import class4mathsch4 from'./class 4/books/maths/NCERT class 4 maths ch4.pdf'
import class4mathsch5 from'./class 4/books/maths/NCERT class 4 maths ch5.pdf'
import class4mathsch6 from'./class 4/books/maths/NCERT class 4 maths ch6.pdf'
import class4mathsch7 from'./class 4/books/maths/NCERT class 4 maths ch7.pdf'
import class4mathsch8 from'./class 4/books/maths/NCERT class 4 maths ch8.pdf'
import class4mathsch9 from'./class 4/books/maths/NCERT class 4 maths ch9.pdf'
import class4mathsch10 from'./class 4/books/maths/NCERT class 4 maths ch10.pdf'
import class4mathsch11 from'./class 4/books/maths/NCERT class 4 maths ch11.pdf'
import class4mathsch12 from'./class 4/books/maths/NCERT class 4 maths ch12.pdf'
import class4mathsch13 from'./class 4/books/maths/NCERT class 4 maths ch13.pdf'
import class4mathsch14 from'./class 4/books/maths/NCERT class 4 maths ch14.pdf'
import class4hindich1 from './class 4/books/hindi/NCERT class 4 hindi ch1.pdf'
import class4hindich2 from './class 4/books/hindi/NCERT class 4 hindi ch2.pdf'
import class4hindich3 from './class 4/books/hindi/NCERT class 4 hindi ch3.pdf'
import class4hindich4 from './class 4/books/hindi/NCERT class 4 hindi ch4.pdf'
import class4hindich5 from './class 4/books/hindi/NCERT class 4 hindi ch5.pdf'
import class4hindich6 from './class 4/books/hindi/NCERT class 4 hindi ch6.pdf'
import class4hindich7 from './class 4/books/hindi/NCERT class 4 hindi ch7.pdf'
import class4hindich8 from './class 4/books/hindi/NCERT class 4 hindi ch8.pdf'
import class4hindich9 from './class 4/books/hindi/NCERT class 4 hindi ch9.pdf'
import class4hindich10 from './class 4/books/hindi/NCERT class 4 hindi ch10.pdf'
import class4hindich11 from './class 4/books/hindi/NCERT class 4 hindi ch12.pdf'
import class4hindich12 from './class 4/books/hindi/NCERT class 4 hindi ch12.pdf'
import class4hindich13 from './class 4/books/hindi/NCERT class 4 hindi ch13.pdf'
import class4hindich14 from './class 4/books/hindi/NCERT class 4 hindi ch14.pdf'
import class4engch1 from './class 4/books/english/NCERT class 4 english ch1.pdf'
import class4engch2 from './class 4/books/english/NCERT class 4 english ch2.pdf'
import class4engch3 from './class 4/books/english/NCERT class 4 english ch3.pdf'
import class4engch4 from './class 4/books/english/NCERT class 4 english ch4.pdf'
import class4engch5 from './class 4/books/english/NCERT class 4 english ch5.pdf'
import class4engch6 from './class 4/books/english/NCERT class 4 english ch6.pdf'
import class4engch7 from './class 4/books/english/NCERT class 4 english ch7.pdf'
import class4engch8 from './class 4/books/english/NCERT class 4 english ch8.pdf'
import class4engch9 from './class 4/books/english/NCERT class 4 english ch9.pdf'
import class5engch1 from './class 5/books/english/NCERT class 5 english ch1.pdf'
import class5engch2 from './class 5/books/english/NCERT class 5 english ch2.pdf'
import class5engch3 from './class 5/books/english/NCERT class 5 english ch3.pdf'
import class5engch4 from './class 5/books/english/NCERT class 5 english ch4.pdf'
import class5engch5 from './class 5/books/english/NCERT class 5 english ch5.pdf'
import class5engch6 from './class 5/books/english/NCERT class 5 english ch6.pdf'
import class5engch7 from './class 5/books/english/NCERT class 5 english ch7.pdf'
import class5engch8 from './class 5/books/english/NCERT class 5 english ch8.pdf'
import class5engch9 from './class 5/books/english/NCERT class 5 english ch9.pdf'
import class5engch10 from './class 5/books/english/NCERT class 5 english ch10.pdf'
import class5evsch1 from './class 5/books/evs/NCERT class 5 evs ch1.pdf'
import class5evsch2 from './class 5/books/evs/NCERT class 5 evs ch2.pdf'
import class5evsch3 from './class 5/books/evs/NCERT class 5 evs ch3.pdf'
import class5evsch4 from './class 5/books/evs/NCERT class 5 evs ch4.pdf'
import class5evsch5 from './class 5/books/evs/NCERT class 5 evs ch5.pdf'
import class5evsch6 from './class 5/books/evs/NCERT class 5 evs ch6.pdf'
import class5evsch7 from './class 5/books/evs/NCERT class 5 evs ch7.pdf'
import class5evsch8 from './class 5/books/evs/NCERT class 5 evs ch8.pdf'
import class5evsch9 from './class 5/books/evs/NCERT class 5 evs ch9.pdf'
import class5evsch10 from './class 5/books/evs/NCERT class 5 evs ch10.pdf'
import class5evsch11 from './class 5/books/evs/NCERT class 5 evs ch11.pdf'
import class5evsch12 from './class 5/books/evs/NCERT class 5 evs ch12.pdf'
import class5evsch13 from './class 5/books/evs/NCERT class 5 evs ch13.pdf'
import class5evsch14 from './class 5/books/evs/NCERT class 5 evs ch14.pdf'
import class5evsch15 from './class 5/books/evs/NCERT class 5 evs ch15.pdf'
import class5evsch16 from './class 5/books/evs/NCERT class 5 evs ch16.pdf'
import class5evsch17 from './class 5/books/evs/NCERT class 5 evs ch17.pdf'
import class5evsch18 from './class 5/books/evs/NCERT class 5 evs ch18.pdf'
import class5evsch19 from './class 5/books/evs/NCERT class 5 evs ch19.pdf'
import class5evsch20 from './class 5/books/evs/NCERT class 5 evs ch20.pdf'
import class5evsch21 from './class 5/books/evs/NCERT class 5 evs ch21.pdf'
import class5evsch22 from './class 5/books/evs/NCERT class 5 evs ch22.pdf'
import class5hindich1 from './class 5/books/hindi/NCERT class 5 hindi ch1.pdf'
import class5hindich2 from './class 5/books/hindi/NCERT class 5 hindi ch2.pdf'
import class5hindich3 from './class 5/books/hindi/NCERT class 5 hindi ch3.pdf'
import class5hindich4 from './class 5/books/hindi/NCERT class 5 hindi ch4.pdf'
import class5hindich5 from './class 5/books/hindi/NCERT class 5 hindi ch5.pdf'
import class5hindich6 from './class 5/books/hindi/NCERT class 5 hindi ch6.pdf'
import class5hindich7 from './class 5/books/hindi/NCERT class 5 hindi ch7.pdf'
import class5hindich8 from './class 5/books/hindi/NCERT class 5 hindi ch8.pdf'
import class5hindich9 from './class 5/books/hindi/NCERT class 5 hindi ch9.pdf'
import class5hindich10 from './class 5/books/hindi/NCERT class 5 hindi ch10.pdf'
import class5hindich11 from './class 5/books/hindi/NCERT class 5 hindi ch11.pdf'
import class5hindich12 from './class 5/books/hindi/NCERT class 5 hindi ch12.pdf'
import class5hindich13 from './class 5/books/hindi/NCERT class 5 hindi ch13.pdf'
import class5hindich14 from './class 5/books/hindi/NCERT class 5 hindi ch14.pdf'
import class5hindich15 from './class 5/books/hindi/NCERT class 5 hindi ch15.pdf'
import class5hindich16 from './class 5/books/hindi/NCERT class 5 hindi ch16.pdf'
import class5hindich17 from './class 5/books/hindi/NCERT class 5 hindi ch17.pdf'
import class5hindich18 from './class 5/books/hindi/NCERT class 5 hindi ch18.pdf'
import class5mathsch1 from './class 5/books/maths/NCERT class 5 Maths ch1.pdf'
import class5mathsch2 from './class 5/books/maths/NCERT class 5 Maths ch2.pdf'
import class5mathsch3 from './class 5/books/maths/NCERT class 5 Maths ch3.pdf'
import class5mathsch4 from './class 5/books/maths/NCERT class 5 Maths ch4.pdf'
import class5mathsch5 from './class 5/books/maths/NCERT class 5 Maths ch5.pdf'
import class5mathsch6 from './class 5/books/maths/NCERT class 5 Maths ch6.pdf'
import class5mathsch7 from './class 5/books/maths/NCERT class 5 Maths ch7.pdf'
import class5mathsch8 from './class 5/books/maths/NCERT class 5 Maths ch8.pdf'
import class5mathsch9 from './class 5/books/maths/NCERT class 5 Maths ch9.pdf'
import class5mathsch10 from './class 5/books/maths/NCERT class 5 Maths ch10.pdf'
import class5mathsch11 from './class 5/books/maths/NCERT class 5 Maths ch11.pdf'
import class5mathsch12 from './class 5/books/maths/NCERT class 5 Maths ch12.pdf'
import class5mathsch13 from './class 5/books/maths/NCERT class 5 Maths ch13.pdf'
import class5mathsch14 from './class 5/books/maths/NCERT class 5 Maths ch14.pdf'
import class6sstch1 from './class 6/books/sst/class 6 ncert sst chapter 1.pdf'
import class6sstch2 from './class 6/books/sst/class 6 ncert sst chapter 2.pdf'
import class6sstch3 from './class 6/books/sst/class 6 ncert sst chapter 3.pdf'
import class6sstch4 from './class 6/books/sst/class 6 ncert sst chapter 4.pdf'
import class6sstch5 from './class 6/books/sst/class 6 ncert sst chapter 5.pdf'
import class6sstch6 from './class 6/books/sst/class 6 ncert sst chapter 6.pdf'
import class6sstch7 from './class 6/books/sst/class 6 ncert sst chapter 7.pdf'
import class6sstch8 from './class 6/books/sst/class 6 ncert sst chapter 8.pdf'
import class6sstch9 from './class 6/books/sst/class 6 ncert sst chapter 9.pdf'
import class6sstch10 from './class 6/books/sst/class 6 ncert sst chapter 10.pdf'
import class6sstch11 from './class 6/books/sst/class 6 ncert sst chapter 11.pdf'
import class6sstch12 from './class 6/books/sst/class 6 ncert sst chapter 12.pdf'
import class6sstch13 from './class 6/books/sst/class 6 ncert sst chapter 13.pdf'
import class6sstch14 from './class 6/books/sst/class 6 ncert sst chapter 14.pdf'
import class6engch1 from './class 6/books/english/class 6 ncert english chapter 1.pdf'
import class6engch2 from './class 6/books/english/class 6 ncert english chapter 2.pdf'
import class6engch3 from './class 6/books/english/class 6 ncert english chapter 3.pdf'
import class6engch4 from './class 6/books/english/class 6 ncert english chapter 4.pdf'
import class6engch5 from './class 6/books/english/class 6 ncert english chapter 5.pdf'
import class6hindich1 from './class 6/books/hindi/class 6 ncert hindi chapter 1.pdf'
import class6hindich2 from './class 6/books/hindi/class 6 ncert hindi chapter 2.pdf'
import class6hindich3 from './class 6/books/hindi/class 6 ncert hindi chapter 3.pdf'
import class6hindich4 from './class 6/books/hindi/class 6 ncert hindi chapter 4.pdf'
import class6hindich5 from './class 6/books/hindi/class 6 ncert hindi chapter 5.pdf'
import class6hindich6 from './class 6/books/hindi/class 6 ncert hindi chapter 6.pdf'
import class6hindich7 from './class 6/books/hindi/class 6 ncert hindi chapter 7.pdf'
import class6hindich8 from './class 6/books/hindi/class 6 ncert hindi chapter 8.pdf'
import class6hindich9 from './class 6/books/hindi/class 6 ncert hindi chapter 9.pdf'
import class6hindich10 from './class 6/books/hindi/class 6 ncert hindi chapter 10.pdf'
import class6hindich11 from './class 6/books/hindi/class 6 ncert hindi chapter 11.pdf'
import class6hindich12 from './class 6/books/hindi/class 6 ncert hindi chapter 12.pdf'
import class6hindich13 from './class 6/books/hindi/class 6 ncert hindi chapter 13.pdf'
import class6sanskritch1 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 1.pdf'
import class6sanskritch2 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 2.pdf'
import class6sanskritch3 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 3.pdf'
import class6sanskritch4 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 4.pdf'
import class6sanskritch5 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 5.pdf'
import class6sanskritch6 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 6.pdf'
import class6sanskritch7 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 7.pdf'
import class6sanskritch8 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 8.pdf'
import class6sanskritch9 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 9.pdf'
import class6sanskritch10 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 10.pdf'
import class6sanskritch11 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 11.pdf'
import class6sanskritch12 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 12.pdf'
import class6sanskritch13 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 13.pdf'
import class6sanskritch14 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 14.pdf'
import class6sanskritch15 from './class 6/books/sanskrit/class 6 ncert sanskrit chapter 15.pdf'
import class6sciencech1 from './class 6/books/science/class 6 ncert science chapter 1.pdf'
import class6sciencech2 from './class 6/books/science/class 6 ncert science chapter 2.pdf'
import class6sciencech3 from './class 6/books/science/class 6 ncert science chapter 3.pdf'
import class6sciencech4 from './class 6/books/science/class 6 ncert science chapter 4.pdf'
import class6sciencech5 from './class 6/books/science/class 6 ncert science chapter 5.pdf'
import class6sciencech6 from './class 6/books/science/class 6 ncert science chapter 6.pdf'
import class6sciencech7 from './class 6/books/science/class 6 ncert science chapter 7.pdf'
import class6sciencech8 from './class 6/books/science/class 6 ncert science chapter 8.pdf'
import class6sciencech9 from './class 6/books/science/class 6 ncert science chapter 9.pdf'
import class6sciencech10 from './class 6/books/science/class 6 ncert science chapter 10.pdf'
import class6sciencech11 from './class 6/books/science/class 6 ncert science chapter 11.pdf'
import class6sciencech12 from './class 6/books/science/class 6 ncert science chapter 12.pdf'
import class7mathsch1 from './class 7/books/maths/NCERT class 7 maths ch1.pdf'
import class7mathsch2 from './class 7/books/maths/NCERT class 7 maths ch2.pdf'
import class7mathsch3 from './class 7/books/maths/NCERT class 7 maths ch3.pdf'
import class7mathsch4 from './class 7/books/maths/NCERT class 7 maths ch4.pdf'
import class7mathsch5 from './class 7/books/maths/NCERT class 7 maths ch5.pdf'
import class7mathsch6 from './class 7/books/maths/NCERT class 7 maths ch6.pdf'
import class7mathsch7 from './class 7/books/maths/NCERT class 7 maths ch7.pdf'
import class7mathsch8 from './class 7/books/maths/NCERT class 7 maths ch8.pdf'
import class7mathsch9 from './class 7/books/maths/NCERT class 7 maths ch9.pdf'
import class7mathsch10 from './class 7/books/maths/NCERT class 7 maths ch10.pdf'
import class7mathsch11 from './class 7/books/maths/NCERT class 7 maths ch11.pdf'
import class7mathsch12 from './class 7/books/maths/NCERT class 7 maths ch12.pdf'
import class7mathsch13 from './class 7/books/maths/NCERT class 7 maths ch13.pdf'
import class7civicsch1 from './class 7/books/civics/NCERT class 7 civics ch1.pdf'
import class7civicsch2 from './class 7/books/civics/NCERT class 7 civics ch2.pdf'
import class7civicsch3 from './class 7/books/civics/NCERT class 7 civics ch3.pdf'
import class7civicsch4 from './class 7/books/civics/NCERT class 7 civics ch4.pdf'
import class7civicsch5 from './class 7/books/civics/NCERT class 7 civics ch5.pdf'
import class7civicsch6 from './class 7/books/civics/NCERT class 7 civics ch6.pdf'
import class7civicsch7 from './class 7/books/civics/NCERT class 7 civics ch7.pdf'
import class7civicsch8 from './class 7/books/civics/NCERT class 7 civics ch8.pdf'
import class7engaliench1 from './class 7/books/english alien/NCERT class 7 english alien ch1.pdf'
import class7engaliench2 from './class 7/books/english alien/NCERT class 7 english alien ch2.pdf'
import class7engaliench3 from './class 7/books/english alien/NCERT class 7 english alien ch3.pdf'
import class7engaliench4 from './class 7/books/english alien/NCERT class 7 english alien ch4.pdf'
import class7engaliench5 from './class 7/books/english alien/NCERT class 7 english alien ch5.pdf'
import class7engaliench6 from './class 7/books/english alien/NCERT class 7 english alien ch6.pdf'
import class7engaliench7 from './class 7/books/english alien/NCERT class 7 english alien ch7.pdf'
import class7enghoneych1 from './class 7/books/english honeycomb/NCERT class 7 english honeycomb ch1.pdf'
import class7enghoneych2 from './class 7/books/english honeycomb/NCERT class 7 english honeycomb ch2.pdf'
import class7enghoneych3 from './class 7/books/english honeycomb/NCERT class 7 english honeycomb ch3.pdf'
import class7enghoneych4 from './class 7/books/english honeycomb/NCERT class 7 english honeycomb ch4.pdf'
import class7enghoneych5 from './class 7/books/english honeycomb/NCERT class 7 english honeycomb ch5.pdf'
import class7enghoneych6 from './class 7/books/english honeycomb/NCERT class 7 english honeycomb ch6.pdf'
import class7enghoneych7 from './class 7/books/english honeycomb/NCERT class 7 english honeycomb ch7.pdf'
import class7enghoneych8 from './class 7/books/english honeycomb/NCERT class 7 english honeycomb ch8.pdf'
import class7geoch1 from './class 7/books/geo/NCERT class 7 geo ch1.pdf'
import class7geoch2 from './class 7/books/geo/NCERT class 7 geo ch2.pdf'
import class7geoch3 from './class 7/books/geo/NCERT class 7 geo ch3.pdf'
import class7geoch4 from './class 7/books/geo/NCERT class 7 geo ch4.pdf'
import class7geoch5 from './class 7/books/geo/NCERT class 7 geo ch5.pdf'
import class7geoch6 from './class 7/books/geo/NCERT class 7 geo ch6.pdf'
import class7geoch7 from './class 7/books/geo/NCERT class 7 geo ch7.pdf'
import class7historych1 from './class 7/books/history/NCERT class 7 history ch1.pdf'
import class7historych2 from './class 7/books/history/NCERT class 7 history ch2.pdf'
import class7historych3 from './class 7/books/history/NCERT class 7 history ch3.pdf'
import class7historych4 from './class 7/books/history/NCERT class 7 history ch4.pdf'
import class7historych5 from './class 7/books/history/NCERT class 7 history ch5.pdf'
import class7historych6 from './class 7/books/history/NCERT class 7 history ch6.pdf'
import class7historych7 from './class 7/books/history/NCERT class 7 history ch7.pdf'
import class7historych8 from './class 7/books/history/NCERT class 7 history ch8.pdf'
import class7mahabharatch1 from './class 7/books/mahabharat/NCERT class 7 mahabharat ch1.pdf'
import class7sanskritch1 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch1.pdf'
import class7sanskritch2 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch2.pdf'
import class7sanskritch3 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch3.pdf'
import class7sanskritch4 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch4.pdf'
import class7sanskritch5 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch5.pdf'
import class7sanskritch6 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch6.pdf'
import class7sanskritch7 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch7.pdf'
import class7sanskritch8 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch8.pdf'
import class7sanskritch9 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch9.pdf'
import class7sanskritch10 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch10.pdf'
import class7sanskritch11 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch11.pdf'
import class7sanskritch12 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch12.pdf'
import class7sanskritch13 from './class 7/books/sanskrit/NCERT class 7 sanskrit ch13.pdf'
import class7sciencech1 from './class 7/books/science/NCERT class 7 science ch1.pdf'
import class7sciencech2 from './class 7/books/science/NCERT class 7 science ch2.pdf'
import class7sciencech3 from './class 7/books/science/NCERT class 7 science ch3.pdf'
import class7sciencech4 from './class 7/books/science/NCERT class 7 science ch4.pdf'
import class7sciencech5 from './class 7/books/science/NCERT class 7 science ch5.pdf'
import class7sciencech6 from './class 7/books/science/NCERT class 7 science ch6.pdf'
import class7sciencech7 from './class 7/books/science/NCERT class 7 science ch7.pdf'
import class7sciencech8 from './class 7/books/science/NCERT class 7 science ch8.pdf'
import class7sciencech9 from './class 7/books/science/NCERT class 7 science ch9.pdf'
import class7sciencech10 from './class 7/books/science/NCERT class 7 science ch10.pdf'
import class7sciencech11 from './class 7/books/science/NCERT class 7 science ch11.pdf'
import class7sciencech12 from './class 7/books/science/NCERT class 7 science ch12.pdf'
import class7sciencech13 from './class 7/books/science/NCERT class 7 science ch13.pdf'
import class7vasantch1 from './class 7/books/vasant/NCERT class 7 vasant ch1.pdf'
import class7vasantch2 from './class 7/books/vasant/NCERT class 7 vasant ch2.pdf'
import class7vasantch3 from './class 7/books/vasant/NCERT class 7 vasant ch3.pdf'
import class7vasantch4 from './class 7/books/vasant/NCERT class 7 vasant ch4.pdf'
import class7vasantch5 from './class 7/books/vasant/NCERT class 7 vasant ch5.pdf'
import class7vasantch6 from './class 7/books/vasant/NCERT class 7 vasant ch6.pdf'
import class7vasantch7 from './class 7/books/vasant/NCERT class 7 vasant ch7.pdf'
import class7vasantch8 from './class 7/books/vasant/NCERT class 7 vasant ch8.pdf'
import class7vasantch9 from './class 7/books/vasant/NCERT class 7 vasant ch9.pdf'
import class7vasantch10 from './class 7/books/vasant/NCERT class 7 vasant ch10.pdf'
import class7vasantch11 from './class 7/books/vasant/NCERT class 7 vasant ch11.pdf'
import class7vasantch12 from './class 7/books/vasant/NCERT class 7 vasant ch12.pdf'
import class7vasantch13 from './class 7/books/vasant/NCERT class 7 vasant ch13.pdf'
import class7vasantch14 from './class 7/books/vasant/NCERT class 7 vasant ch14.pdf'
import class7vasantch15 from './class 7/books/vasant/NCERT class 7 vasant ch15.pdf'
import class8bharatkikhojch1 from './class 8/books/bharat ki khoj/NCERT class 8 BKK ch1.pdf'
import class8bharatkikhojch2 from './class 8/books/bharat ki khoj/NCERT class 8 BKK ch2.pdf'
import class8bharatkikhojch3 from './class 8/books/bharat ki khoj/NCERT class 8 BKK ch3.pdf'
import class8bharatkikhojch4 from './class 8/books/bharat ki khoj/NCERT class 8 BKK ch4.pdf'
import class8bharatkikhojch5 from './class 8/books/bharat ki khoj/NCERT class 8 BKK ch5.pdf'
import class8bharatkikhojch6 from './class 8/books/bharat ki khoj/NCERT class 8 BKK ch6.pdf'
import class8bharatkikhojch7 from './class 8/books/bharat ki khoj/NCERT class 8 BKK ch7.pdf'
import class8bharatkikhojch8 from './class 8/books/bharat ki khoj/NCERT class 8 BKK ch8.pdf'
import class8bharatkikhojch9 from './class 8/books/bharat ki khoj/NCERT class 8 BKK ch9.pdf'
import class8civicsch1 from './class 8/books/civics/NCERT class 8 civics ch1.pdf'
import class8civicsch2 from './class 8/books/civics/NCERT class 8 civics ch2.pdf'
import class8civicsch3 from './class 8/books/civics/NCERT class 8 civics ch3.pdf'
import class8civicsch4 from './class 8/books/civics/NCERT class 8 civics ch4.pdf'
import class8civicsch5 from './class 8/books/civics/NCERT class 8 civics ch5.pdf'
import class8civicsch6 from './class 8/books/civics/NCERT class 8 civics ch6.pdf'
import class8civicsch7 from './class 8/books/civics/NCERT class 8 civics ch7.pdf'
import class8civicsch8 from './class 8/books/civics/NCERT class 8 civics ch8.pdf'
import class8geoch1 from './class 8/books/geo/NCERT class 8 geo ch1.pdf'
import class8geoch2 from './class 8/books/geo/NCERT class 8 geo ch2.pdf'
import class8geoch3 from './class 8/books/geo/NCERT class 8 geo ch3.pdf'
import class8geoch4 from './class 8/books/geo/NCERT class 8 geo ch4.pdf'
import class8geoch5 from './class 8/books/geo/NCERT class 8 geo ch5.pdf'
import class8historych1 from './class 8/books/history/NCERT class 8 history ch1.pdf'
import class8historych2 from './class 8/books/history/NCERT class 8 history ch2.pdf'
import class8historych3 from './class 8/books/history/NCERT class 8 history ch3.pdf'
import class8historych4 from './class 8/books/history/NCERT class 8 history ch4.pdf'
import class8historych5 from './class 8/books/history/NCERT class 8 history ch5.pdf'
import class8historych6 from './class 8/books/history/NCERT class 8 history ch6.pdf'
import class8historych7 from './class 8/books/history/NCERT class 8 history ch7.pdf'
import class8historych8 from './class 8/books/history/NCERT class 8 history ch8.pdf'
import class8honeych1 from './class 8/books/honeydew/NCERT class 8 honeydew ch1.pdf'
import class8honeych2 from './class 8/books/honeydew/NCERT class 8 honeydew ch2.pdf'
import class8honeych3 from './class 8/books/honeydew/NCERT class 8 honeydew ch3.pdf'
import class8honeych4 from './class 8/books/honeydew/NCERT class 8 honeydew ch4.pdf'
import class8honeych5 from './class 8/books/honeydew/NCERT class 8 honeydew ch5.pdf'
import class8honeych6 from './class 8/books/honeydew/NCERT class 8 honeydew ch6.pdf'
import class8honeych7 from './class 8/books/honeydew/NCERT class 8 honeydew ch7.pdf'
import class8honeych8 from './class 8/books/honeydew/NCERT class 8 honeydew ch8.pdf'
import class8mathsch1 from './class 8/books/maths/NCERT class 8 maths ch1.pdf'
import class8mathsch2 from './class 8/books/maths/NCERT class 8 maths ch2.pdf'
import class8mathsch3 from './class 8/books/maths/NCERT class 8 maths ch3.pdf'
import class8mathsch4 from './class 8/books/maths/NCERT class 8 maths ch4.pdf'
import class8mathsch5 from './class 8/books/maths/NCERT class 8 maths ch5.pdf'
import class8mathsch6 from './class 8/books/maths/NCERT class 8 maths ch6.pdf'
import class8mathsch7 from './class 8/books/maths/NCERT class 8 maths ch7.pdf'
import class8mathsch8 from './class 8/books/maths/NCERT class 8 maths ch8.pdf'
import class8mathsch9 from './class 8/books/maths/NCERT class 8 maths ch9.pdf'
import class8mathsch10 from './class 8/books/maths/NCERT class 8 maths ch10.pdf'
import class8mathsch11 from './class 8/books/maths/NCERT class 8 maths ch11.pdf'
import class8mathsch12 from './class 8/books/maths/NCERT class 8 maths ch12.pdf'
import class8mathsch13 from './class 8/books/maths/NCERT class 8 maths ch13.pdf'
import class8sanskritch1 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch1.pdf'
import class8sanskritch2 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch2.pdf'
import class8sanskritch3 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch3.pdf'
import class8sanskritch4 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch4.pdf'
import class8sanskritch5 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch5.pdf'
import class8sanskritch6 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch6.pdf'
import class8sanskritch7 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch7.pdf'
import class8sanskritch8 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch8.pdf'
import class8sanskritch9 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch9.pdf'
import class8sanskritch10 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch10.pdf'
import class8sanskritch11 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch11.pdf'
import class8sanskritch12 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch12.pdf'
import class8sanskritch13 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch13.pdf'
import class8sanskritch14 from './class 8/books/sanskrit/Ncert class 8 sanskrit ch14.pdf'
import class8sciencech1 from './class 8/books/science/NCERT class 8 science ch1.pdf'
import class8sciencech2 from './class 8/books/science/NCERT class 8 science ch2.pdf'
import class8sciencech3 from './class 8/books/science/NCERT class 8 science ch3.pdf'
import class8sciencech4 from './class 8/books/science/NCERT class 8 science ch4.pdf'
import class8sciencech5 from './class 8/books/science/NCERT class 8 science ch5.pdf'
import class8sciencech6 from './class 8/books/science/NCERT class 8 science ch6.pdf'
import class8sciencech7 from './class 8/books/science/NCERT class 8 science ch7.pdf'
import class8sciencech8 from './class 8/books/science/NCERT class 8 science ch8.pdf'
import class8sciencech9 from './class 8/books/science/NCERT class 8 science ch9.pdf'
import class8sciencech10 from './class 8/books/science/NCERT class 8 science ch10.pdf'
import class8sciencech11 from './class 8/books/science/NCERT class 8 science ch11.pdf'
import class8sciencech12 from './class 8/books/science/NCERT class 8 science ch12.pdf'
import class8sciencech13 from './class 8/books/science/NCERT class 8 science ch13.pdf'
import class8sohch1 from './class 8/books/sohappened/NCERT class 8 sohppned ch1.pdf'
import class8sohch2 from './class 8/books/sohappened/NCERT class 8 sohppned ch2.pdf'
import class8sohch3 from './class 8/books/sohappened/NCERT class 8 sohppned ch3.pdf'
import class8sohch4 from './class 8/books/sohappened/NCERT class 8 sohppned ch4.pdf'
import class8sohch5 from './class 8/books/sohappened/NCERT class 8 sohppned ch5.pdf'
import class8sohch6 from './class 8/books/sohappened/NCERT class 8 sohppned ch6.pdf'
import class8sohch7 from './class 8/books/sohappened/NCERT class 8 sohppned ch7.pdf'
import class8sohch8 from './class 8/books/sohappened/NCERT class 8 sohppned ch8.pdf'
import class8vasantch1 from './class 8/books/vasant/NCERT class 8 vasant ch1.pdf'
import class8vasantch2 from './class 8/books/vasant/NCERT class 8 vasant ch2.pdf'
import class8vasantch3 from './class 8/books/vasant/NCERT class 8 vasant ch3.pdf'
import class8vasantch4 from './class 8/books/vasant/NCERT class 8 vasant ch4.pdf'
import class8vasantch5 from './class 8/books/vasant/NCERT class 8 vasant ch5.pdf'
import class8vasantch6 from './class 8/books/vasant/NCERT class 8 vasant ch6.pdf'
import class8vasantch7 from './class 8/books/vasant/NCERT class 8 vasant ch7.pdf'
import class8vasantch8 from './class 8/books/vasant/NCERT class 8 vasant ch8.pdf'
import class8vasantch9 from './class 8/books/vasant/NCERT class 8 vasant ch9.pdf'
import class8vasantch10 from './class 8/books/vasant/NCERT class 8 vasant ch10.pdf'
import class8vasantch11 from './class 8/books/vasant/NCERT class 8 vasant ch11.pdf'
import class8vasantch12 from './class 8/books/vasant/NCERT class 8 vasant ch12.pdf'
import class8vasantch13 from './class 8/books/vasant/NCERT class 8 vasant ch13.pdf'
import class9beehivech1 from './class 9/books/beehive/Ncert class 9 beehive ch1.pdf'
import class9beehivech2 from './class 9/books/beehive/Ncert class 9 beehive ch2.pdf'
import class9beehivech3 from './class 9/books/beehive/Ncert class 9 beehive ch3.pdf'
import class9beehivech4 from './class 9/books/beehive/Ncert class 9 beehive ch4.pdf'
import class9beehivech5 from './class 9/books/beehive/Ncert class 9 beehive ch5.pdf'
import class9beehivech6 from './class 9/books/beehive/Ncert class 9 beehive ch6.pdf'
import class9beehivech7 from './class 9/books/beehive/Ncert class 9 beehive ch7.pdf'
import class9beehivech8 from './class 9/books/beehive/Ncert class 9 beehive ch8.pdf'
import class9beehivech9 from './class 9/books/beehive/Ncert class 9 beehive ch9.pdf'
import class9civicsch1 from './class 9/books/civics/Ncert class 9 civics ch1.pdf'
import class9civicsch2 from './class 9/books/civics/Ncert class 9 civics ch2.pdf'
import class9civicsch3 from './class 9/books/civics/Ncert class 9 civics ch3.pdf'
import class9civicsch4 from './class 9/books/civics/Ncert class 9 civics ch4.pdf'
import class9civicsch5 from './class 9/books/civics/Ncert class 9 civics ch5.pdf'
import class9ecoch1 from './class 9/books/eco/Ncert class 9 eco ch1.pdf'
import class9ecoch2 from './class 9/books/eco/Ncert class 9 eco ch2.pdf'
import class9ecoch3 from './class 9/books/eco/Ncert class 9 eco ch3.pdf'
import class9ecoch4 from './class 9/books/eco/Ncert class 9 eco ch4.pdf'
import class9geoch1 from './class 9/books/geo/Ncert class 9 geo ch1.pdf'
import class9geoch2 from './class 9/books/geo/Ncert class 9 geo ch2.pdf'
import class9geoch3 from './class 9/books/geo/Ncert class 9 geo ch3.pdf'
import class9geoch4 from './class 9/books/geo/Ncert class 9 geo ch4.pdf'
import class9geoch5 from './class 9/books/geo/Ncert class 9 geo ch5.pdf'
import class9geoch6 from './class 9/books/geo/Ncert class 9 geo ch6.pdf'
import class9historych1 from './class 9/books/history/Ncert class 9 history ch1.pdf'
import class9historych2 from './class 9/books/history/Ncert class 9 history ch2.pdf'
import class9historych3 from './class 9/books/history/Ncert class 9 history ch3.pdf'
import class9historych4 from './class 9/books/history/Ncert class 9 history ch4.pdf'
import class9historych5 from './class 9/books/history/Ncert class 9 history ch5.pdf'
import class9kritikach1 from './class 9/books/kritika/Ncert class 9 kritika ch1.pdf'
import class9kritikach2 from './class 9/books/kritika/Ncert class 9 kritika ch2.pdf'
import class9kritikach3 from './class 9/books/kritika/Ncert class 9 kritika ch3.pdf'
import class9mathsch1 from './class 9/books/maths/Ncert class 9 maths ch1.pdf'
import class9mathsch2 from './class 9/books/maths/Ncert class 9 maths ch2.pdf'
import class9mathsch3 from './class 9/books/maths/Ncert class 9 maths ch3.pdf'
import class9mathsch4 from './class 9/books/maths/Ncert class 9 maths ch4.pdf'
import class9mathsch5 from './class 9/books/maths/Ncert class 9 maths ch5.pdf'
import class9mathsch6 from './class 9/books/maths/Ncert class 9 maths ch6.pdf'
import class9mathsch7 from './class 9/books/maths/Ncert class 9 maths ch7.pdf'
import class9mathsch8 from './class 9/books/maths/Ncert class 9 maths ch8.pdf'
import class9mathsch9 from './class 9/books/maths/Ncert class 9 maths ch9.pdf'
import class9mathsch10 from './class 9/books/maths/Ncert class 9 maths ch10.pdf'
import class9mathsch11 from './class 9/books/maths/Ncert class 9 maths ch11.pdf'
import class9mathsch12 from './class 9/books/maths/Ncert class 9 maths ch12.pdf'
import class9momentsch1 from './class 9/books/moments supply/Ncert class 9 moments ch1.pdf'
import class9momentsch2 from './class 9/books/moments supply/Ncert class 9 moments ch1.pdf'
import class9momentsch3 from './class 9/books/moments supply/Ncert class 9 moments ch1.pdf'
import class9momentsch4 from './class 9/books/moments supply/Ncert class 9 moments ch1.pdf'
import class9momentsch5 from './class 9/books/moments supply/Ncert class 9 moments ch1.pdf'
import class9momentsch6 from './class 9/books/moments supply/Ncert class 9 moments ch1.pdf'
import class9momentsch7 from './class 9/books/moments supply/Ncert class 9 moments ch1.pdf'
import class9momentsch8 from './class 9/books/moments supply/Ncert class 9 moments ch1.pdf'
import class9momentsch9 from './class 9/books/moments supply/Ncert class 9 moments ch1.pdf'
import class9sanskritch1 from './class 9/books/sanskrit/Ncert class 9 sanskrit ch1.pdf'
import class9sanskritch2 from './class 9/books/sanskrit/Ncert class 9 sanskrit ch2.pdf'
import class9sanskritch3 from './class 9/books/sanskrit/Ncert class 9 sanskrit ch3.pdf'
import class9sanskritch4 from './class 9/books/sanskrit/Ncert class 9 sanskrit ch4.pdf'
import class9sanskritch5 from './class 9/books/sanskrit/Ncert class 9 sanskrit ch5.pdf'
import class9sanskritch6 from './class 9/books/sanskrit/Ncert class 9 sanskrit ch6.pdf'
import class9sanskritch7 from './class 9/books/sanskrit/Ncert class 9 sanskrit ch7.pdf'
import class9sanskritch8 from './class 9/books/sanskrit/Ncert class 9 sanskrit ch8.pdf'
import class9sanskritch9 from './class 9/books/sanskrit/Ncert class 9 sanskrit ch9.pdf'
import class9sanskritch10 from './class 9/books/sanskrit/Ncert class 9 sanskrit ch10.pdf'
import class9sciencech1 from './class 9/books/science/Ncert class 9 science ch1.pdf'
import class9sciencech2 from './class 9/books/science/Ncert class 9 science ch2.pdf'
import class9sciencech3 from './class 9/books/science/Ncert class 9 science ch3.pdf'
import class9sciencech4 from './class 9/books/science/Ncert class 9 science ch4.pdf'
import class9sciencech5 from './class 9/books/science/Ncert class 9 science ch5.pdf'
import class9sciencech6 from './class 9/books/science/Ncert class 9 science ch6.pdf'
import class9sciencech7 from './class 9/books/science/Ncert class 9 science ch7.pdf'
import class9sciencech8 from './class 9/books/science/Ncert class 9 science ch8.pdf'
import class9sciencech9 from './class 9/books/science/Ncert class 9 science ch9.pdf'
import class9sciencech10 from './class 9/books/science/Ncert class 9 science ch10.pdf'
import class9sciencech11 from './class 9/books/science/Ncert class 9 science ch11.pdf'
import class9sciencech12 from './class 9/books/science/Ncert class 9 science ch12.pdf'
import class9shitizch1 from './class 9/books/shitiz/Ncert class 9 shitiz ch1.pdf'
import class9shitizch2 from './class 9/books/shitiz/Ncert class 9 shitiz ch2.pdf'
import class9shitizch3 from './class 9/books/shitiz/Ncert class 9 shitiz ch3.pdf'
import class9shitizch4 from './class 9/books/shitiz/Ncert class 9 shitiz ch4.pdf'
import class9shitizch5 from './class 9/books/shitiz/Ncert class 9 shitiz ch5.pdf'
import class9shitizch6 from './class 9/books/shitiz/Ncert class 9 shitiz ch6.pdf'
import class9shitizch7 from './class 9/books/shitiz/Ncert class 9 shitiz ch7.pdf'
import class9shitizch8 from './class 9/books/shitiz/Ncert class 9 shitiz ch8.pdf'
import class9shitizch9 from './class 9/books/shitiz/Ncert class 9 shitiz ch9.pdf'
import class9shitizch10 from './class 9/books/shitiz/Ncert class 9 shitiz ch10.pdf'
import class9shitizch11 from './class 9/books/shitiz/Ncert class 9 shitiz ch11.pdf'
import class9shitizch12 from './class 9/books/shitiz/Ncert class 9 shitiz ch12.pdf'
import class9shitizch13 from './class 9/books/shitiz/Ncert class 9 shitiz ch13.pdf'

import class10civicsch1 from './class 10/books/civics/Ncert class 10 civics ch1.pdf'
import class10civicsch2 from './class 10/books/civics/Ncert class 10 civics ch2.pdf'
import class10civicsch3 from './class 10/books/civics/Ncert class 10 civics ch3.pdf'
import class10civicsch4 from './class 10/books/civics/Ncert class 10 civics ch4.pdf'
import class10civicsch5 from './class 10/books/civics/Ncert class 10 civics ch5.pdf'
import class10ecoch1 from './class 10/books/eco/Ncert class 10 eco ch1.pdf'
import class10ecoch2 from './class 10/books/eco/Ncert class 10 eco ch2.pdf'
import class10ecoch3 from './class 10/books/eco/Ncert class 10 eco ch3.pdf'
import class10ecoch4 from './class 10/books/eco/Ncert class 10 eco ch4.pdf'
import class10ecoch5 from './class 10/books/eco/Ncert class 10 eco ch5.pdf'
import class10firstflightch1 from './class 10/books/firstflight/Ncert class 10 FirstF ch1.pdf'
import class10firstflightch2 from './class 10/books/firstflight/Ncert class 10 FirstF ch2.pdf'
import class10firstflightch3 from './class 10/books/firstflight/Ncert class 10 FirstF ch3.pdf'
import class10firstflightch4 from './class 10/books/firstflight/Ncert class 10 FirstF ch4.pdf'
import class10firstflightch5 from './class 10/books/firstflight/Ncert class 10 FirstF ch5.pdf'
import class10firstflightch6 from './class 10/books/firstflight/Ncert class 10 FirstF ch6.pdf'
import class10firstflightch7 from './class 10/books/firstflight/Ncert class 10 FirstF ch7.pdf'
import class10firstflightch8 from './class 10/books/firstflight/Ncert class 10 FirstF ch8.pdf'
import class10firstflightch9 from './class 10/books/firstflight/Ncert class 10 FirstF ch9.pdf'
import class10footch1 from './class 10/books/foot print/Ncert class 10 FootP ch1.pdf'
import class10footch2 from './class 10/books/foot print/Ncert class 10 FootP ch2.pdf'
import class10footch3 from './class 10/books/foot print/Ncert class 10 FootP ch3.pdf'
import class10footch4 from './class 10/books/foot print/Ncert class 10 FootP ch4.pdf'
import class10footch5 from './class 10/books/foot print/Ncert class 10 FootP ch5.pdf'
import class10footch6 from './class 10/books/foot print/Ncert class 10 FootP ch6.pdf'
import class10footch7 from './class 10/books/foot print/Ncert class 10 FootP ch7.pdf'
import class10footch8 from './class 10/books/foot print/Ncert class 10 FootP ch8.pdf'
import class10footch9 from './class 10/books/foot print/Ncert class 10 FootP ch9.pdf'
import class10geoch1 from './class 10/books/geo/Ncert class 10 geo ch1.pdf'
import class10geoch2 from './class 10/books/geo/Ncert class 10 geo ch2.pdf'
import class10geoch3 from './class 10/books/geo/Ncert class 10 geo ch3.pdf'
import class10geoch4 from './class 10/books/geo/Ncert class 10 geo ch4.pdf'
import class10geoch5 from './class 10/books/geo/Ncert class 10 geo ch5.pdf'
import class10geoch6 from './class 10/books/geo/Ncert class 10 geo ch6.pdf'
import class10geoch7 from './class 10/books/geo/Ncert class 10 geo ch7.pdf'
import class10historych1 from './class 10/books/history/Ncert class 10 history ch1.pdf'
import class10historych2 from './class 10/books/history/Ncert class 10 history ch2.pdf'
import class10historych3 from './class 10/books/history/Ncert class 10 history ch3.pdf'
import class10historych4 from './class 10/books/history/Ncert class 10 history ch4.pdf'
import class10historych5 from './class 10/books/history/Ncert class 10 history ch5.pdf'
import class10kritikach1 from './class 10/books/kritika/Ncert class 10 kritika ch1.pdf'
import class10kritikach2 from './class 10/books/kritika/Ncert class 10 kritika ch2.pdf'
import class10kritikach3 from './class 10/books/kritika/Ncert class 10 kritika ch3.pdf'
import class10mathsch1 from './class 10/books/maths/Ncert class 10 maths ch1.pdf'
import class10mathsch2 from './class 10/books/maths/Ncert class 10 maths ch2.pdf'
import class10mathsch3 from './class 10/books/maths/Ncert class 10 maths ch3.pdf'
import class10mathsch4 from './class 10/books/maths/Ncert class 10 maths ch4.pdf'
import class10mathsch5 from './class 10/books/maths/Ncert class 10 maths ch5.pdf'
import class10mathsch6 from './class 10/books/maths/Ncert class 10 maths ch6.pdf'
import class10mathsch7 from './class 10/books/maths/Ncert class 10 maths ch7.pdf'
import class10mathsch8 from './class 10/books/maths/Ncert class 10 maths ch8.pdf'
import class10mathsch9 from './class 10/books/maths/Ncert class 10 maths ch9.pdf'
import class10mathsch10 from './class 10/books/maths/Ncert class 10 maths ch10.pdf'
import class10mathsch11 from './class 10/books/maths/Ncert class 10 maths ch11.pdf'
import class10mathsch12 from './class 10/books/maths/Ncert class 10 maths ch12.pdf'
import class10mathsch13 from './class 10/books/maths/Ncert class 10 maths ch13.pdf'
import class10mathsch14 from './class 10/books/maths/Ncert class 10 maths ch14.pdf'
import class10sanskritch1 from './class 10/books/sanskrit/Ncert class 10 sanskrit ch1.pdf'
import class10sanskritch2 from './class 10/books/sanskrit/Ncert class 10 sanskrit ch2.pdf'
import class10sanskritch3 from './class 10/books/sanskrit/Ncert class 10 sanskrit ch3.pdf'
import class10sanskritch4 from './class 10/books/sanskrit/Ncert class 10 sanskrit ch4.pdf'
import class10sanskritch5 from './class 10/books/sanskrit/Ncert class 10 sanskrit ch5.pdf'
import class10sanskritch6 from './class 10/books/sanskrit/Ncert class 10 sanskrit ch6.pdf'
import class10sanskritch7 from './class 10/books/sanskrit/Ncert class 10 sanskrit ch7.pdf'
import class10sanskritch8 from './class 10/books/sanskrit/Ncert class 10 sanskrit ch8.pdf'
import class10sanskritch9 from './class 10/books/sanskrit/Ncert class 10 sanskrit ch9.pdf'
import class10sanskritch10 from './class 10/books/sanskrit/Ncert class 10 sanskrit ch10.pdf'
import class10sciencech1 from './class 10/books/science/Ncert class 10 science ch1.pdf'
import class10sciencech2 from './class 10/books/science/Ncert class 10 science ch2.pdf'
import class10sciencech3 from './class 10/books/science/Ncert class 10 science ch3.pdf'
import class10sciencech4 from './class 10/books/science/Ncert class 10 science ch4.pdf'
import class10sciencech5 from './class 10/books/science/Ncert class 10 science ch5.pdf'
import class10sciencech6 from './class 10/books/science/Ncert class 10 science ch6.pdf'
import class10sciencech7 from './class 10/books/science/Ncert class 10 science ch7.pdf'
import class10sciencech8 from './class 10/books/science/Ncert class 10 science ch8.pdf'
import class10sciencech9 from './class 10/books/science/Ncert class 10 science ch9.pdf'
import class10sciencech10 from './class 10/books/science/Ncert class 10 science ch10.pdf'
import class10sciencech11 from './class 10/books/science/Ncert class 10 science ch11.pdf'
import class10sciencech12 from './class 10/books/science/Ncert class 10 science ch12.pdf'
import class10sciencech13 from './class 10/books/science/Ncert class 10 science ch13.pdf'
import class10shitizch1 from './class 10/books/shitiz/Ncert class 10 shitiz ch1.pdf'
import class10shitizch2 from './class 10/books/shitiz/Ncert class 10 shitiz ch2.pdf'
import class10shitizch3 from './class 10/books/shitiz/Ncert class 10 shitiz ch3.pdf'
import class10shitizch4 from './class 10/books/shitiz/Ncert class 10 shitiz ch4.pdf'
import class10shitizch5 from './class 10/books/shitiz/Ncert class 10 shitiz ch5.pdf'
import class10shitizch6 from './class 10/books/shitiz/Ncert class 10 shitiz ch6.pdf'
import class10shitizch7 from './class 10/books/shitiz/Ncert class 10 shitiz ch7.pdf'
import class10shitizch8 from './class 10/books/shitiz/Ncert class 10 shitiz ch8.pdf'
import class10shitizch9 from './class 10/books/shitiz/Ncert class 10 shitiz ch9.pdf'
import class10shitizch10 from './class 10/books/shitiz/Ncert class 10 shitiz ch10.pdf'
import class10shitizch11 from './class 10/books/shitiz/Ncert class 10 shitiz ch11.pdf'
import class10shitizch12 from './class 10/books/shitiz/Ncert class 10 shitiz ch12.pdf'

import class11accountsch1 from './class 11/books/accounts/Ncert Class 11 Account ch1.pdf'
import class11accountsch2 from './class 11/books/accounts/Ncert Class 11 Account ch2.pdf'
import class11accountsch3 from './class 11/books/accounts/Ncert Class 11 Account ch3.pdf'
import class11accountsch4 from './class 11/books/accounts/Ncert Class 11 Account ch4.pdf'
import class11accountsch5 from './class 11/books/accounts/Ncert Class 11 Account ch5.pdf'
import class11antrach1 from './class 11/books/antra/Ncert class 11 antra ch1.pdf'
import class11antrach2 from './class 11/books/antra/Ncert class 11 antra ch2.pdf'
import class11antrach3 from './class 11/books/antra/Ncert class 11 antra ch3.pdf'
import class11antrach4 from './class 11/books/antra/Ncert class 11 antra ch4.pdf'
import class11antrach5 from './class 11/books/antra/Ncert class 11 antra ch5.pdf'
import class11antrach6 from './class 11/books/antra/Ncert class 11 antra ch6.pdf'
import class11antrach7 from './class 11/books/antra/Ncert class 11 antra ch7.pdf'
import class11antrach8 from './class 11/books/antra/Ncert class 11 antra ch8.pdf'
import class11antrach9 from './class 11/books/antra/Ncert class 11 antra ch9.pdf'
import class11antrach10 from './class 11/books/antra/Ncert class 11 antra ch10.pdf'
import class11antrach11 from './class 11/books/antra/Ncert class 11 antra ch11.pdf'
import class11antrach12 from './class 11/books/antra/Ncert class 11 antra ch12.pdf'
import class11antrach13 from './class 11/books/antra/Ncert class 11 antra ch13.pdf'
import class11antrach14 from './class 11/books/antra/Ncert class 11 antra ch14.pdf'
import class11antrach15 from './class 11/books/antra/Ncert class 11 antra ch15.pdf'
import class11antrach16 from './class 11/books/antra/Ncert class 11 antra ch16.pdf'
import class11bioch1 from './class 11/books/bio/Ncert class 11 bio ch1.pdf'
import class11bioch2 from './class 11/books/bio/Ncert class 11 bio ch2.pdf'
import class11bioch3 from './class 11/books/bio/Ncert class 11 bio ch3.pdf'
import class11bioch4 from './class 11/books/bio/Ncert class 11 bio ch4.pdf'
import class11bioch5 from './class 11/books/bio/Ncert class 11 bio ch5.pdf'
import class11bioch6 from './class 11/books/bio/Ncert class 11 bio ch6.pdf'
import class11bioch7 from './class 11/books/bio/Ncert class 11 bio ch7.pdf'
import class11bioch8 from './class 11/books/bio/Ncert class 11 bio ch8.pdf'
import class11bioch9 from './class 11/books/bio/Ncert class 11 bio ch9.pdf'
import class11bioch10 from './class 11/books/bio/Ncert class 11 bio ch10.pdf'
import class11bioch11 from './class 11/books/bio/Ncert class 11 bio ch11.pdf'
import class11bioch12 from './class 11/books/bio/Ncert class 11 bio ch12.pdf'
import class11bioch13 from './class 11/books/bio/Ncert class 11 bio ch13.pdf'
import class11bioch14 from './class 11/books/bio/Ncert class 11 bio ch14.pdf'
import class11bioch15 from './class 11/books/bio/Ncert class 11 bio ch15.pdf'
import class11bioch16 from './class 11/books/bio/Ncert class 11 bio ch16.pdf'
import class11bioch17 from './class 11/books/bio/Ncert class 11 bio ch17.pdf'
import class11bioch18 from './class 11/books/bio/Ncert class 11 bio ch18.pdf'
import class11bioch19 from './class 11/books/bio/Ncert class 11 bio ch19.pdf'

import class11biotechch1 from './class 11/books/biotechno/Ncert Class 11 biotec ch1.pdf'
import class11biotechch2 from './class 11/books/biotechno/Ncert Class 11 biotec ch2.pdf'
import class11biotechch3 from './class 11/books/biotechno/Ncert Class 11 biotec ch3.pdf'
import class11biotechch4 from './class 11/books/biotechno/Ncert Class 11 biotec ch4.pdf'
import class11biotechch5 from './class 11/books/biotechno/Ncert Class 11 biotec ch5.pdf'
import class11biotechch6 from './class 11/books/biotechno/Ncert Class 11 biotec ch6.pdf'
import class11biotechch7 from './class 11/books/biotechno/Ncert Class 11 biotec ch7.pdf'
import class11biotechch8 from './class 11/books/biotechno/Ncert Class 11 biotec ch8.pdf'
import class11biotechch9 from './class 11/books/biotechno/Ncert Class 11 biotec ch9.pdf'
import class11biotechch10 from './class 11/books/biotechno/Ncert Class 11 biotec ch10.pdf'
import class11biotechch11 from './class 11/books/biotechno/Ncert Class 11 biotec ch11.pdf'
import class11biotechch12 from './class 11/books/biotechno/Ncert Class 11 biotec ch12.pdf'

import class11bussstudych1 from './class 11/books/buss std/Ncert class 11 bus Std ch1.pdf'
import class11bussstudych2 from './class 11/books/buss std/Ncert class 11 bus Std ch2.pdf'
import class11bussstudych3 from './class 11/books/buss std/Ncert class 11 bus Std ch3.pdf'
import class11bussstudych4 from './class 11/books/buss std/Ncert class 11 bus Std ch4.pdf'
import class11bussstudych5 from './class 11/books/buss std/Ncert class 11 bus Std ch5.pdf'
import class11bussstudych6 from './class 11/books/buss std/Ncert class 11 bus Std ch6.pdf'
import class11bussstudych7 from './class 11/books/buss std/Ncert class 11 bus Std ch7.pdf'
import class11bussstudych8 from './class 11/books/buss std/Ncert class 11 bus Std ch8.pdf'
import class11bussstudych9 from './class 11/books/buss std/Ncert class 11 bus Std ch9.pdf'
import class11bussstudych10 from './class 11/books/buss std/Ncert class 11 bus Std ch10.pdf'
import class11bussstudych11 from './class 11/books/buss std/Ncert class 11 bus Std ch11.pdf'

import class11chemistry1ch1 from './class 11/books/chemistry/Ncert class 11 chemistry ch1.pdf'
import class11chemistry1ch2 from './class 11/books/chemistry/Ncert class 11 chemistry ch2.pdf'
import class11chemistry1ch3 from './class 11/books/chemistry/Ncert class 11 chemistry ch3.pdf'
import class11chemistry1ch4 from './class 11/books/chemistry/Ncert class 11 chemistry ch4.pdf'
import class11chemistry1ch5 from './class 11/books/chemistry/Ncert class 11 chemistry ch5.pdf'
import class11chemistry1ch6 from './class 11/books/chemistry/Ncert class 11 chemistry ch6.pdf'

import class11chemistry2ch1 from './class 11/books/chemistry 2/Ncert class 11 chem 2 ch1.pdf'
import class11chemistry2ch2 from './class 11/books/chemistry 2/Ncert class 11 chem 2 ch2.pdf'
import class11chemistry2ch3 from './class 11/books/chemistry 2/Ncert class 11 chem 2 ch3.pdf'

import class11csch1 from './class 11/books/cs/Ncert Class 11 cs ch1.pdf'
import class11csch2 from './class 11/books/cs/Ncert Class 11 cs ch2.pdf'
import class11csch3 from './class 11/books/cs/Ncert Class 11 cs ch3.pdf'
import class11csch4 from './class 11/books/cs/Ncert Class 11 cs ch4.pdf'
import class11csch5 from './class 11/books/cs/Ncert Class 11 cs ch5.pdf'
import class11csch6 from './class 11/books/cs/Ncert Class 11 cs ch6.pdf'
import class11csch7 from './class 11/books/cs/Ncert Class 11 cs ch7.pdf'
import class11csch8 from './class 11/books/cs/Ncert Class 11 cs ch8.pdf'
import class11csch9 from './class 11/books/cs/Ncert Class 11 cs ch9.pdf'
import class11csch10 from './class 11/books/cs/Ncert Class 11 cs ch10.pdf'
import class11csch11 from './class 11/books/cs/Ncert Class 11 cs ch11.pdf'

import class11ecoch1 from './class 11/books/eco/Ncert class 11 eco ch1.pdf'
import class11ecoch2 from './class 11/books/eco/Ncert class 11 eco ch2.pdf'
import class11ecoch3 from './class 11/books/eco/Ncert class 11 eco ch3.pdf'
import class11ecoch4 from './class 11/books/eco/Ncert class 11 eco ch4.pdf'
import class11ecoch5 from './class 11/books/eco/Ncert class 11 eco ch5.pdf'
import class11ecoch6 from './class 11/books/eco/Ncert class 11 eco ch6.pdf'
import class11ecoch7 from './class 11/books/eco/Ncert class 11 eco ch7.pdf'
import class11ecoch8 from './class 11/books/eco/Ncert class 11 eco ch8.pdf'



import class11fineartch1 from './class 11/books/fine art/Ncert Class 11 fineart ch1.pdf'
import class11fineartch2 from './class 11/books/fine art/Ncert Class 11 fineart ch2.pdf'
import class11fineartch3 from './class 11/books/fine art/Ncert Class 11 fineart ch3.pdf'
import class11fineartch4 from './class 11/books/fine art/Ncert Class 11 fineart ch4.pdf'
import class11fineartch5 from './class 11/books/fine art/Ncert Class 11 fineart ch5.pdf'
import class11fineartch6 from './class 11/books/fine art/Ncert Class 11 fineart ch6.pdf'
import class11fineartch7 from './class 11/books/fine art/Ncert Class 11 fineart ch7.pdf'
import class11fineartch8 from './class 11/books/fine art/Ncert Class 11 fineart ch8.pdf'

import class11geoch1 from './class 11/books/geo/Ncert class 11 geo ch1.pdf'
import class11geoch2 from './class 11/books/geo/Ncert class 11 geo ch2.pdf'
import class11geoch3 from './class 11/books/geo/Ncert class 11 geo ch3.pdf'
import class11geoch4 from './class 11/books/geo/Ncert class 11 geo ch4.pdf'
import class11geoch5 from './class 11/books/geo/Ncert class 11 geo ch5.pdf'
import class11geoch6 from './class 11/books/geo/Ncert class 11 geo ch6.pdf'
import class11geoch7 from './class 11/books/geo/Ncert class 11 geo ch7.pdf'
import class11geoch8 from './class 11/books/geo/Ncert class 11 geo ch8.pdf'
import class11geoch9 from './class 11/books/geo/Ncert class 11 geo ch9.pdf'
import class11geoch10 from './class 11/books/geo/Ncert class 11 geo ch10.pdf'
import class11geoch11 from './class 11/books/geo/Ncert class 11 geo ch11.pdf'
import class11geoch12 from './class 11/books/geo/Ncert class 11 geo ch12.pdf'
import class11geoch13 from './class 11/books/geo/Ncert class 11 geo ch13.pdf'
import class11geoch14 from './class 11/books/geo/Ncert class 11 geo ch14.pdf'

import class11historych1 from './class 11/books/history/Ncert class 11 history ch1.pdf'
import class11historych2 from './class 11/books/history/Ncert class 11 history ch2.pdf'
import class11historych3 from './class 11/books/history/Ncert class 11 history ch3.pdf'
import class11historych4 from './class 11/books/history/Ncert class 11 history ch4.pdf'
import class11historych5 from './class 11/books/history/Ncert class 11 history ch5.pdf'
import class11historych6 from './class 11/books/history/Ncert class 11 history ch6.pdf'
import class11historych7 from './class 11/books/history/Ncert class 11 history ch7.pdf'

import class11hornbellch1 from './class 11/books/hornbell/Ncert class 11 hornbill ch1.pdf'
import class11hornbellch2 from './class 11/books/hornbell/Ncert class 11 hornbill ch2.pdf'
import class11hornbellch3 from './class 11/books/hornbell/Ncert class 11 hornbill ch3.pdf'
import class11hornbellch4 from './class 11/books/hornbell/Ncert class 11 hornbill ch4.pdf'
import class11hornbellch5 from './class 11/books/hornbell/Ncert class 11 hornbill ch5.pdf'
import class11hornbellch6 from './class 11/books/hornbell/Ncert class 11 hornbill ch6.pdf'
import class11hornbellch7 from './class 11/books/hornbell/Ncert class 11 hornbill ch7.pdf'
import class11hornbellch8 from './class 11/books/hornbell/Ncert class 11 hornbill ch8.pdf'
import class11hornbellch9 from './class 11/books/hornbell/Ncert class 11 hornbill ch9.pdf'
import class11hornbellch10 from './class 11/books/hornbell/Ncert class 11 hornbill ch10.pdf'
import class11hornbellch11 from './class 11/books/hornbell/Ncert class 11 hornbill ch11.pdf'
import class11hornbellch12 from './class 11/books/hornbell/Ncert class 11 hornbill ch12.pdf'

import class11humanstd1ch1 from './class 11/books/human std 1/Ncert class 11 human science ch1.pdf'
import class11humanstd1ch2 from './class 11/books/human std 1/Ncert class 11 human science ch2.pdf'
import class11humanstd1ch3 from './class 11/books/human std 1/Ncert class 11 human science ch3.pdf'
import class11humanstd1ch4 from './class 11/books/human std 1/Ncert class 11 human science ch4.pdf'
import class11humanstd1ch5 from './class 11/books/human std 1/Ncert class 11 human science ch5.pdf'
import class11humanstd1ch6 from './class 11/books/human std 1/Ncert class 11 human science ch6.pdf'
import class11humanstd1ch7 from './class 11/books/human std 1/Ncert class 11 human science ch7.pdf'

import class11humanstd2ch1 from './class 11/books/human std 2/Ncert class 11 human science  2 ch1.pdf'
import class11humanstd2ch2 from './class 11/books/human std 2/Ncert class 11 human science  2 ch2.pdf'
import class11humanstd2ch3 from './class 11/books/human std 2/Ncert class 11 human science  2 ch3.pdf'
import class11humanstd2ch4 from './class 11/books/human std 2/Ncert class 11 human science  2 ch4.pdf'

import class11ipch1 from './class 11/books/ip/Ncert Class 11 ip ch1.pdf'
import class11ipch2 from './class 11/books/ip/Ncert Class 11 ip ch2.pdf'
import class11ipch3 from './class 11/books/ip/Ncert Class 11 ip ch3.pdf'
import class11ipch4 from './class 11/books/ip/Ncert Class 11 ip ch4.pdf'
import class11ipch5 from './class 11/books/ip/Ncert Class 11 ip ch5.pdf'
import class11ipch6 from './class 11/books/ip/Ncert Class 11 ip ch6.pdf'
import class11ipch7 from './class 11/books/ip/Ncert Class 11 ip ch7.pdf'
import class11ipch8 from './class 11/books/ip/Ncert Class 11 ip ch8.pdf'

import class11mathsch1 from './class 11/books/maths/Ncert class 11 maths ch1.pdf'
import class11mathsch2 from './class 11/books/maths/Ncert class 11 maths ch2.pdf'
import class11mathsch3 from './class 11/books/maths/Ncert class 11 maths ch3.pdf'
import class11mathsch4 from './class 11/books/maths/Ncert class 11 maths ch4.pdf'
import class11mathsch5 from './class 11/books/maths/Ncert class 11 maths ch5.pdf'
import class11mathsch6 from './class 11/books/maths/Ncert class 11 maths ch6.pdf'
import class11mathsch7 from './class 11/books/maths/Ncert class 11 maths ch7.pdf'
import class11mathsch8 from './class 11/books/maths/Ncert class 11 maths ch8.pdf'
import class11mathsch9 from './class 11/books/maths/Ncert class 11 maths ch9.pdf'
import class11mathsch10 from './class 11/books/maths/Ncert class 11 maths ch10.pdf'
import class11mathsch11 from './class 11/books/maths/Ncert class 11 maths ch11.pdf'
import class11mathsch12 from './class 11/books/maths/Ncert class 11 maths ch12.pdf'
import class11mathsch13 from './class 11/books/maths/Ncert class 11 maths ch13.pdf'
import class11mathsch14 from './class 11/books/maths/Ncert class 11 maths ch14.pdf'

import class11phyeduch1 from './class 11/books/phys edu/Ncert Class 11 phy edu ch1.pdf'
import class11phyeduch2 from './class 11/books/phys edu/Ncert Class 11 phy edu ch2.pdf'
import class11phyeduch3 from './class 11/books/phys edu/Ncert Class 11 phy edu ch3.pdf'
import class11phyeduch4 from './class 11/books/phys edu/Ncert Class 11 phy edu ch4.pdf'
import class11phyeduch5 from './class 11/books/phys edu/Ncert Class 11 phy edu ch5.pdf'
import class11phyeduch6 from './class 11/books/phys edu/Ncert Class 11 phy edu ch6.pdf'
import class11phyeduch7 from './class 11/books/phys edu/Ncert Class 11 phy edu ch7.pdf'
import class11phyeduch8 from './class 11/books/phys edu/Ncert Class 11 phy edu ch8.pdf'
import class11phyeduch9 from './class 11/books/phys edu/Ncert Class 11 phy edu ch9.pdf'
import class11phyeduch10 from './class 11/books/phys edu/Ncert Class 11 phy edu ch10.pdf'
import class11phyeduch11 from './class 11/books/phys edu/Ncert Class 11 phy edu ch11.pdf'

import class11phy1ch1 from './class 11/books/physics/Ncert class 11 phy ch1.pdf'
import class11phy1ch2 from './class 11/books/physics/Ncert class 11 phy ch2.pdf'
import class11phy1ch3 from './class 11/books/physics/Ncert class 11 phy ch3.pdf'
import class11phy1ch4 from './class 11/books/physics/Ncert class 11 phy ch4.pdf'
import class11phy1ch5 from './class 11/books/physics/Ncert class 11 phy ch5.pdf'
import class11phy1ch6 from './class 11/books/physics/Ncert class 11 phy ch6.pdf'
import class11phy1ch7 from './class 11/books/physics/Ncert class 11 phy ch7.pdf'

import class11phy2ch1 from './class 11/books/physics 2/Ncert class 11 phy 2 ch1.pdf'
import class11phy2ch2 from './class 11/books/physics 2/Ncert class 11 phy 2 ch2.pdf'
import class11phy2ch3 from './class 11/books/physics 2/Ncert class 11 phy 2 ch3.pdf'
import class11phy2ch4 from './class 11/books/physics 2/Ncert class 11 phy 2 ch4.pdf'
import class11phy2ch5 from './class 11/books/physics 2/Ncert class 11 phy 2 ch5.pdf'
import class11phy2ch6 from './class 11/books/physics 2/Ncert class 11 phy 2 ch6.pdf'
import class11phy2ch7 from './class 11/books/physics 2/Ncert class 11 phy 2 ch7.pdf'

import class11polsciencech1 from './class 11/books/pol science/Ncert class 11 pol science ch1.pdf'
import class11polsciencech2 from './class 11/books/pol science/Ncert class 11 pol science ch2.pdf'
import class11polsciencech3 from './class 11/books/pol science/Ncert class 11 pol science ch3.pdf'
import class11polsciencech4 from './class 11/books/pol science/Ncert class 11 pol science ch4.pdf'
import class11polsciencech5 from './class 11/books/pol science/Ncert class 11 pol science ch5.pdf'
import class11polsciencech6 from './class 11/books/pol science/Ncert class 11 pol science ch6.pdf'
import class11polsciencech7 from './class 11/books/pol science/Ncert class 11 pol science ch7.pdf'
import class11polsciencech8 from './class 11/books/pol science/Ncert class 11 pol science ch8.pdf'

import class11pyscologych1 from './class 11/books/psycology/Ncert class 11 pysco ch1.pdf'
import class11pyscologych2 from './class 11/books/psycology/Ncert class 11 pysco ch2.pdf'
import class11pyscologych3 from './class 11/books/psycology/Ncert class 11 pysco ch3.pdf'
import class11pyscologych4 from './class 11/books/psycology/Ncert class 11 pysco ch4.pdf'
import class11pyscologych5 from './class 11/books/psycology/Ncert class 11 pysco ch5.pdf'
import class11pyscologych6 from './class 11/books/psycology/Ncert class 11 pysco ch6.pdf'
import class11pyscologych7 from './class 11/books/psycology/Ncert class 11 pysco ch7.pdf'
import class11pyscologych8 from './class 11/books/psycology/Ncert class 11 pysco ch8.pdf'

import class11sangetch1 from './class 11/books/sangeet/Ncert Class 11 sangeet ch1.pdf'
import class11sangetch2 from './class 11/books/sangeet/Ncert Class 11 sangeet ch2.pdf'
import class11sangetch3 from './class 11/books/sangeet/Ncert Class 11 sangeet ch3.pdf'
import class11sangetch4 from './class 11/books/sangeet/Ncert Class 11 sangeet ch4.pdf'
import class11sangetch5 from './class 11/books/sangeet/Ncert Class 11 sangeet ch5.pdf'
import class11sangetch6 from './class 11/books/sangeet/Ncert Class 11 sangeet ch6.pdf'
import class11sangetch7 from './class 11/books/sangeet/Ncert Class 11 sangeet ch7.pdf'
import class11sangetch8 from './class 11/books/sangeet/Ncert Class 11 sangeet ch8.pdf'

import class11sanskritch1 from './class 11/books/sanskrit/Ncert Class 12 Sanskrit ch1.pdf'
import class11sanskritch2 from './class 11/books/sanskrit/Ncert Class 12 Sanskrit ch2.pdf'
import class11sanskritch3 from './class 11/books/sanskrit/Ncert Class 12 Sanskrit ch3.pdf'
import class11sanskritch4 from './class 11/books/sanskrit/Ncert Class 12 Sanskrit ch4.pdf'
import class11sanskritch5 from './class 11/books/sanskrit/Ncert Class 12 Sanskrit ch5.pdf'
import class11sanskritch6 from './class 11/books/sanskrit/Ncert Class 12 Sanskrit ch6.pdf'
import class11sanskritch7 from './class 11/books/sanskrit/Ncert Class 12 Sanskrit ch7.pdf'
import class11sanskritch8 from './class 11/books/sanskrit/Ncert Class 12 Sanskrit ch8.pdf'
import class11sanskritch9 from './class 11/books/sanskrit/Ncert Class 12 Sanskrit ch9.pdf'
import class11sanskritch10 from './class 11/books/sanskrit/Ncert Class 12 Sanskrit ch10.pdf'


import class11sanpshotch1 from './class 11/books/snapshot/Ncert class 11 snapshot ch1.pdf'
import class11sanpshotch2 from './class 11/books/snapshot/Ncert class 11 snapshot ch2.pdf'
import class11sanpshotch3 from './class 11/books/snapshot/Ncert class 11 snapshot ch3.pdf'
import class11sanpshotch4 from './class 11/books/snapshot/Ncert class 11 snapshot ch4.pdf'
import class11sanpshotch5 from './class 11/books/snapshot/Ncert class 11 snapshot ch5.pdf'

import class11sociologych1 from './class 11/books/sociology/Ncert class 11 sociology ch1.pdf'
import class11sociologych2 from './class 11/books/sociology/Ncert class 11 sociology ch2.pdf'
import class11sociologych3 from './class 11/books/sociology/Ncert class 11 sociology ch3.pdf'
import class11sociologych4 from './class 11/books/sociology/Ncert class 11 sociology ch4.pdf'
import class11sociologych5 from './class 11/books/sociology/Ncert class 11 sociology ch5.pdf'

import class11srijanch1 from './class 11/books/srijan/ncert class 11 srijan ch1.pdf'
import class11srijanch2 from './class 11/books/srijan/ncert class 11 srijan ch2.pdf'
import class11srijanch3 from './class 11/books/srijan/ncert class 11 srijan ch3.pdf'
import class11srijanch4 from './class 11/books/srijan/ncert class 11 srijan ch4.pdf'

import class11vitanch1 from './class 11/books/vitan/Ncert class 11 vitan ch1.pdf'
import class11vitanch2 from './class 11/books/vitan/Ncert class 11 vitan ch2.pdf'
import class11vitanch3 from './class 11/books/vitan/Ncert class 11 vitan ch3.pdf'
import class11vitanch4 from './class 11/books/vitan/Ncert class 11 vitan ch4.pdf'

import class12accounts1ch1 from './class 12/books/accounts/Ncert class 12 acc ch1.pdf'
import class12accounts1ch2 from './class 12/books/accounts/Ncert class 12 acc ch2.pdf'
import class12accounts1ch3 from './class 12/books/accounts/Ncert class 12 acc ch3.pdf'
import class12accounts1ch4 from './class 12/books/accounts/Ncert class 12 acc ch4.pdf'

import class12accounts2ch1 from './class 12/books/accounts 2/Ncert class 12 acc 2 ch1.pdf'
import class12accounts2ch2 from './class 12/books/accounts 2/Ncert class 12 acc 2 ch2.pdf'
import class12accounts2ch3 from './class 12/books/accounts 2/Ncert class 12 acc 2 ch3.pdf'
import class12accounts2ch4 from './class 12/books/accounts 2/Ncert class 12 acc 2 ch4.pdf'
import class12accounts2ch5 from './class 12/books/accounts 2/Ncert class 12 acc 2 ch5.pdf'
import class12accounts2ch6 from './class 12/books/accounts 2/Ncert class 12 acc 2 ch6.pdf'

import class12antrach1 from './class 12/books/antra/Ncert Class 12 Antra ch1.pdf'
import class12antrach2 from './class 12/books/antra/Ncert Class 12 Antra ch2.pdf'
import class12antrach3 from './class 12/books/antra/Ncert Class 12 Antra ch3.pdf'
import class12antrach4 from './class 12/books/antra/Ncert Class 12 Antra ch4.pdf'
import class12antrach5 from './class 12/books/antra/Ncert Class 12 Antra ch5.pdf'
import class12antrach6 from './class 12/books/antra/Ncert Class 12 Antra ch6.pdf'
import class12antrach7 from './class 12/books/antra/Ncert Class 12 Antra ch7.pdf'
import class12antrach8 from './class 12/books/antra/Ncert Class 12 Antra ch8.pdf'
import class12antrach9 from './class 12/books/antra/Ncert Class 12 Antra ch9.pdf'
import class12antrach10 from './class 12/books/antra/Ncert Class 12 Antra ch10.pdf'
import class12antrach11 from './class 12/books/antra/Ncert Class 12 Antra ch11.pdf'
import class12antrach12 from './class 12/books/antra/Ncert Class 12 Antra ch12.pdf'
import class12antrach13 from './class 12/books/antra/Ncert Class 12 Antra ch13.pdf'
import class12antrach14 from './class 12/books/antra/Ncert Class 12 Antra ch14.pdf'
import class12antrach15 from './class 12/books/antra/Ncert Class 12 Antra ch15.pdf'
import class12antrach16 from './class 12/books/antra/Ncert Class 12 Antra ch16.pdf'
import class12antrach17 from './class 12/books/antra/Ncert Class 12 Antra ch17.pdf'

import class12bioch1 from './class 12/books/bio/Ncert Class 12 Bio ch1.pdf'
import class12bioch2 from './class 12/books/bio/Ncert Class 12 Bio ch2.pdf'
import class12bioch3 from './class 12/books/bio/Ncert Class 12 Bio ch3.pdf'
import class12bioch4 from './class 12/books/bio/Ncert Class 12 Bio ch4.pdf'
import class12bioch5 from './class 12/books/bio/Ncert Class 12 Bio ch5.pdf'
import class12bioch6 from './class 12/books/bio/Ncert Class 12 Bio ch6.pdf'
import class12bioch7 from './class 12/books/bio/Ncert Class 12 Bio ch7.pdf'
import class12bioch8 from './class 12/books/bio/Ncert Class 12 Bio ch8.pdf'
import class12bioch9 from './class 12/books/bio/Ncert Class 12 Bio ch9.pdf'
import class12bioch10 from './class 12/books/bio/Ncert Class 12 Bio ch10.pdf'
import class12bioch11 from './class 12/books/bio/Ncert Class 12 Bio ch11.pdf'
import class12bioch12 from './class 12/books/bio/Ncert Class 12 Bio ch12.pdf'
import class12bioch13 from './class 12/books/bio/Ncert Class 12 Bio ch13.pdf'

import class12biotechnoch1 from './class 12/books/biotechno/Ncert Class 12 BioTech ch1.pdf'
import class12biotechnoch2 from './class 12/books/biotechno/Ncert Class 12 BioTech ch2.pdf'
import class12biotechnoch3 from './class 12/books/biotechno/Ncert Class 12 BioTech ch3.pdf'
import class12biotechnoch4 from './class 12/books/biotechno/Ncert Class 12 BioTech ch4.pdf'
import class12biotechnoch5 from './class 12/books/biotechno/Ncert Class 12 BioTech ch5.pdf'
import class12biotechnoch6 from './class 12/books/biotechno/Ncert Class 12 BioTech ch6.pdf'
import class12biotechnoch7 from './class 12/books/biotechno/Ncert Class 12 BioTech ch7.pdf'
import class12biotechnoch8 from './class 12/books/biotechno/Ncert Class 12 BioTech ch8.pdf'
import class12biotechnoch9 from './class 12/books/biotechno/Ncert Class 12 BioTech ch9.pdf'
import class12biotechnoch10 from './class 12/books/biotechno/Ncert Class 12 BioTech ch10.pdf'
import class12biotechnoch11 from './class 12/books/biotechno/Ncert Class 12 BioTech ch11.pdf'
import class12biotechnoch12 from './class 12/books/biotechno/Ncert Class 12 BioTech ch12.pdf'
import class12biotechnoch13 from './class 12/books/biotechno/Ncert Class 12 BioTech ch13.pdf'

import class12bussstud1ch1 from './class 12/books/buss std/Ncert Class 12 BussStudy ch1.pdf'
import class12bussstud1ch2 from './class 12/books/buss std/Ncert Class 12 BussStudy ch2.pdf'
import class12bussstud1ch3 from './class 12/books/buss std/Ncert Class 12 BussStudy ch3.pdf'
import class12bussstud1ch4 from './class 12/books/buss std/Ncert Class 12 BussStudy ch4.pdf'
import class12bussstud1ch5 from './class 12/books/buss std/Ncert Class 12 BussStudy ch5.pdf'
import class12bussstud1ch6 from './class 12/books/buss std/Ncert Class 12 BussStudy ch6.pdf'
import class12bussstud1ch7 from './class 12/books/buss std/Ncert Class 12 BussStudy ch7.pdf'
import class12bussstud1ch8 from './class 12/books/buss std/Ncert Class 12 BussStudy ch8.pdf'

import class12bussstud2ch1 from './class 12/books/bustudy2/Ncert Class 12 BussStudy 2 ch1.pdf'
import class12bussstud2ch2 from './class 12/books/bustudy2/Ncert Class 12 BussStudy 2 ch2.pdf'
import class12bussstud2ch3 from './class 12/books/bustudy2/Ncert Class 12 BussStudy 2 ch3.pdf'

import class12chemistry1ch1 from './class 12/books/chemistry/Ncert Class 12 Chm ch1.pdf'
import class12chemistry1ch2 from './class 12/books/chemistry/Ncert Class 12 Chm ch2.pdf'
import class12chemistry1ch3 from './class 12/books/chemistry/Ncert Class 12 Chm ch3.pdf'
import class12chemistry1ch4 from './class 12/books/chemistry/Ncert Class 12 Chm ch4.pdf'
import class12chemistry1ch5 from './class 12/books/chemistry/Ncert Class 12 Chm ch5.pdf'

import class12chemistry2ch1 from './class 12/books/chemistry 2/Ncert Class 12 Chm 2 ch1.pdf'
import class12chemistry2ch2 from './class 12/books/chemistry 2/Ncert Class 12 Chm 2 ch2.pdf'
import class12chemistry2ch3 from './class 12/books/chemistry 2/Ncert Class 12 Chm 2 ch3.pdf'
import class12chemistry2ch4 from './class 12/books/chemistry 2/Ncert Class 12 Chm 2 ch4.pdf'
import class12chemistry2ch5 from './class 12/books/chemistry 2/Ncert Class 12 Chm 2 ch5.pdf'

import class12csch1 from './class 12/books/cs/Ncert Class 12 CS ch1.pdf'
import class12csch2 from './class 12/books/cs/Ncert Class 12 CS ch2.pdf'
import class12csch3 from './class 12/books/cs/Ncert Class 12 CS ch3.pdf'
import class12csch4 from './class 12/books/cs/Ncert Class 12 CS ch4.pdf'
import class12csch5 from './class 12/books/cs/Ncert Class 12 CS ch5.pdf'
import class12csch6 from './class 12/books/cs/Ncert Class 12 CS ch6.pdf'
import class12csch7 from './class 12/books/cs/Ncert Class 12 CS ch7.pdf'
import class12csch8 from './class 12/books/cs/Ncert Class 12 CS ch8.pdf'
import class12csch9 from './class 12/books/cs/Ncert Class 12 CS ch9.pdf'
import class12csch10 from './class 12/books/cs/Ncert Class 12 CS ch10.pdf'
import class12csch11 from './class 12/books/cs/Ncert Class 12 CS ch11.pdf'
import class12csch12 from './class 12/books/cs/Ncert Class 12 CS ch12.pdf'
import class12csch13 from './class 12/books/cs/Ncert Class 12 CS ch13.pdf'

import class12eco1ch1 from './class 12/books/eco/Ncert Class 12 Eco 1 ch1.pdf'
import class12eco1ch2 from './class 12/books/eco/Ncert Class 12 Eco 1 ch2.pdf'
import class12eco1ch3 from './class 12/books/eco/Ncert Class 12 Eco 1 ch3.pdf'
import class12eco1ch4 from './class 12/books/eco/Ncert Class 12 Eco 1 ch4.pdf'
import class12eco1ch5 from './class 12/books/eco/Ncert Class 12 Eco 1 ch5.pdf'

import class12eco2ch1 from './class 12/books/eco 2/Ncert Class 12 Eco 2 ch1.pdf'
import class12eco2ch2 from './class 12/books/eco 2/Ncert Class 12 Eco 2 ch2.pdf'
import class12eco2ch3 from './class 12/books/eco 2/Ncert Class 12 Eco 2 ch3.pdf'
import class12eco2ch4 from './class 12/books/eco 2/Ncert Class 12 Eco 2 ch4.pdf'
import class12eco2ch5 from './class 12/books/eco 2/Ncert Class 12 Eco 2 ch5.pdf'
import class12eco2ch6 from './class 12/books/eco 2/Ncert Class 12 Eco 2 ch6.pdf'

import class12fineartch1 from './class 12/books/fine art/Ncert Class 12 Fineart ch1.pdf'
import class12fineartch2 from './class 12/books/fine art/Ncert Class 12 Fineart ch2.pdf'
import class12fineartch3 from './class 12/books/fine art/Ncert Class 12 Fineart ch3.pdf'
import class12fineartch4 from './class 12/books/fine art/Ncert Class 12 Fineart ch4.pdf'
import class12fineartch5 from './class 12/books/fine art/Ncert Class 12 Fineart ch5.pdf'
import class12fineartch6 from './class 12/books/fine art/Ncert Class 12 Fineart ch6.pdf'
import class12fineartch7 from './class 12/books/fine art/Ncert Class 12 Fineart ch7.pdf'
import class12fineartch8 from './class 12/books/fine art/Ncert Class 12 Fineart ch8.pdf'

import class12flamingoch1 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch1.pdf'
import class12flamingoch2 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch2.pdf'
import class12flamingoch3 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch3.pdf'
import class12flamingoch4 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch4.pdf'
import class12flamingoch5 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch5.pdf'
import class12flamingoch6 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch6.pdf'
import class12flamingoch7 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch7.pdf'
import class12flamingoch8 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch8.pdf'
import class12flamingoch9 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch9.pdf'
import class12flamingoch10 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch10.pdf'
import class12flamingoch11 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch11.pdf'
import class12flamingoch12 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch12.pdf'
import class12flamingoch13 from './class 12/books/flamingo/Ncert Class 12 Flamingo ch13.pdf'

import class12geoch1 from './class 12/books/geo/Ncert Class 12 Geo ch1.pdf'
import class12geoch2 from './class 12/books/geo/Ncert Class 12 Geo ch2.pdf'
import class12geoch3 from './class 12/books/geo/Ncert Class 12 Geo ch3.pdf'
import class12geoch4 from './class 12/books/geo/Ncert Class 12 Geo ch4.pdf'
import class12geoch5 from './class 12/books/geo/Ncert Class 12 Geo ch5.pdf'
import class12geoch6 from './class 12/books/geo/Ncert Class 12 Geo ch6.pdf'
import class12geoch7 from './class 12/books/geo/Ncert Class 12 Geo ch7.pdf'
import class12geoch8 from './class 12/books/geo/Ncert Class 12 Geo ch8.pdf'

import class12history1ch1 from './class 12/books/history/Ncert Class 12 History ch1.pdf'
import class12history1ch2 from './class 12/books/history/Ncert Class 12 History ch2.pdf'
import class12history1ch3 from './class 12/books/history/Ncert Class 12 History ch3.pdf'
import class12history1ch4 from './class 12/books/history/Ncert Class 12 History ch4.pdf'

import class12history2ch1 from './class 12/books/history 2/Ncert Class 12 History 2 ch1.pdf'
import class12history2ch2 from './class 12/books/history 2/Ncert Class 12 History 2 ch2.pdf'
import class12history2ch3 from './class 12/books/history 2/Ncert Class 12 History 2 ch3.pdf'
import class12history2ch4 from './class 12/books/history 2/Ncert Class 12 History 2 ch4.pdf'

import class12humanstdy1ch1 from './class 12/books/human std 1/Ncert Class 12 HomeSc ch1.pdf'
import class12humanstdy1ch2 from './class 12/books/human std 1/Ncert Class 12 HomeSc ch2.pdf'
import class12humanstdy1ch3 from './class 12/books/human std 1/Ncert Class 12 HomeSc ch3.pdf'
import class12humanstdy1ch4 from './class 12/books/human std 1/Ncert Class 12 HomeSc ch4.pdf'
import class12humanstdy1ch5 from './class 12/books/human std 1/Ncert Class 12 HomeSc ch5.pdf'
import class12humanstdy1ch6 from './class 12/books/human std 1/Ncert Class 12 HomeSc ch6.pdf'
import class12humanstdy1ch7 from './class 12/books/human std 1/Ncert Class 12 HomeSc ch7.pdf'

import class12humanstdy2ch1 from './class 12/books/human std 2/Ncert Class 12 HomeSc 2 ch1.pdf'
import class12humanstdy2ch2 from './class 12/books/human std 2/Ncert Class 12 HomeSc 2 ch3.pdf'
import class12humanstdy2ch3 from './class 12/books/human std 2/Ncert Class 12 HomeSc 2 ch3.pdf'
import class12humanstdy2ch4 from './class 12/books/human std 2/Ncert Class 12 HomeSc 2 ch4.pdf'
import class12humanstdy2ch5 from './class 12/books/human std 2/Ncert Class 12 HomeSc 2 ch5.pdf'
import class12humanstdy2ch6 from './class 12/books/human std 2/Ncert Class 12 HomeSc 2 ch6.pdf'
import class12humanstdy2ch7 from './class 12/books/human std 2/Ncert Class 12 HomeSc 2 ch7.pdf'

import class12itch1 from './class 12/books/ip/Ncert Class 12 IT ch1.pdf'
import class12itch2 from './class 12/books/ip/Ncert Class 12 IT ch2.pdf'
import class12itch3 from './class 12/books/ip/Ncert Class 12 IT ch3.pdf'
import class12itch4 from './class 12/books/ip/Ncert Class 12 IT ch4.pdf'
import class12itch5 from './class 12/books/ip/Ncert Class 12 IT ch5.pdf'
import class12itch6 from './class 12/books/ip/Ncert Class 12 IT ch6.pdf'
import class12itch7 from './class 12/books/ip/Ncert Class 12 IT ch7.pdf'

import class12maths1ch1 from './class 12/books/maths/Ncert Class 12 Maths ch1.pdf'
import class12maths1ch2 from './class 12/books/maths/Ncert Class 12 Maths ch2.pdf'
import class12maths1ch3 from './class 12/books/maths/Ncert Class 12 Maths ch3.pdf'
import class12maths1ch4 from './class 12/books/maths/Ncert Class 12 Maths ch4.pdf'
import class12maths1ch5 from './class 12/books/maths/Ncert Class 12 Maths ch5.pdf'
import class12maths1ch6 from './class 12/books/maths/Ncert Class 12 Maths ch6.pdf'

import class12maths2ch1 from './class 12/books/maths 2/Ncert Class 12 Maths 2 ch1.pdf'
import class12maths2ch2 from './class 12/books/maths 2/Ncert Class 12 Maths 2 ch2.pdf'
import class12maths2ch3 from './class 12/books/maths 2/Ncert Class 12 Maths 2 ch3.pdf'
import class12maths2ch4 from './class 12/books/maths 2/Ncert Class 12 Maths 2 ch4.pdf'
import class12maths2ch5 from './class 12/books/maths 2/Ncert Class 12 Maths 2 ch5.pdf'
import class12maths2ch6 from './class 12/books/maths 2/Ncert Class 12 Maths 2 ch6.pdf'
import class12maths2ch7 from './class 12/books/maths 2/Ncert Class 12 Maths 2 ch7.pdf'

import class12physics1ch1 from './class 12/books/physics/Ncert class 12 physics ch1.pdf'
import class12physics1ch2 from './class 12/books/physics/Ncert class 12 physics ch2.pdf'
import class12physics1ch3 from './class 12/books/physics/Ncert class 12 physics ch3.pdf'
import class12physics1ch4 from './class 12/books/physics/Ncert class 12 physics ch4.pdf'
import class12physics1ch5 from './class 12/books/physics/Ncert class 12 physics ch5.pdf'
import class12physics1ch6 from './class 12/books/physics/Ncert class 12 physics ch6.pdf'
import class12physics1ch7 from './class 12/books/physics/Ncert class 12 physics ch8.pdf'
import class12physics1ch8 from './class 12/books/physics/Ncert class 12 physics ch8.pdf'

import class12physics2ch1 from './class 12/books/physics 2/Ncert class 12 physics 2 ch1.pdf'
import class12physics2ch2 from './class 12/books/physics 2/Ncert class 12 physics 2 ch2.pdf'
import class12physics2ch3 from './class 12/books/physics 2/Ncert class 12 physics 2 ch3.pdf'
import class12physics2ch4 from './class 12/books/physics 2/Ncert class 12 physics 2 ch4.pdf'
import class12physics2ch5 from './class 12/books/physics 2/Ncert class 12 physics 2 ch5.pdf'
import class12physics2ch6 from './class 12/books/physics 2/Ncert class 12 physics 2 ch6.pdf'

import class12polsciencech1 from './class 12/books/pol science/Ncert Class 12 PolSci ch1.pdf'
import class12polsciencech2 from './class 12/books/pol science/Ncert Class 12 PolSci ch2.pdf'
import class12polsciencech3 from './class 12/books/pol science/Ncert Class 12 PolSci ch3.pdf'
import class12polsciencech4 from './class 12/books/pol science/Ncert Class 12 PolSci ch4.pdf'
import class12polsciencech5 from './class 12/books/pol science/Ncert Class 12 PolSci ch5.pdf'
import class12polsciencech6 from './class 12/books/pol science/Ncert Class 12 PolSci ch6.pdf'
import class12polsciencech7 from './class 12/books/pol science/Ncert Class 12 PolSci ch7.pdf'

import class12psycoch1 from './class 12/books/psycology/Ncert Class 12 Pyscho ch1.pdf'
import class12psycoch2 from './class 12/books/psycology/Ncert Class 12 Pyscho ch2.pdf'
import class12psycoch3 from './class 12/books/psycology/Ncert Class 12 Pyscho ch3.pdf'
import class12psycoch4 from './class 12/books/psycology/Ncert Class 12 Pyscho ch4.pdf'
import class12psycoch5 from './class 12/books/psycology/Ncert Class 12 Pyscho ch5.pdf'
import class12psycoch6 from './class 12/books/psycology/Ncert Class 12 Pyscho ch6.pdf'
import class12psycoch7 from './class 12/books/psycology/Ncert Class 12 Pyscho ch7.pdf'

import class12sangeetch1 from './class 12/books/sangeet/Ncert Class 12 Sangeet ch1.pdf'
import class12sangeetch2 from './class 12/books/sangeet/Ncert Class 12 Sangeet ch2.pdf'
import class12sangeetch3 from './class 12/books/sangeet/Ncert Class 12 Sangeet ch3.pdf'
import class12sangeetch4 from './class 12/books/sangeet/Ncert Class 12 Sangeet ch4.pdf'
import class12sangeetch5 from './class 12/books/sangeet/Ncert Class 12 Sangeet ch5.pdf'
import class12sangeetch6 from './class 12/books/sangeet/Ncert Class 12 Sangeet ch6.pdf'
import class12sangeetch7 from './class 12/books/sangeet/Ncert Class 12 Sangeet ch7.pdf'

import class12sociologych1 from './class 12/books/sociology/Ncert Class 12 Sociology ch1.pdf'
import class12sociologych2 from './class 12/books/sociology/Ncert Class 12 Sociology ch2.pdf'
import class12sociologych3 from './class 12/books/sociology/Ncert Class 12 Sociology ch3.pdf'
import class12sociologych4 from './class 12/books/sociology/Ncert Class 12 Sociology ch4.pdf'
import class12sociologych5 from './class 12/books/sociology/Ncert Class 12 Sociology ch5.pdf'
import class12sociologych6 from './class 12/books/sociology/Ncert Class 12 Sociology ch6.pdf'
import class12sociologych7 from './class 12/books/sociology/Ncert Class 12 Sociology ch7.pdf'

import class12srijanch1 from './class 12/books/srijan/Ncert Class 12 Sarijan ch1.pdf'
import class12srijanch2 from './class 12/books/srijan/Ncert Class 12 Sarijan ch2.pdf'
import class12srijanch3 from './class 12/books/srijan/Ncert Class 12 Sarijan ch3.pdf'
import class12srijanch4 from './class 12/books/srijan/Ncert Class 12 Sarijan ch4.pdf'

import class12vistasch1 from './class 12/books/vistas/Ncert Class 12 Vistas ch1.pdf'
import class12vistasch2 from './class 12/books/vistas/Ncert Class 12 Vistas ch2.pdf'
import class12vistasch3 from './class 12/books/vistas/Ncert Class 12 Vistas ch3.pdf'
import class12vistasch4 from './class 12/books/vistas/Ncert Class 12 Vistas ch4.pdf'
import class12vistasch5 from './class 12/books/vistas/Ncert Class 12 Vistas ch5.pdf'
import class12vistasch6 from './class 12/books/vistas/Ncert Class 12 Vistas ch6.pdf'

import class12vitanch1 from './class 12/books/vitan/Ncert Class 12 Vitan ch1.pdf'
import class12vitanch2 from './class 12/books/vitan/Ncert Class 12 Vitan ch2.pdf'
import class12vitanch3 from './class 12/books/vitan/Ncert Class 12 Vitan ch3.pdf'



let all_chapters=[
    {
        class:"1",
        name:"Maths",
        chapter:"01",
        chaptername:"A Day at the Beach",
        pdf:class1mathsch1
    },{
        class:"1",
        name:"Maths",
        chapter:"02",
        chaptername:"Shapes Around Us",
        pdf:class1mathsch2
    },{
        class:"1",
        name:"Maths",
        chapter:"03",
        chaptername:"Fun with Numbers",
        pdf:class1mathsch3
    },{
        class:"1",
        name:"Maths",
        chapter:"04",
        chaptername:"Shadow Story",
        pdf:class1mathsch4
    },{
        class:"1",
        name:"Maths",
        chapter:"05",
        chaptername:"Fun with Numbers",
        pdf:class1mathsch5
    },{
        class:"1",
        name:"Maths",
        chapter:"06",
        chaptername:"Fun with Numbers",
        pdf:class1mathsch6
    },{
        class:"1",
        name:"Maths",
        chapter:"07",
        chaptername:"Fun with Numbers",
        pdf:class1mathsch7
    },{
        class:"1",
        name:"Maths",
        chapter:"08",
        chaptername:"Fun with Numbers",
        pdf:class1mathsch8
    },{
        class:"1",
        name:"Maths",
        chapter:"09",
        chaptername:"Fun with Numbers",
        pdf:class1mathsch9
    },{
        class:"1",
        name:"Maths",
        chapter:"10",
        chaptername:"Fun with Numbers",
        pdf:class1mathsch10
    },{
        class:"1",
        name:"Maths",
        chapter:"11",
        chaptername:"Fun with Numbers",
        pdf:class1mathsch11
    },{
        class:"1",
        name:"Maths",
        chapter:"12",
        chaptername:"Fun with Numbers",
        pdf:class1mathsch12
    },{
        class:"1",
        name:"Maths",
        chapter:"13",
        chaptername:"Fun with Numbers",
        pdf:class1mathsch13
    },{
        class:"1",
        name:"Hindi",
        chapter:"01",
        chaptername:"परिवार",
        pdf:class1hindich1
    },{
        class:"1",
        name:"Hindi",
        chapter:"02",
        chaptername:"दादा-दादी",
        pdf:class1hindich2
    },{
        class:"1",
        name:"Hindi",
        chapter:"03",
        chaptername:"रीना का दिन",
        pdf:class1hindich3
    },{
        class:"1",
        name:"Hindi",
        chapter:"04",
        chaptername:"रानी भी",
        pdf:class1hindich4
    },{
        class:"1",
        name:"Hindi",
        chapter:"05",
        chaptername:"मिठाई",
        pdf:class1hindich5
    },{
        class:"1",
        name:"Hindi",
        chapter:"06",
        chaptername:"तीन साथी",
        pdf:class1hindich6
    },{
        class:"1",
        name:"Hindi",
        chapter:"07",
        chaptername:"वाह, मेरे घोड़े!",
        pdf:class1hindich7
    },{
        class:"1",
        name:"Hindi",
        chapter:"08",
        chaptername:"खतरे में साँप",
        pdf:class1hindich8
    },{
        class:"1",
        name:"Hindi",
        chapter:"09",
        chaptername:"आलू की सड़क",
        pdf:class1hindich9
    },{
        class:"1",
        name:"Hindi",
        chapter:"10",
        chaptername:"झूम-झूली",
        pdf:class1hindich10
    },{
        class:"1",
        name:"English",
        chapter:"01",
        chaptername:"My Family and Me",
        pdf:class1engch1
    },{
        class:"1",
        name:"English",
        chapter:"02",
        chaptername:"Greetings",
        pdf:class1engch2
    },{
        class:"1",
        name:"English",
        chapter:"03",
        chaptername:"Life Around Us",
        pdf:class1engch3
    },{
        class:"1",
        name:"English",
        chapter:"04",
        chaptername:"The Cap Seller and the Monkeys",
        pdf:class1engch4
    },{
        class:"1",
        name:"English",
        chapter:"05",
        chaptername:"A Farm",
        pdf:class1engch5
    },{
        class:"1",
        name:"English",
        chapter:"06",
        chaptername:"Food",
        pdf:class1engch6
    },{
        class:"1",
        name:"English",
        chapter:"07",
        chaptername:"The Food We Eat",
        pdf:class1engch7
    },{
        class:"1",
        name:"English",
        chapter:"08",
        chaptername:"Seasons",
        pdf:class1engch8
    },{
        class:"1",
        name:"English",
        chapter:"09",
        chaptername:"Anandi's Rainbow",
        pdf:class1engch9
    },{
        class:"2",
        name:"English",
        chapter:"01",
        chaptername:"My Bicycle",
        pdf:class2engch1
    },{
        class:"2",
        name:"English",
        chapter:"02",
        chaptername:"Picture Reading",
        pdf:class2engch2
    },{
        class:"2",
        name:"English",
        chapter:"03",
        chaptername:"It is Fun",
        pdf:class2engch3
    },{
        class:"2",
        name:"English",
        chapter:"04",
        chaptername:"Seeing without Seeing",
        pdf:class2engch4
    },{
        class:"2",
        name:"English",
        chapter:"05",
        chaptername:"Come Back Soon",
        pdf:class2engch5
    },{
        class:"2",
        name:"English",
        chapter:"06",
        chaptername:"Between Home and School",
        pdf:class2engch6
    },{
        class:"2",
        name:"English",
        chapter:"07",
        chaptername:"This is my Town",
        pdf:class2engch7
    },{
        class:"2",
        name:"English",
        chapter:"08",
        chaptername:"A Show of Clouds",
        pdf:class2engch8
    },{
        class:"2",
        name:"English",
        chapter:"09",
        chaptername:"My name",
        pdf:class2engch9
    },{
        class:"2",
        name:"English",
        chapter:"10",
        chaptername:"THe Crow",
        pdf:class2engch10
    },{
        class:"2",
        name:"English",
        chapter:"11",
        chaptername:"The Smart Monkey",
        pdf:class2engch11
    },{
        class:"2",
        name:"English",
        chapter:"12",
        chaptername:"Little Drops of Water",
        pdf:class2engch12
    },{
        class:"2",
        name:"English",
        chapter:"13",
        chaptername:"We are all Indians",
        pdf:class2engch13
    },{
        class:"2",
        name:"Maths",
        chapter:"01",
        chaptername:"A Day at the Beach",
        pdf:class2mathsch1
    },{
        class:"2",
        name:"Maths",
        chapter:"02",
        chaptername:"Shapes Around Us",
        pdf:class2mathsch2
    },{
        class:"2",
        name:"Maths",
        chapter:"03",
        chaptername:"Fun with Numbers",
        pdf:class2mathsch3
    },{
        class:"2",
        name:"Maths",
        chapter:"04",
        chaptername:"Shadow Story",
        pdf:class2mathsch4
    },{
        class:"2",
        name:"Maths",
        chapter:"05",
        chaptername:"Playing with Lines",
        pdf:class2mathsch5
    },{
        class:"2",
        name:"Maths",
        chapter:"06",
        chaptername:"Decoration for Festival",
        pdf:class2mathsch6
    },{
        class:"2",
        name:"Maths",
        chapter:"07",
        chaptername:"Rani’s Gift",
        pdf:class2mathsch7
    },{
        class:"2",
        name:"Maths",
        chapter:"08",
        chaptername:"Grouping and Sharing",
        pdf:class2mathsch8
    },{
        class:"2",
        name:"Maths",
        chapter:"09",
        chaptername:"Which Season is it?",
        pdf:class2mathsch9
    },{
        class:"2",
        name:"Maths",
        chapter:"10",
        chaptername:"Fun at the Fair",
        pdf:class2mathsch10
    },{
        class:"2",
        name:"Maths",
        chapter:"11",
        chaptername:"Data Handling",
        pdf:class2mathsch11
    },{
        class:"2",
        name:"Hindi",
        chapter:"01",
        chaptername:"नीम की दादी",
        pdf:class2hindich1
    },{
        class:"2",
        name:"Hindi",
        chapter:"02",
        chaptername:"घर",
        pdf:class2hindich2
    },{
        class:"2",
        name:"Hindi",
        chapter:"03",
        chaptername:"माला की चाँदी की पायल",
        pdf:class2hindich3
    },{
        class:"2",
        name:"Hindi",
        chapter:"04",
        chaptername:"माँ",
        pdf:class2hindich4
    },{
        class:"2",
        name:"Hindi",
        chapter:"05",
        chaptername:"थाथू और मैं",
        pdf:class2hindich5
    },{
        class:"2",
        name:"Hindi",
        chapter:"06",
        chaptername:"चींटा",
        pdf:class2hindich6
    },{
        class:"2",
        name:"Hindi",
        chapter:"07",
        chaptername:"टिल्लू जी",
        pdf:class2hindich7
    },{
        class:"2",
        name:"Hindi",
        chapter:"08",
        chaptername:"तीन दोस्त",
        pdf:class2hindich8
    },{
        class:"2",
        name:"Hindi",
        chapter:"09",
        chaptername:"दुनिया रंग-बिरंगी",
        pdf:class2hindich9
    },{
        class:"2",
        name:"Hindi",
        chapter:"10",
        chaptername:"कौन",
        pdf:class2hindich10
    },{
        class:"2",
        name:"Hindi",
        chapter:"11",
        chaptername:"बैंगनी जोजो",
        pdf:class2hindich11
    },{
        class:"2",
        name:"Hindi",
        chapter:"12",
        chaptername:"तोसिया का सपना",
        pdf:class2hindich12
    },{
        class:"2",
        name:"Hindi",
        chapter:"13",
        chaptername:"तालाब",
        pdf:class2hindich13
    },{
        class:"2",
        name:"Hindi",
        chapter:"14",
        chaptername:"बीज",
        pdf:class2hindich14
    },{
        class:"2",
        name:"Hindi",
        chapter:"15",
        chaptername:"किसान",
        pdf:class2hindich15
    },{
        class:"2",
        name:"Hindi",
        chapter:"16",
        chaptername:"मूली",
        pdf:class2hindich16
    },{
        class:"2",
        name:"Hindi",
        chapter:"17",
        chaptername:"बरसात और मेंढक",
        pdf:class2hindich17
    },{
        class:"2",
        name:"Hindi",
        chapter:"18",
        chaptername:"शेर और चूहे की दोस्ती",
        pdf:class2hindich18
    },{
        class:"2",
        name:"Hindi",
        chapter:"19",
        chaptername:"आउट",
        pdf:class2hindich19
    },{
        class:"2",
        name:"Hindi",
        chapter:"20",
        chaptername:"छुप-छुपाई",
        pdf:class2hindich20
    },{
        class:"2",
        name:"Hindi",
        chapter:"21",
        chaptername:"हाथी साइकिल चला रहा था",
        pdf:class2hindich21
    },{
        class:"2",
        name:"Hindi",
        chapter:"22",
        chaptername:"चार दिशाएँ",
        pdf:class2hindich22
    },{
        class:"2",
        name:"Hindi",
        chapter:"23",
        chaptername:"चंदा मामा",
        pdf:class2hindich23
    },{
        class:"2",
        name:"Hindi",
        chapter:"24",
        chaptername:"गिरे ताल में चंदा मामा",
        pdf:class2hindich24
    },{
        class:"2",
        name:"Hindi",
        chapter:"25",
        chaptername:"सबसे बड़ा छाता",
        pdf:class2hindich25
    },{
        class:"2",
        name:"Hindi",
        chapter:"26",
        chaptername:"बादल",
        pdf:class2hindich26
    },{
        class:"3",
        name:"Hindi",
        chapter:"01",
        chaptername:"सीखो",
        pdf:class3hindich1
    },{
        class:"3",
        name:"Hindi",
        chapter:"02",
        chaptername:"चींटी",
        pdf:class3hindich2
    },{
        class:"3",
        name:"Hindi",
        chapter:"03",
        chaptername:"कितने पैर?",
        pdf:class3hindich3
    },{
        class:"3",
        name:"Hindi",
        chapter:"04",
        chaptername:"हमारी चिड़िया रानी!",
        pdf:class3hindich4
    },{
        class:"3",
        name:"Hindi",
        chapter:"05",
        chaptername:"आम का पेड़",
        pdf:class3hindich5
    },{
        class:"3",
        name:"Hindi",
        chapter:"06",
        chaptername:"बीरबल की खिचड़ी",
        pdf:class3hindich6
    },{
        class:"3",
        name:"Hindi",
        chapter:"07",
        chaptername:"मित्र को पत्र",
        pdf:class3hindich7
    },{
        class:"3",
        name:"Hindi",
        chapter:"08",
        chaptername:"चतुर गीदड़",
        pdf:class3hindich8
    },{
        class:"3",
        name:"Hindi",
        chapter:"09",
        chaptername:"प्रकृति पर्व फूलदेई",
        pdf:class3hindich9
    },{
        class:"3",
        name:"Hindi",
        chapter:"10",
        chaptername:"रस्साकशी",
        pdf:class3hindich10
    },{
        class:"3",
        name:"Hindi",
        chapter:"11",
        chaptername:"एक जादुई पिटारा",
        pdf:class3hindich11
    },{
        class:"3",
        name:"Hindi",
        chapter:"12",
        chaptername:"अपना-अपना काम",
        pdf:class3hindich12
    },{
        class:"3",
        name:"Hindi",
        chapter:"13",
        chaptername:" पेड़ों की अम्मा ‘चिभक्का’ ",
        pdf:class3hindich13
    },{
        class:"3",
        name:"Hindi",
        chapter:"14",
        chaptername:"किसान की होशियारी",
        pdf:class3hindich14
    },{
        class:"3",
        name:"Hindi",
        chapter:"15",
        chaptername:"भारत",
        pdf:class3hindich15
    },{
        class:"3",
        name:"Hindi",
        chapter:"16",
        chaptername:"चंद्रयान (संवाद)",
        pdf:class3hindich16
    },{
        class:"3",
        name:"Hindi",
        chapter:"17",
        chaptername:"बोलने वाली माँद ",
        pdf:class3hindich17
    },{
        class:"3",
        name:"Hindi",
        chapter:"18",
        chaptername:"हम अनेक किंतु एक",
        pdf:class3hindich18
    },{
        class:"3",
        name:"English",
        chapter:"01",
        chaptername:"Colors",
        pdf:class3engch1
    },{
        class:"3",
        name:"English",
        chapter:"02",
        chaptername:"Badal and Moti",
        pdf:class3engch2
    },{
        class:"3",
        name:"English",
        chapter:"03",
        chaptername:"Best Friends",
        pdf:class3engch3
    },{
        class:"3",
        name:"English",
        chapter:"04",
        chaptername:"Out in the Garden",
        pdf:class3engch4
    },{
        class:"3",
        name:"English",
        chapter:"05",
        chaptername:"Talking Toys",
        pdf:class3engch5
    },{
        class:"3",
        name:"English",
        chapter:"06",
        chaptername:"Papers Boat",
        pdf:class3engch6
    },{
        class:"3",
        name:"English",
        chapter:"07",
        chaptername:"The Big Ladooo",
        pdf:class3engch7
    },{
        class:"3",
        name:"English",
        chapter:"08",
        chaptername:"Thank God",
        pdf:class3engch8
    },{
        class:"3",
        name:"English",
        chapter:"09",
        chaptername:"Madhu's Wish",
        pdf:class3engch9
    },{
        class:"3",
        name:"English",
        chapter:"10",
        chaptername:"Night",
        pdf:class3engch10
    },{
        class:"3",
        name:"English",
        chapter:"11",
        chaptername:"Chanda mama counts the Stars",
        pdf:class3engch11
    },{
        class:"3",
        name:"English",
        chapter:"12",
        chaptername:"Chandrayan",
        pdf:class3engch12
    },{
        class:"3",
        name:"Maths",
        chapter:"01",
        chaptername:"What is a Name",
        pdf:class3mathsch1
    },{
        class:"3",
        name:"Maths",
        chapter:"02",
        chaptername:"Toy Joy",
        pdf:class3mathsch2
    },{
        class:"3",
        name:"Maths",
        chapter:"03",
        chaptername:"Double Century",
        pdf:class3mathsch3
    },{
        class:"3",
        name:"Maths",
        chapter:"04",
        chaptername:"Vacation with My Nani Maa ",
        pdf:class3mathsch4
    },{
        class:"3",
        name:"Maths",
        chapter:"05",
        chaptername:"Fun with Shapes",
        pdf:class3mathsch5
    },{
        class:"3",
        name:"Maths",
        chapter:"06",
        chaptername:"House of Hundreds",
        pdf:class3mathsch6
    },{
        class:"3",
        name:"Maths",
        chapter:"07",
        chaptername:"Raksha Bandhan",
        pdf:class3mathsch7
    },{
        class:"3",
        name:"Maths",
        chapter:"08",
        chaptername:"Fair Share ",
        pdf:class3mathsch8
    },{
        class:"3",
        name:"Maths",
        chapter:"09",
        chaptername:"House of Hundreds - II ",
        pdf:class3mathsch9
    },{
        class:"3",
        name:"Maths",
        chapter:"10",
        chaptername:"Fun at Class Party!",
        pdf:class3mathsch10
    },{
        class:"3",
        name:"Maths",
        chapter:"11",
        chaptername:"Filling and Lifting",
        pdf:class3mathsch11
    },{
        class:"3",
        name:"Maths",
        chapter:"12",
        chaptername:"Give and Take",
        pdf:class3mathsch12
    },{
        class:"3",
        name:"Maths",
        chapter:"13",
        chaptername:"Time Goes On",
        pdf:class3mathsch13
    },{
        class:"3",
        name:"Maths",
        chapter:"14",
        chaptername:"The Surajkund Fair",
        pdf:class3mathsch14
    },{
        class:"4",
        name:"Maths",
        chapter:"01",
        chaptername:"Building with Bricks",
        pdf:class4mathsch1
    },{
        class:"4",
        name:"Maths",
        chapter:"02",
        chaptername:"Long and Short",
        pdf:class4mathsch2
    },{
        class:"4",
        name:"Maths",
        chapter:"03",
        chaptername:"A Trip to Bhopal",
        pdf:class4mathsch3
    },{
        class:"4",
        name:"Maths",
        chapter:"04",
        chaptername:"Tick-tick-tick",
        pdf:class4mathsch4
    },{
        class:"4",
        name:"Maths",
        chapter:"05",
        chaptername:"The Way the World Looks",
        pdf:class4mathsch5
    },{
        class:"4",
        name:"Maths",
        chapter:"06",
        chaptername:"The Junk Seller",
        pdf:class4mathsch6
    },{
        class:"4",
        name:"Maths",
        chapter:"07",
        chaptername:"Mugs and Mugs",
        pdf:class4mathsch7
    },{
        class:"4",
        name:"Maths",
        chapter:"08",
        chaptername:"Carts and Wheels",
        pdf:class4mathsch8
    },{
        class:"4",
        name:"Maths",
        chapter:"09",
        chaptername:"Halves and Quarters",
        pdf:class4mathsch9
    },{
        class:"4",
        name:"Maths",
        chapter:"10",
        chaptername:"Play with Patterns",
        pdf:class4mathsch10
    },{
        class:"4",
        name:"Maths",
        chapter:"11",
        chaptername:"Tables and Shares",
        pdf:class4mathsch11
    },{
        class:"4",
        name:"Maths",
        chapter:"12",
        chaptername:"How Heavy? How Light?",
        pdf:class4mathsch12
    },{
        class:"4",
        name:"Maths",
        chapter:"13",
        chaptername:"Fields and Fences",
        pdf:class4mathsch13
    },{
        class:"4",
        name:"Maths",
        chapter:"14",
        chaptername:"Smart Charts",
        pdf:class4mathsch14
    },{
        class:"4",
        name:"English",
        chapter:"01",
        chaptername:"Nehas Alarm Clock & Wake up",
        pdf:class4engch1
    },{
        class:"4",
        name:"English",
        chapter:"02",
        chaptername:"The Little fir tree & Noses",
        pdf:class4engch2
    },{
        class:"4",
        name:"English",
        chapter:"03",
        chaptername:"Nasruddins Aim & Run",
        pdf:class4engch3
    },{
        class:"4",
        name:"English",
        chapter:"04",
        chaptername:"Alice in Wonderland & Why",
        pdf:class4engch4
    },{
        class:"4",
        name:"English",
        chapter:"05",
        chaptername:"Helen Keller & Don’t be Afraid of the dark",
        pdf:class4engch5
    },{
        class:"4",
        name:"English",
        chapter:"06",
        chaptername:"The Scholars Mother Tongue & Hiawatha",
        pdf:class4engch6
    },{
        class:"4",
        name:"English",
        chapter:"07",
        chaptername:"The Giving Tree & A Watering Rhyme",
        pdf:class4engch7
    },{
        class:"4",
        name:"English",
        chapter:"08",
        chaptername:"Going to buy a Book & Poem Books",
        pdf:class4engch8
    },{
        class:"4",
        name:"English",
        chapter:"09",
        chaptername:"Pinocchho & The Naughty Boy",
        pdf:class4engch9
    },{
        class:"4",
        name:"Hindi",
        chapter:"01",
        chaptername:"मन के भोले -भाले बादल",
        pdf:class4hindich1
    },{
        class:"4",
        name:"Hindi",
        chapter:"02",
        chaptername:"जैसा सवाल वैसा जवाब",
        pdf:class4hindich2
    },{
        class:"4",
        name:"Hindi",
        chapter:"03",
        chaptername:"किरमिच की गेंद",
        pdf:class4hindich3
    },{
        class:"4",
        name:"Hindi",
        chapter:"04",
        chaptername:"पापा जब बच्चे थे",
        pdf:class4hindich4
    },{
        class:"4",
        name:"Hindi",
        chapter:"05",
        chaptername:"दोस्त की पोशाक",
        pdf:class4hindich5
    },{
        class:"4",
        name:"Hindi",
        chapter:"06",
        chaptername:"नाव बनाओ नाव बनाओ",
        pdf:class4hindich6
    },{
        class:"4",
        name:"Hindi",
        chapter:"07",
        chaptername:"दान का हिसाब",
        pdf:class4hindich7
    },{
        class:"4",
        name:"Hindi",
        chapter:"08",
        chaptername:"कौन ?",
        pdf:class4hindich8
    },{
        class:"4",
        name:"Hindi",
        chapter:"09",
        chaptername:"स्वतंत्रता की ओर",
        pdf:class4hindich9
    },{
        class:"4",
        name:"Hindi",
        chapter:"10",
        chaptername:"थप्प रोटी थप्प दाल",
        pdf:class4hindich10
    },{
        class:"4",
        name:"Hindi",
        chapter:"11",
        chaptername:"पढ़क्कू की सूझ",
        pdf:class4hindich11
    },{
        class:"4",
        name:"Hindi",
        chapter:"12",
        chaptername:"सुनीता की पहिया कुर्सी",
        pdf:class4hindich12
    },{
        class:"4",
        name:"Hindi",
        chapter:"13",
        chaptername:"हुदहुद",
        pdf:class4hindich13
    },{
        class:"4",
        name:"Hindi",
        chapter:"14",
        chaptername:"मुफ्त ही मुफ्त",
        pdf:class4hindich14
    },{
        class:"4",
        name:"Evs",
        chapter:"01",
        chaptername:"Going to School",
        pdf:class4evsch1
    },{
        class:"4",
        name:"Evs",
        chapter:"02",
        chaptername:"Ear to Ear",
        pdf:class4evsch2
    },{
        class:"4",
        name:"Evs",
        chapter:"03",
        chaptername:"A Day with Nandu",
        pdf:class4evsch3
    },{
        class:"4",
        name:"Evs",
        chapter:"04",
        chaptername:"The Story of Amrita",
        pdf:class4evsch4
    },{
        class:"4",
        name:"Evs",
        chapter:"05",
        chaptername:"Anita and the Honeybees",
        pdf:class4evsch5
    },{
        class:"4",
        name:"Evs",
        chapter:"06",
        chaptername:"Omana’s Journey",
        pdf:class4evsch6
    },{
        class:"4",
        name:"Evs",
        chapter:"07",
        chaptername:"From the Window",
        pdf:class4evsch7
    },{
        class:"4",
        name:"Evs",
        chapter:"08",
        chaptername:"Reaching Grandmother’s House",
        pdf:class4evsch8
    },{
        class:"4",
        name:"Evs",
        chapter:"09",
        chaptername:"Changing Families",
        pdf:class4evsch9
    },{
        class:"4",
        name:"Evs",
        chapter:"10",
        chaptername:"Hu Tu Tu, Hu Tu Tu",
        pdf:class4evsch10
    },{
        class:"4",
        name:"Evs",
        chapter:"11",
        chaptername:"The Valley of Flowers",
        pdf:class4evsch11
    },{
        class:"4",
        name:"Evs",
        chapter:"12",
        chaptername:"Changing Times",
        pdf:class4evsch12
    },{
        class:"4",
        name:"Evs",
        chapter:"13",
        chaptername:"A River’s Tale",
        pdf:class4evsch13
    },{
        class:"4",
        name:"Evs",
        chapter:"14",
        chaptername:"Basva’s Farm",
        pdf:class4evsch14
    },{
        class:"4",
        name:"Evs",
        chapter:"15",
        chaptername:"From Market to Home",
        pdf:class4evsch15
    },{
        class:"4",
        name:"Evs",
        chapter:"16",
        chaptername:"A Busy Month",
        pdf:class4evsch16
    },{
        class:"4",
        name:"Evs",
        chapter:"17",
        chaptername:"Nandita in Mumbai",
        pdf:class4evsch17
    },{
        class:"4",
        name:"Evs",
        chapter:"18",
        chaptername:"Too Much Water, Too Little Water",
        pdf:class4evsch18
    },{
        class:"4",
        name:"Evs",
        chapter:"19",
        chaptername:" Abdul in the Garden",
        pdf:class4evsch19
    },{
        class:"4",
        name:"Evs",
        chapter:"20",
        chaptername:"Eating Together",
        pdf:class4evsch20
    },{
        class:"4",
        name:"Evs",
        chapter:"21",
        chaptername:"Food and Fun",
        pdf:class4evsch21
    },{
        class:"4",
        name:"Evs",
        chapter:"22",
        chaptername:"The World in My Home",
        pdf:class4evsch22
    },{
        class:"4",
        name:"Evs",
        chapter:"23",
        chaptername:"Pochampalli",
        pdf:class4evsch23
    },{
        class:"4",
        name:"Evs",
        chapter:"24",
        chaptername:"Home and Abroad",
        pdf:class4evsch24
    },{
        class:"4",
        name:"Evs",
        chapter:"25",
        chaptername:"Spicy Riddles",
        pdf:class4evsch25
    },{
        class:"4",
        name:"Evs",
        chapter:"26",
        chaptername:"Defence Officer: Wahida",
        pdf:class4evsch26
    },{
        class:"4",
        name:"Evs",
        chapter:"27",
        chaptername:"Chuskit Goes to School",
        pdf:class4evsch27
    },{
        class:"5",
        name:"Evs",
        chapter:"01",
        chaptername:"Super Senses",
        pdf:class5evsch1
    },{
        class:"5",
        name:"Evs",
        chapter:"02",
        chaptername:"A Snake Charmers Story",
        pdf:class5evsch2
    },{
        class:"5",
        name:"Evs",
        chapter:"03",
        chaptername:"From Tasting to Digesting",
        pdf:class5evsch3
    },{
        class:"5",
        name:"Evs",
        chapter:"04",
        chaptername:"Mangoes Round the Year",
        pdf:class5evsch4
    },{
        class:"5",
        name:"Evs",
        chapter:"05",
        chaptername:"Seeds and Seeds",
        pdf:class5evsch5
    },{
        class:"5",
        name:"Evs",
        chapter:"06",
        chaptername:"Every Drop Counts",
        pdf:class5evsch6
    },{
        class:"5",
        name:"Evs",
        chapter:"07",
        chaptername:"Experiments with Water",
        pdf:class5evsch7
    },{
        class:"5",
        name:"Evs",
        chapter:"08",
        chaptername:"A Treat for Mosquitoes",
        pdf:class5evsch8
    },{
        class:"5",
        name:"Evs",
        chapter:"09",
        chaptername:"Up You Go",
        pdf:class5evsch9
    },{
        class:"5",
        name:"Evs",
        chapter:"10",
        chaptername:"Walls Tell Stories",
        pdf:class5evsch10
    },{
        class:"5",
        name:"Evs",
        chapter:"11",
        chaptername:"Sunita in Space",
        pdf:class5evsch11
    },{
        class:"5",
        name:"Evs",
        chapter:"12",
        chaptername:"What If It Finishes",
        pdf:class5evsch12
    },{
        class:"5",
        name:"Evs",
        chapter:"13",
        chaptername:"A Shelter So High",
        pdf:class5evsch13
    },{
        class:"5",
        name:"Evs",
        chapter:"14",
        chaptername:"When the Earth Shook",
        pdf:class5evsch14
    },{
        class:"5",
        name:"Evs",
        chapter:"15",
        chaptername:"Blow Hot Blow Cold",
        pdf:class5evsch15
    },{
        class:"5",
        name:"Evs",
        chapter:"16",
        chaptername:"Who Will Do This Work",
        pdf:class5evsch16
    },{
        class:"5",
        name:"Evs",
        chapter:"17",
        chaptername:"Across the Wall",
        pdf:class5evsch17
    },{
        class:"5",
        name:"Evs",
        chapter:"18",
        chaptername:"No Place for Us",
        pdf:class5evsch18
    },{
        class:"5",
        name:"Evs",
        chapter:"19",
        chaptername:"A Seed Tells a Farmers Story",
        pdf:class5evsch19
    },{
        class:"5",
        name:"Evs",
        chapter:"20",
        chaptername:"Whose Forests",
        pdf:class5evsch20
    },{
        class:"5",
        name:"Evs",
        chapter:"21",
        chaptername:"Like Father Like Daughter",
        pdf:class5evsch21
    },{
        class:"5",
        name:"Evs",
        chapter:"22",
        chaptername:"On the Move Again",
        pdf:class5evsch22
    },{
        class:"5",
        name:"English",
        chapter:"01",
        chaptername:"Wonderful Waste & Ice Cream Man",
        pdf:class5engch1
    },{
        class:"5",
        name:"English",
        chapter:"02",
        chaptername:"Flying Together & Teamwork",
        pdf:class5engch2
    },{
        class:"5",
        name:"English",
        chapter:"03",
        chaptername:"Robinson Crusoe & My Shadow",
        pdf:class5engch3
    },{
        class:"5",
        name:"English",
        chapter:"04",
        chaptername:"My Elder Brother & Crying",
        pdf:class5engch4
    },{
        class:"5",
        name:"English",
        chapter:"05",
        chaptername:"Rip van Winkle & The Lazy Frog",
        pdf:class5engch5
    },{
        class:"5",
        name:"English",
        chapter:"06",
        chaptername:"Talkative Barber & Class Discussion",
        pdf:class5engch6
    },{
        class:"5",
        name:"English",
        chapter:"07",
        chaptername:"Gullivers Travels & Topsy Turvey Land",
        pdf:class5engch7
    },{
        class:"5",
        name:"English",
        chapter:"08",
        chaptername:"The Little Bully & Nobodys Friend",
        pdf:class5engch8
    },{
        class:"5",
        name:"English",
        chapter:"09",
        chaptername:"Around the World & Sing a Song of People",
        pdf:class5engch9
    },{
        class:"5",
        name:"English",
        chapter:"10",
        chaptername:"Who will be Ningthou & Malu Bhalu",
        pdf:class5engch10
    },{
        class:"5",
        name:"Hindi",
        chapter:"01",
        chaptername:"रखा की रस्सी",
        pdf:class5hindich1
    },{
        class:"5",
        name:"Hindi",
        chapter:"02",
        chaptername:"फसलें का त्योहार",
        pdf:class5hindich2
    },{
        class:"5",
        name:"Hindi",
        chapter:"03",
        chaptername:"खिलौनेवाला",
        pdf:class5hindich3
    },{
        class:"5",
        name:"Hindi",
        chapter:"04",
        chaptername:"नन्हा फनकार",
        pdf:class5hindich4
    },{
        class:"5",
        name:"Hindi",
        chapter:"05",
        chaptername:"जहाँ चाह वहाँ राह",
        pdf:class5hindich5
    },{
        class:"5",
        name:"Hindi",
        chapter:"06",
        chaptername:"चिटठी का सफर",
        pdf:class5hindich6
    },{
        class:"5",
        name:"Hindi",
        chapter:"07",
        chaptername:"डाकिए की कहानी ,कंवरसिंह की जुबानी",
        pdf:class5hindich7
    },{
        class:"5",
        name:"Hindi",
        chapter:"08",
        chaptername:"वे दिन भी क्या दिन थे",
        pdf:class5hindich8
    },{
        class:"5",
        name:"Hindi",
        chapter:"09",
        chaptername:"एक माँ की बेबसी",
        pdf:class5hindich9
    },{
        class:"5",
        name:"Hindi",
        chapter:"10",
        chaptername:"एक दिन की बादशाहत",
        pdf:class5hindich10
    },{
        class:"5",
        name:"Hindi",
        chapter:"11",
        chaptername:"चावल की रोटियां",
        pdf:class5hindich11
    },{
        class:"5",
        name:"Hindi",
        chapter:"12",
        chaptername:"गुरु और चेला",
        pdf:class5hindich12
    },{
        class:"5",
        name:"Hindi",
        chapter:"13",
        chaptername:" स्वामी की दादी",
        pdf:class5hindich13
    },{
        class:"5",
        name:"Hindi",
        chapter:"14",
        chaptername:"बाघ आया उस रात",
        pdf:class5hindich14
    },{
        class:"5",
        name:"Hindi",
        chapter:"15",
        chaptername:"बिशन की दिलेरी",
        pdf:class5hindich15
    },{
        class:"5",
        name:"Hindi",
        chapter:"16",
        chaptername:"पानी रे पानी",
        pdf:class5hindich16
    },{
        class:"5",
        name:"Hindi",
        chapter:"17",
        chaptername:"छोटी-सी हमारी नदी",
        pdf:class5hindich17
    },{
        class:"5",
        name:"Hindi",
        chapter:"18",
        chaptername:"चुनौती हिमालय की",
        pdf:class5hindich18
    },{
        class:"5",
        name:"Maths",
        chapter:"01",
        chaptername:"The Fish Tale",
        pdf:class5mathsch1
    },{
        class:"5",
        name:"Maths",
        chapter:"02",
        chaptername:"Shapes and Angles",
        pdf:class5mathsch2
    },{
        class:"5",
        name:"Maths",
        chapter:"03",
        chaptername:"How Many Squares?",
        pdf:class5mathsch3
    },{
        class:"5",
        name:"Maths",
        chapter:"04",
        chaptername:"Parts and Wholes",
        pdf:class5mathsch4
    },{
        class:"5",
        name:"Maths",
        chapter:"05",
        chaptername:"Does It Look the Same?",
        pdf:class5mathsch5
    },{
        class:"5",
        name:"Maths",
        chapter:"06",
        chaptername:"Be My Multiple, I’ll Be Your Factor",
        pdf:class5mathsch6
    },{
        class:"5",
        name:"Maths",
        chapter:"07",
        chaptername:"Can You See the Pattern?",
        pdf:class5mathsch7
    },{
        class:"5",
        name:"Maths",
        chapter:"08",
        chaptername:"Mapping Your Way",
        pdf:class5mathsch8
    },{
        class:"5",
        name:"Maths",
        chapter:"09",
        chaptername:"Boxes and Sketches",
        pdf:class5mathsch9
    },{
        class:"5",
        name:"Maths",
        chapter:"10",
        chaptername:"Tenths and Hundredths",
        pdf:class5mathsch10
    },{
        class:"5",
        name:"Maths",
        chapter:"11",
        chaptername:"Areas and Its Boundary",
        pdf:class5mathsch11
    },{
        class:"5",
        name:"Maths",
        chapter:"12",
        chaptername:"Smart Charts",
        pdf:class5mathsch12
    },{
        class:"5",
        name:"Maths",
        chapter:"13",
        chaptername:"Ways to Multiply and Divide",
        pdf:class5mathsch13
    },{
        class:"5",
        name:"Maths",
        chapter:"14",
        chaptername:"How Big? How Heavy?",
        pdf:class5mathsch14
    },{
        class:"6",
        name:"English",
        chapter:"01",
        chaptername:"FABLES AND FOLK TALES",
        pdf:class6engch1
    },{
        class:"6",
        name:"English",
        chapter:"02",
        chaptername:"FRIENDSHIP",
        pdf:class6engch2
    },{
        class:"6",
        name:"English",
        chapter:"03",
        chaptername:"NURTURING NATURE",
        pdf:class6engch3
    },{
        class:"6",
        name:"English",
        chapter:"04",
        chaptername:"SPORTS AND WELLNESS",
        pdf:class6engch4
    },{
        class:"6",
        name:"English",
        chapter:"05",
        chaptername:"CULTURE AND TRADITION",
        pdf:class6engch5
    },{
        class:"6",
        name:"Sst",
        chapter:"01",
        chaptername:"Why Social Science?",
        pdf:class6sstch1
    },{
        class:"6",
        name:"Sst",
        chapter:"02",
        chaptername:"Oceans and Continents",
        pdf:class6sstch2
    },{
        class:"6",
        name:"Sst",
        chapter:"03",
        chaptername:"Landforms and Life",
        pdf:class6sstch3
    },{
        class:"6",
        name:"Sst",
        chapter:"04",
        chaptername:"Timeline and Sources of History",
        pdf:class6sstch4
    },{
        class:"6",
        name:"Sst",
        chapter:"05",
        chaptername:"India, That Is Bharat",
        pdf:class6sstch5
    },{
        class:"6",
        name:"Sst",
        chapter:"06",
        chaptername:"The Beginnings of Indian Civilisation",
        pdf:class6sstch6
    },{
        class:"6",
        name:"Sst",
        chapter:"07",
        chaptername:"India’s Cultural Roots",
        pdf:class6sstch7
    },{
        class:"6",
        name:"Sst",
        chapter:"08",
        chaptername:"Unity in Diversity, or ‘Many in the One’",
        pdf:class6sstch8
    },{
        class:"6",
        name:"Sst",
        chapter:"09",
        chaptername:"Family and Community",
        pdf:class6sstch9
    },{
        class:"6",
        name:"Sst",
        chapter:"10",
        chaptername:"Governance",
        pdf:class6sstch10
    },{
        class:"6",
        name:"Sst",
        chapter:"11",
        chaptername:"Local Government in Rural Areas",
        pdf:class6sstch11
    },{
        class:"6",
        name:"Sst",
        chapter:"12",
        chaptername:"Local Government in Urban Areas",
        pdf:class6sstch12
    },{
        class:"6",
        name:"Sst",
        chapter:"13",
        chaptername:"The Value of Work",
        pdf:class6sstch13
    },{
        class:"6",
        name:"Sst",
        chapter:"14",
        chaptername:"Economic Activities Around Us",
        pdf:class6sstch14
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"01",
        chaptername:"वयं वर्णमालां पठाम:",
        pdf:class6sanskritch1
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"02",
        chaptername:"एष: क: ? एषा का ? एतत् किम् ?",
        pdf:class6sanskritch2
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"03",
        chaptername:"अहं च त्वं च",
        pdf:class6sanskritch3
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"04",
        chaptername:"अहं प्रात: उत्तिष् ठाम",
        pdf:class6sanskritch4
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"05",
        chaptername:"शूरा: वयं धीरा: वयम",
        pdf:class6sanskritch5
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"06",
        chaptername:"स: एव महान् चित्रकार:",
        pdf:class6sanskritch6
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"07",
        chaptername:"अतिथिदेवो भव",
        pdf:class6sanskritch7
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"08",
        chaptername:"बुद्धि: सर्वार्थसाधिका",
        pdf:class6sanskritch8
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"09",
        chaptername:"यो जानाति स: पण्डित:",
        pdf:class6sanskritch9
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"10",
        chaptername:"त्वम् आपणं गच",
        pdf:class6sanskritch10
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"11",
        chaptername:"पृथिव्यां त्रीणि रत् नानि",
        pdf:class6sanskritch11
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"12",
        chaptername:"आलस्यं हि मनुष्याणां शरीरस्थो महान् रिपु:",
        pdf:class6sanskritch12
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"13",
        chaptername:"सङ्ख्यागणना ननु सरला",
        pdf:class6sanskritch13
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"14",
        chaptername:"माधवस्य प्रियम् अङ् गम् ",
        pdf:class6sanskritch14
    },{
        class:"6",
        name:"Sanskrit",
        chapter:"15",
        chaptername:"वृक्षा: सत्पुरुषा: इव",
        pdf:class6sanskritch15
    },{
        class:"6",
        name:"Science",
        chapter:"01",
        chaptername:"The Wonderful World of Science",
        pdf:class6sciencech1
    },{
        class:"6",
        name:"Science",
        chapter:"02",
        chaptername:"Diversity in the Living World",
        pdf:class6sciencech2
    },{
        class:"6",
        name:"Science",
        chapter:"03",
        chaptername:"Mindful Eating: A Path to a Healthy Body",
        pdf:class6sciencech3
    },{
        class:"6",
        name:"Science",
        chapter:"04",
        chaptername:"Exploring Magnets",
        pdf:class6sciencech4
    },{
        class:"6",
        name:"Science",
        chapter:"05",
        chaptername:"Measurement of Length and Motion",
        pdf:class6sciencech5
    },{
        class:"6",
        name:"Science",
        chapter:"06",
        chaptername:"Materials Around Us",
        pdf:class6sciencech6
    },{
        class:"6",
        name:"Science",
        chapter:"07",
        chaptername:"Temperature and its Measurement",
        pdf:class6sciencech7
    },{
        class:"6",
        name:"Science",
        chapter:"08",
        chaptername:"A Journey through States of Water",
        pdf:class6sciencech8
    },{
        class:"6",
        name:"Science",
        chapter:"09",
        chaptername:"ethods of Separation in Everyday Life",
        pdf:class6sciencech9
    },{
        class:"6",
        name:"Science",
        chapter:"10",
        chaptername:"Living Creatures: Exploring their Characteristics",
        pdf:class6sciencech10
    },{
        class:"6",
        name:"Science",
        chapter:"11",
        chaptername:"Nature’s Treasures",
        pdf:class6sciencech11
    },{
        class:"6",
        name:"Science",
        chapter:"12",
        chaptername:"Beyond Earth",
        pdf:class6sciencech12
    },{
        class:"7",
        name:"Science",
        chapter:"01",
        chaptername:"Nutrition in Plants",
        pdf:class7sciencech1
    },{
        class:"7",
        name:"Science",
        chapter:"02",
        chaptername:"Nutrition in Animals",
        pdf:class7sciencech2
    },{
        class:"7",
        name:"Science",
        chapter:"03",
        chaptername:"Heat",
        pdf:class7sciencech3
    },{
        class:"7",
        name:"Science",
        chapter:"04",
        chaptername:"Acids, Bases and Salts",
        pdf:class7sciencech4
    },{
        class:"7",
        name:"Science",
        chapter:"05",
        chaptername:"Physical and Chemical Changes",
        pdf:class7sciencech5
    },{
        class:"7",
        name:"Science",
        chapter:"06",
        chaptername:"Respiration in Organisms",
        pdf:class7sciencech6
    },{
        class:"7",
        name:"Science",
        chapter:"07",
        chaptername:"Transportation in Animals and Plants",
        pdf:class7sciencech7
    },{
        class:"7",
        name:"Science",
        chapter:"08",
        chaptername:"Reproduction in Plants",
        pdf:class7sciencech8
    },{
        class:"7",
        name:"Science",
        chapter:"09",
        chaptername:"Motion and Time",
        pdf:class7sciencech9
    },{
        class:"7",
        name:"Science",
        chapter:"10",
        chaptername:"Electric Current and Its Effects",
        pdf:class7sciencech10
    },{
        class:"7",
        name:"Science",
        chapter:"11",
        chaptername:"Light",
        pdf:class7sciencech11
    },{
        class:"7",
        name:"Science",
        chapter:"12",
        chaptername:"Forests: Our Lifeline",
        pdf:class7sciencech12
    },{
        class:"7",
        name:"Science",
        chapter:"13",
        chaptername:"Wastewater Story",
        pdf:class7sciencech13
    },{
        class:"7",
        name:"Honeycomb",
        chapter:"01",
        chaptername:"Three Questions and The Squirrel",
        pdf:class7enghoneych1
    },{
        class:"7",
        name:"Honeycomb",
        chapter:"02",
        chaptername:" A Gift of Chappals and The Rebel",
        pdf:class7enghoneych2
    },{
        class:"7",
        name:"Honeycomb",
        chapter:"03",
        chaptername:"Gopal and the Hilsa Fish and The Shed",
        pdf:class7enghoneych3
    },{
        class:"7",
        name:"Honeycomb",
        chapter:"04",
        chaptername:"The Ashes That Made Trees Bloom and Chivvy",
        pdf:class7enghoneych4
    },{
        class:"7",
        name:"Honeycomb",
        chapter:"05",
        chaptername:"Quality and Trees",
        pdf:class7enghoneych5
    },{
        class:"7",
        name:"Honeycomb",
        chapter:"06",
        chaptername:"Expert Detectives and Mystery of the Talking Fan",
        pdf:class7enghoneych6
    },{
        class:"7",
        name:"Honeycomb",
        chapter:"07",
        chaptername:"The Invention of Vita-Wonk and Dad and the Cat and the Tree and Garden Snake",
        pdf:class7enghoneych7
    },{
        class:"7",
        name:"Honeycomb",
        chapter:"08",
        chaptername:"A Homage to Our Brave Soldiers and Meadow Surprises",
        pdf:class7enghoneych8
    },{
        class:"7",
        name:"An Alien Hand",
        chapter:"01",
        chaptername:"The Tiny Teacher",
        pdf:class7engaliench1
    },{
        class:"7",
        name:"An Alien Hand",
        chapter:"02",
        chaptername:"Bringing Up Kari",
        pdf:class7engaliench2
    },{
        class:"7",
        name:"An Alien Hand",
        chapter:"03",
        chaptername:"Golu Grows A Nose",
        pdf:class7engaliench3
    },{
        class:"7",
        name:"An Alien Hand",
        chapter:"04",
        chaptername:"Chandni",
        pdf:class7engaliench4
    },{
        class:"7",
        name:"An Alien Hand",
        chapter:"05",
        chaptername:"The Bear Story",
        pdf:class7engaliench5
    },{
        class:"7",
        name:"An Alien Hand",
        chapter:"06",
        chaptername:"A Tiger In The House",
        pdf:class7engaliench6
    },{
        class:"7",
        name:"An Alien Hand",
        chapter:"07",
        chaptername:"An Alien Hand",
        pdf:class7engaliench7
    },{
        class:"7",
        name:"Maths",
        chapter:"01",
        chaptername:"Integers",
        pdf:class7mathsch1
    },{
        class:"7",
        name:"Maths",
        chapter:"02",
        chaptername:"Fractions and Decimals",
        pdf:class7mathsch2
    },{
        class:"7",
        name:"Maths",
        chapter:"03",
        chaptername:"Data Handling",
        pdf:class7mathsch3
    },{
        class:"7",
        name:"Maths",
        chapter:"04",
        chaptername:"Simple Equations",
        pdf:class7mathsch4
    },{
        class:"7",
        name:"Maths",
        chapter:"05",
        chaptername:"Lines and Angles",
        pdf:class7mathsch5
    },{
        class:"7",
        name:"Maths",
        chapter:"06",
        chaptername:"The Triangle and Its Properties",
        pdf:class7mathsch6
    },{
        class:"7",
        name:"Maths",
        chapter:"07",
        chaptername:"Comparing Quantities",
        pdf:class7mathsch7
    },{
        class:"7",
        name:"Maths",
        chapter:"07",
        chaptername:"Rational Numbers",
        pdf:class7mathsch8
    },{
        class:"7",
        name:"Maths",
        chapter:"09",
        chaptername:"Perimeter and Area",
        pdf:class7mathsch9
    },{
        class:"7",
        name:"Maths",
        chapter:"10",
        chaptername:"Algebraic Expressions",
        pdf:class7mathsch10
    },{
        class:"7",
        name:"Maths",
        chapter:"11",
        chaptername:"Exponents and Powers",
        pdf:class7mathsch11
    },{
        class:"7",
        name:"Maths",
        chapter:"12",
        chaptername:"Symmetry",
        pdf:class7mathsch12
    },{
        class:"7",
        name:"Maths",
        chapter:"13",
        chaptername:"Visualising Solid Shapes",
        pdf:class7mathsch13
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"01",
        chaptername:"सुभाषितानि",
        pdf:class7sanskritch1
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"02",
        chaptername:"दुर्बुद्धिः विनश्यति",
        pdf:class7sanskritch2
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"03",
        chaptername:"स्वावलम्बनम्",
        pdf:class7sanskritch3
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"04",
        chaptername:"पण्डिता रमाबाई",
        pdf:class7sanskritch4
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"05",
        chaptername:"सदाचारः",
        pdf:class7sanskritch5
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"06",
        chaptername:"सङ्कल्पः सिद्धिदायकः",
        pdf:class7sanskritch6
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"07",
        chaptername:"त्रिवर्णः ध्वजः",
        pdf:class7sanskritch7
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"08",
        chaptername:"अहमपि विद्यालयं गमिष्यामि",
        pdf:class7sanskritch8
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"09",
        chaptername:"विश्वबन्धुत्वम्",
        pdf:class7sanskritch9
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"10",
        chaptername:"समवायो हि दुर्जयः",
        pdf:class7sanskritch10
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"11",
        chaptername:"विद्याधनम्",
        pdf:class7sanskritch11
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"12",
        chaptername:"अमृतं संस्कृतम्",
        pdf:class7sanskritch12
    },{
        class:"7",
        name:"Sanskrit",
        chapter:"13",
        chaptername:"लालनगीतम्",
        pdf:class7sanskritch13
    },{
        class:"7",
        name:"Vasant",
        chapter:"01",
        chaptername:"हम पंछी उन्मुक्त गगन के",
        pdf:class7vasantch1
    },{
        class:"7",
        name:"Vasant",
        chapter:"02",
        chaptername:"हिमालय की बेटियां",
        pdf:class7vasantch2
    },{
        class:"7",
        name:"Vasant",
        chapter:"03",
        chaptername:"कठपुतली",
        pdf:class7vasantch3
    },{
        class:"7",
        name:"Vasant",
        chapter:"04",
        chaptername:"मीठाईवाला",
        pdf:class7vasantch4
    },{
        class:"7",
        name:"Vasant",
        chapter:"05",
        chaptername:"पापा खो गए",
        pdf:class7vasantch5
    },{
        class:"7",
        name:"Vasant",
        chapter:"06",
        chaptername:"शाम एक किशान",
        pdf:class7vasantch6
    },{
        class:"7",
        name:"Vasant",
        chapter:"07",
        chaptername:"अपूर्व अनुभव",
        pdf:class7vasantch7
    },{
        class:"7",
        name:"Vasant",
        chapter:"08",
        chaptername:"रहीम की दोहे",
        pdf:class7vasantch8
    },{
        class:"7",
        name:"Vasant",
        chapter:"09",
        chaptername:"एक तिनका",
        pdf:class7vasantch9
    },{
        class:"7",
        name:"Vasant",
        chapter:"10",
        chaptername:"खानपान की बदलती तस्वीर",
        pdf:class7vasantch10
    },{
        class:"7",
        name:"Vasant",
        chapter:"11",
        chaptername:"नीलकंठ",
        pdf:class7vasantch11
    },{
        class:"7",
        name:"Vasant",
        chapter:"12",
        chaptername:"भोर और बरखा",
        pdf:class7vasantch12
    },{
        class:"7",
        name:"Vasant",
        chapter:"13",
        chaptername:"वीर कुवर सिंह",
        pdf:class7vasantch13
    },{
        class:"7",
        name:"Vasant",
        chapter:"14",
        chaptername:"संघर्ष के कराण मैं तुनुकमिजाज हो गया धनराज",
        pdf:class7vasantch14
    },{
        class:"7",
        name:"Vasant",
        chapter:"15",
        chaptername:"आश्रम का अनुमानित व्यय",
        pdf:class7vasantch15
    },{
        class:"7",
        name:"Mahabharat",
        chapter:"01",
        chaptername:"बाल महाभारत",
        pdf:class7mahabharatch1
    },{
        class:"7",
        name:"History",
        chapter:"01",
        chaptername:"Tracing Changes Through A Thousand Years",
        pdf:class7historych1
    },{
        class:"7",
        name:"History",
        chapter:"02",
        chaptername:"New Kings And Kingdoms",
        pdf:class7historych2
    },{
        class:"7",
        name:"History",
        chapter:"03",
        chaptername:"The Delhi Sultans",
        pdf:class7historych3
    },{
        class:"7",
        name:"History",
        chapter:"04",
        chaptername:"The Mughal Empire",
        pdf:class7historych4
    },{
        class:"7",
        name:"History",
        chapter:"05",
        chaptername:"Rulers And Buildings",
        pdf:class7historych5
    },{
        class:"7",
        name:"History",
        chapter:"06",
        chaptername:"Devotional Paths To The Divine",
        pdf:class7historych6
    },{
        class:"7",
        name:"History",
        chapter:"07",
        chaptername:"The Making Of Regional Cultures",
        pdf:class7historych7
    },{
        class:"7",
        name:"History",
        chapter:"08",
        chaptername:"Eighteenth-Century Political Formations",
        pdf:class7historych8
    },{
        class:"7",
        name:"Civics",
        chapter:"01",
        chaptername:"On Equality",
        pdf:class7civicsch1
    },{
        class:"7",
        name:"Civics",
        chapter:"02",
        chaptername:"Role of the Government in Health",
        pdf:class7civicsch2
    },{
        class:"7",
        name:"Civics",
        chapter:"03",
        chaptername:"How the State Government Works",
        pdf:class7civicsch3
    },{
        class:"7",
        name:"Civics",
        chapter:"04",
        chaptername:"Growing up as Boys and Girls",
        pdf:class7civicsch4
    },{
        class:"7",
        name:"Civics",
        chapter:"05",
        chaptername:"Women Change the World",
        pdf:class7civicsch5
    },{
        class:"7",
        name:"Civics",
        chapter:"06",
        chaptername:"Understanding Media",
        pdf:class7civicsch6
    },{
        class:"7",
        name:"Civics",
        chapter:"07",
        chaptername:"Markets Around Us",
        pdf:class7civicsch7
    },{
        class:"7",
        name:"Civics",
        chapter:"08",
        chaptername:"A Shirt in the Market",
        pdf:class7civicsch8
    },{
        class:"7",
        name:"Geo",
        chapter:"01",
        chaptername:"Environment",
        pdf:class7geoch1
    },{
        class:"7",
        name:"Geo",
        chapter:"02",
        chaptername:"Inside Our Earth",
        pdf:class7geoch2
    },{
        class:"7",
        name:"Geo",
        chapter:"03",
        chaptername:"Our Changing Earth",
        pdf:class7geoch3
    },{
        class:"7",
        name:"Geo",
        chapter:"04",
        chaptername:"Air",
        pdf:class7geoch4
    },{
        class:"7",
        name:"Geo",
        chapter:"05",
        chaptername:"Water",
        pdf:class7geoch5
    },{
        class:"7",
        name:"Geo",
        chapter:"06",
        chaptername:"Human Environment Interactions – The Tropical and the Subtropical Region",
        pdf:class7geoch6
    },{
        class:"7",
        name:"Geo",
        chapter:"07",
        chaptername:"Life in the Deserts",
        pdf:class7geoch7
    },{
        class:"8",
        name:"Geo",
        chapter:"01",
        chaptername:"Resources",
        pdf:class8geoch1
    },{
        class:"8",
        name:"Geo",
        chapter:"02",
        chaptername:"Land, Soil, Water, Natural Vegetation and Wildlife Resources",
        pdf:class8geoch2
    },{
        class:"8",
        name:"Geo",
        chapter:"03",
        chaptername:"Agriculture",
        pdf:class8geoch3
    },{
        class:"8",
        name:"Geo",
        chapter:"04",
        chaptername:"Industries",
        pdf:class8geoch4
    },{
        class:"8",
        name:"Geo",
        chapter:"05",
        chaptername:"Human Resources",
        pdf:class8geoch5
    },{
        class:"8",
        name:"Civics",
        chapter:"01",
        chaptername:"The Indian Constitution",
        pdf:class8civicsch1
    },{
        class:"8",
        name:"Civics",
        chapter:"02",
        chaptername:"Understanding Secularism",
        pdf:class8civicsch2
    },{
        class:"8",
        name:"Civics",
        chapter:"03",
        chaptername:"Why Do We Need A Parliament",
        pdf:class8civicsch3
    },{
        class:"8",
        name:"Civics",
        chapter:"04",
        chaptername:"Judiciary",
        pdf:class8civicsch4
    },{
        class:"8",
        name:"Civics",
        chapter:"05",
        chaptername:"Understanding Marginalisation",
        pdf:class8civicsch5
    },{
        class:"8",
        name:"Civics",
        chapter:"06",
        chaptername:"Confronting Marginalisation",
        pdf:class8civicsch6
    },{
        class:"8",
        name:"Civics",
        chapter:"07",
        chaptername:"Public Facilities",
        pdf:class8civicsch7
    },{
        class:"8",
        name:"Civics",
        chapter:"08",
        chaptername:"Law and Social Justice",
        pdf:class8civicsch8
    },{
        class:"8",
        name:"History",
        chapter:"01",
        chaptername:"How, When and Where",
        pdf:class8historych1
    },{
        class:"8",
        name:"History",
        chapter:"02",
        chaptername:"From Trade to Territory The Company Establishes Power",
        pdf:class8historych2
    },{
        class:"8",
        name:"History",
        chapter:"03",
        chaptername:"Ruling the Countryside",
        pdf:class8historych3
    },{
        class:"8",
        name:"History",
        chapter:"04",
        chaptername:"Tribals, Dikus and the Vision of a Golden Age",
        pdf:class8historych4
    },{
        class:"8",
        name:"History",
        chapter:"05",
        chaptername:"When People Rebel 1857 and after",
        pdf:class8historych5
    },{
        class:"8",
        name:"History",
        chapter:"06",
        chaptername:"Civilising the “Native”, Educating the Nation",
        pdf:class8historych6
    },{
        class:"8",
        name:"History",
        chapter:"07",
        chaptername:"Women, Caste and Reform",
        pdf:class8historych7
    },{
        class:"8",
        name:"History",
        chapter:"08",
        chaptername:"The Making of the National Movement: 1870s–1947",
        pdf:class8historych8
    },{
        class:"8",
        name:"Bharat ki Khoj",
        chapter:"01",
        chaptername:"अहमद नगर का किला",
        pdf:class8bharatkikhojch1
    },{
        class:"8",
        name:"Bharat ki Khoj",
        chapter:"02",
        chaptername:"तलाश",
        pdf:class8bharatkikhojch2
    },{
        class:"8",
        name:"Bharat ki Khoj",
        chapter:"03",
        chaptername:"सिन्धु घाटी सभ्यता",
        pdf:class8bharatkikhojch3
    },{
        class:"8",
        name:"Bharat ki Khoj",
        chapter:"04",
        chaptername:"युगों का दौर",
        pdf:class8bharatkikhojch4
    },{
        class:"8",
        name:"Bharat ki Khoj",
        chapter:"05",
        chaptername:"नयी समस्याएँ",
        pdf:class8bharatkikhojch5
    },{
        class:"8",
        name:"Bharat ki Khoj",
        chapter:"06",
        chaptername:"अंतिम दौर - एक",
        pdf:class8bharatkikhojch6
    },{
        class:"8",
        name:"Bharat ki Khoj",
        chapter:"07",
        chaptername:"अंतिम दौर - दो",
        pdf:class8bharatkikhojch7
    },{
        class:"8",
        name:"Bharat ki Khoj",
        chapter:"08",
        chaptername:"तनाव",
        pdf:class8bharatkikhojch8
    },{
        class:"8",
        name:"Bharat ki Khoj",
        chapter:"09",
        chaptername:"दो पृष्ठभूमियाँ - भारतीय और अंग्रेजी",
        pdf:class8bharatkikhojch9
    },{
        class:"8",
        name:"Vasant",
        chapter:"01",
        chaptername:"लाख की चूड़ियाँ",
        pdf:class8vasantch1
    },{
        class:"8",
        name:"Vasant",
        chapter:"02",
        chaptername:"बस की यात्रा",
        pdf:class8vasantch2
    },{
        class:"8",
        name:"Vasant",
        chapter:"03",
        chaptername:"दीवानों की हस्ती",
        pdf:class8vasantch3
    },{
        class:"8",
        name:"Vasant",
        chapter:"04",
        chaptername:"भगवान के डाकिये",
        pdf:class8vasantch4
    },{
        class:"8",
        name:"Vasant",
        chapter:"05",
        chaptername:"क्या निराश हुआ जाए",
        pdf:class8vasantch5
    },{
        class:"8",
        name:"Vasant",
        chapter:"06",
        chaptername:"यह सबसे कठिन समय नहीं",
        pdf:class8vasantch6
    },{
        class:"8",
        name:"Vasant",
        chapter:"07",
        chaptername:"कबीर की साखियाँ",
        pdf:class8vasantch7
    },{
        class:"8",
        name:"Vasant",
        chapter:"08",
        chaptername:"सुदामा चरित",
        pdf:class8vasantch8
    },{
        class:"8",
        name:"Vasant",
        chapter:"09",
        chaptername:"जहाँ पहिया हैं",
        pdf:class8vasantch9
    },{
        class:"8",
        name:"Vasant",
        chapter:"10",
        chaptername:"अकबरी लोटा",
        pdf:class8vasantch10
    },{
        class:"8",
        name:"Vasant",
        chapter:"11",
        chaptername:"सूरदास के पद",
        pdf:class8vasantch11
    },{
        class:"8",
        name:"Vasant",
        chapter:"12",
        chaptername:"पानी की कहानी",
        pdf:class8vasantch12
    },{
        class:"8",
        name:"Vasant",
        chapter:"13",
        chaptername:"बाज और साँप",
        pdf:class8vasantch13
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"01",
        chaptername:"सुभाषितानि",
        pdf:class8sanskritch1
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"02",
        chaptername:"बिलस्य वाणी न कदापि मे श्रुता",
        pdf:class8sanskritch2
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"03",
        chaptername:"डिजीभारतम",
        pdf:class8sanskritch3
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"04",
        chaptername:"सदैव पुरतो निधेहि चरणम",
        pdf:class8sanskritch4
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"5",
        chaptername:"कण्टकेनैव कण्टकम",
        pdf:class8sanskritch5
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"06",
        chaptername:"गृहं शून्यं सुतां विना",
        pdf:class8sanskritch6
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"07",
        chaptername:"भारतजनताहम",
        pdf:class8sanskritch7
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"08",
        chaptername:"संसारसागरस्य नायकाः",
        pdf:class8sanskritch8
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"09",
        chaptername:"सप्तभगिन्यः",
        pdf:class8sanskritch9
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"10",
        chaptername:"नीतिनवनीतं",
        pdf:class8sanskritch10
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"11",
        chaptername:"सावित्री बाई फुले",
        pdf:class8sanskritch11
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"12",
        chaptername:"कः रक्षति कः रक्षितः",
        pdf:class8sanskritch12
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"13",
        chaptername:"क्षितौ राजते भारतस्वर्णभूमिः",
        pdf:class8sanskritch13
    },{
        class:"8",
        name:"Sanskrit",
        chapter:"14",
        chaptername:"आर्यभटः",
        pdf:class8sanskritch14
    },{
        class:"8",
        name:"Maths",
        chapter:"01",
        chaptername:"Rational Numbers",
        pdf:class8mathsch1
    },{
        class:"8",
        name:"Maths",
        chapter:"02",
        chaptername:"Linear Equations in One Variable",
        pdf:class8mathsch2
    },{
        class:"8",
        name:"Maths",
        chapter:"03",
        chaptername:"Understanding Quadrilaterals",
        pdf:class8mathsch3
    },{
        class:"8",
        name:"Maths",
        chapter:"04",
        chaptername:"Data Handling",
        pdf:class8mathsch4
    },{
        class:"8",
        name:"Maths",
        chapter:"05",
        chaptername:"Squares and Square Roots",
        pdf:class8mathsch5
    },{
        class:"8",
        name:"Maths",
        chapter:"06",
        chaptername:"Cubes and Cube Roots",
        pdf:class8mathsch6
    },{
        class:"8",
        name:"Maths",
        chapter:"07",
        chaptername:"Comparing Quantities",
        pdf:class8mathsch7
    },{
        class:"8",
        name:"Maths",
        chapter:"08",
        chaptername:"Algebraic Expressions and Identities",
        pdf:class8mathsch8
    },{
        class:"8",
        name:"Maths",
        chapter:"09",
        chaptername:"Mensuration",
        pdf:class8mathsch9
    },{
        class:"8",
        name:"Maths",
        chapter:"10",
        chaptername:"Exponents and Powers",
        pdf:class8mathsch10
    },{
        class:"8",
        name:"Maths",
        chapter:"11",
        chaptername:"Direct and Indirect proportions",
        pdf:class8mathsch11
    },{
        class:"8",
        name:"Maths",
        chapter:"12",
        chaptername:"Factorisation ",
        pdf:class8mathsch12
    },{
        class:"8",
        name:"Maths",
        chapter:"13",
        chaptername:"Introduction to Graphs",
        pdf:class8mathsch13
    },{
        class:"8",
        name:"Science",
        chapter:"01",
        chaptername:"Crop Production and Management",
        pdf:class8sciencech1
    },{
        class:"8",
        name:"Science",
        chapter:"02",
        chaptername:"Microorganisms: Friend and Foe",
        pdf:class8sciencech2
    },{
        class:"8",
        name:"Science",
        chapter:"03",
        chaptername:"Coal and Petroleum",
        pdf:class8sciencech3
    },{
        class:"8",
        name:"Science",
        chapter:"04",
        chaptername:"Combustion and Flame",
        pdf:class8sciencech4
    },{
        class:"8",
        name:"Science",
        chapter:"05",
        chaptername:"Conservation of Plants and Animals",
        pdf:class8sciencech5
    },{
        class:"8",
        name:"Science",
        chapter:"06",
        chaptername:"Reproduction in Animals",
        pdf:class8sciencech6
    },{
        class:"8",
        name:"Science",
        chapter:"07",
        chaptername:"Reaching The Age of Adolescence",
        pdf:class8sciencech7
    },{
        class:"8",
        name:"Science",
        chapter:"08",
        chaptername:"Force and Pressure",
        pdf:class8sciencech8
    },{
        class:"8",
        name:"Science",
        chapter:"09",
        chaptername:"Friction",
        pdf:class8sciencech9
    },{
        class:"8",
        name:"Science",
        chapter:"10",
        chaptername:"Sound",
        pdf:class8sciencech10
    },{
        class:"8",
        name:"Science",
        chapter:"11",
        chaptername:"Chemical Effects of Electric Current",
        pdf:class8sciencech11
    },{
        class:"8",
        name:"Science",
        chapter:"12",
        chaptername:"Some Natural Phenomena",
        pdf:class8sciencech12
    },{
        class:"8",
        name:"Science",
        chapter:"13",
        chaptername:"Light",
        pdf:class8sciencech13
    },{
        class:"8",
        name:"Honeydew",
        chapter:"01",
        chaptername:"The Best Christmas Present in the World and The Ant and the Cricket",
        pdf:class8honeych1
    },{
        class:"8",
        name:"Honeydew",
        chapter:"02",
        chaptername:"The Tsunami and Geography Lesson",
        pdf:class8honeych2
    },{
        class:"8",
        name:"Honeydew",
        chapter:"03",
        chaptername:"Glimpses of the Past",
        pdf:class8honeych3
    },{
        class:"8",
        name:"Honeydew",
        chapter:"04",
        chaptername:"Bepin Choudhury’s Lapse of Memory and The Last Bargain",
        pdf:class8honeych4
    },{
        class:"8",
        name:"Honeydew",
        chapter:"05",
        chaptername:"The Summit Within and The School Boy",
        pdf:class8honeych5
    },{
        class:"8",
        name:"Honeydew",
        chapter:"06",
        chaptername:"This is Jody’s Fawn",
        pdf:class8honeych6
    },{
        class:"8",
        name:"Honeydew",
        chapter:"07",
        chaptername:"A Visit to Cambridge",
        pdf:class8honeych7
    },{
        class:"8",
        name:"Honeydew",
        chapter:"08",
        chaptername:"A Short Monsoon Diary and On the Grasshopper and Cricket",
        pdf:class8honeych8
    },{
        class:"8",
        name:"It So Happened",
        chapter:"01",
        chaptername:"How The Camel Got His Hump",
        pdf:class8sohch1
    },{
        class:"8",
        name:"It So Happened",
        chapter:"02",
        chaptername:"Children at Work",
        pdf:class8sohch2
    },{
        class:"8",
        name:"It So Happened",
        chapter:"03",
        chaptername:"The Selfish Giant",
        pdf:class8sohch3
    },{
        class:"8",
        name:"It So Happened",
        chapter:"04",
        chaptername:"The Treasure Within",
        pdf:class8sohch4
    },{
        class:"8",
        name:"It So Happened",
        chapter:"05",
        chaptername:"Princess September",
        pdf:class8sohch5
    },{
        class:"8",
        name:"It So Happened",
        chapter:"06",
        chaptername:"The Fight",
        pdf:class8sohch6
    },{
        class:"8",
        name:"It So Happened",
        chapter:"07",
        chaptername:"Jalebis",
        pdf:class8sohch7
    },{
        class:"8",
        name:"It So Happened",
        chapter:"08",
        chaptername:"Ancient Education System of India",
        pdf:class8sohch8
    },{
        class:"9",
        name:"Maths",
        chapter:"01",
        chaptername:"Number System",
        pdf:class9mathsch1
    },{
        class:"9",
        name:"Maths",
        chapter:"02",
        chaptername:"Polynomials",
        pdf:class9mathsch2
    },{
        class:"9",
        name:"Maths",
        chapter:"03",
        chaptername:"Coordinate Geometry",
        pdf:class9mathsch3
    },{
        class:"9",
        name:"Maths",
        chapter:"04",
        chaptername:"Linear Equations in Two Variables",
        pdf:class9mathsch4
    },{
        class:"9",
        name:"Maths",
        chapter:"05",
        chaptername:"Introduction to Euclids Geometry",
        pdf:class9mathsch5
    },{
        class:"9",
        name:"Maths",
        chapter:"06",
        chaptername:"Lines and Angles",
        pdf:class9mathsch6
    },{
        class:"9",
        name:"Maths",
        chapter:"07",
        chaptername:"Triangles",
        pdf:class9mathsch7
    },{
        class:"9",
        name:"Maths",
        chapter:"08",
        chaptername:"Quadrilaterals",
        pdf:class9mathsch8
    },{
        class:"9",
        name:"Maths",
        chapter:"09",
        chaptername:"Circles",
        pdf:class9mathsch9
    },{
        class:"9",
        name:"Maths",
        chapter:"10",
        chaptername:"Heron’s Formula",
        pdf:class9mathsch10
    },{
        class:"9",
        name:"Maths",
        chapter:"11",
        chaptername:"Surface Areas and Volumes",
        pdf:class9mathsch11
    },{
        class:"9",
        name:"Maths",
        chapter:"12",
        chaptername:"Statistics",
        pdf:class9mathsch12
    },{
        class:"9",
        name:"Science",
        chapter:"01",
        chaptername:"Matter in Our Surroundings",
        pdf:class9sciencech1
    },{
        class:"9",
        name:"Science",
        chapter:"02",
        chaptername:"Is Matter Around Us Pure?",
        pdf:class9sciencech2
    },{
        class:"9",
        name:"Science",
        chapter:"03",
        chaptername:"Atoms and Molecules",
        pdf:class9sciencech3
    },{
        class:"9",
        name:"Science",
        chapter:"04",
        chaptername:"Structure of the Atom",
        pdf:class9sciencech4
    },{
        class:"9",
        name:"Science",
        chapter:"05",
        chaptername:"The Fundamental Unit of Life",
        pdf:class9sciencech5
    },{
        class:"9",
        name:"Science",
        chapter:"06",
        chaptername:"Tissues",
        pdf:class9sciencech6
    },{
        class:"9",
        name:"Science",
        chapter:"07",
        chaptername:"Motion",
        pdf:class9sciencech7
    },{
        class:"9",
        name:"Science",
        chapter:"08",
        chaptername:"Force and Laws of Motion",
        pdf:class9sciencech8
    },{
        class:"9",
        name:"Science",
        chapter:"09",
        chaptername:"Gravitation",
        pdf:class9sciencech9
    },{
        class:"9",
        name:"Science",
        chapter:"10",
        chaptername:"Work and Energy",
        pdf:class9sciencech10
    },{
        class:"9",
        name:"Science",
        chapter:"11",
        chaptername:"Sound",
        pdf:class9sciencech11
    },{
        class:"9",
        name:"Science",
        chapter:"12",
        chaptername:"Improvement in Food Resources",
        pdf:class9sciencech12
    },{
        class:"9",
        name:"Beehive",
        chapter:"01",
        chaptername:"The Fun They Had and Poem: The Road Not Taken",
        pdf:class9beehivech1
    },{
        class:"9",
        name:"Beehive",
        chapter:"02",
        chaptername:"The Sound of Music and Poem: Wind",
        pdf:class9beehivech2
    },{
        class:"9",
        name:"Beehive",
        chapter:"03",
        chaptername:"The Little Girl and Poem: Rain on the Roof",
        pdf:class9beehivech3
    },{
        class:"9",
        name:"Beehive",
        chapter:"04",
        chaptername:"A Truly Beautiful Mind and Poem: The Lake Isle of Innisfree",
        pdf:class9beehivech4
    },{
        class:"9",
        name:"Beehive",
        chapter:"05",
        chaptername:"The Snake and the Mirror and Poem: A Legend of the Northland",
        pdf:class9beehivech5
    },{
        class:"9",
        name:"Beehive",
        chapter:"06",
        chaptername:"My Childhood and Poem: No Men Are Foreign",
        pdf:class9beehivech6
    },{
        class:"9",
        name:"Beehive",
        chapter:"07",
        chaptername:"Reach for the Top and Poem: On Killing a Tree",
        pdf:class9beehivech7
    },{
        class:"9",
        name:"Beehive",
        chapter:"08",
        chaptername:"Kathmandu and Poem: A Slumber Did My Spirit Seal",
        pdf:class9beehivech8
    },{
        class:"9",
        name:"Beehive",
        chapter:"09",
        chaptername:"If I Were You",
        pdf:class9beehivech9
    },{
        class:"9",
        name:"Moments",
        chapter:"01",
        chaptername:"The Lost Child",
        pdf:class9momentsch1
    },{
        class:"9",
        name:"Moments",
        chapter:"02",
        chaptername:"The Adventures of Toto",
        pdf:class9momentsch2
    },{
        class:"9",
        name:"Moments",
        chapter:"03",
        chaptername:"Iswaran the Storyteller",
        pdf:class9momentsch3
    },{
        class:"9",
        name:"Moments",
        chapter:"04",
        chaptername:"In the Kingdom of Fools",
        pdf:class9momentsch4
    },{
        class:"9",
        name:"Moments",
        chapter:"05",
        chaptername:"The Happy Prince",
        pdf:class9momentsch5
    },{
        class:"9",
        name:"Moments",
        chapter:"06",
        chaptername:"Weathering the Storm in Ersama",
        pdf:class9momentsch6
    },{
        class:"9",
        name:"Moments",
        chapter:"07",
        chaptername:"The Last Leaf",
        pdf:class9momentsch7
    },{
        class:"9",
        name:"Moments",
        chapter:"08",
        chaptername:"A House Is Not a Home",
        pdf:class9momentsch8
    },{
        class:"9",
        name:"Moments",
        chapter:"09",
        chaptername:"The Beggar",
        pdf:class9momentsch9
    },{
        class:"9",
        name:"Kritika",
        chapter:"01",
        chaptername:"इस जल प्रलय में",
        pdf:class9kritikach1
    },{
        class:"9",
        name:"Kritika",
        chapter:"02",
        chaptername:"मेरे संग की औरतें",
        pdf:class9kritikach2
    },{
        class:"9",
        name:"Kritika",
        chapter:"03",
        chaptername:"रीढ़ की हड्डी",
        pdf:class9kritikach3
    },{
        class:"9",
        name:"Shitiz",
        chapter:"01",
        chaptername:"दो बैलों की कथा",
        pdf:class9shitizch1
    },{
        class:"9",
        name:"Shitiz",
        chapter:"02",
        chaptername:"ल्हासा की ओर",
        pdf:class9shitizch2
    },{
        class:"9",
        name:"Shitiz",
        chapter:"03",
        chaptername:"उपभोक्तावाद की संस्कृति",
        pdf:class9shitizch3
    },{
        class:"9",
        name:"Shitiz",
        chapter:"04",
        chaptername:"साँवले सपनों की याद",
        pdf:class9shitizch4
    },{
        class:"9",
        name:"Shitiz",
        chapter:"05",
        chaptername:"प्रेमचंद के फटे जूते",
        pdf:class9shitizch5
    },{
        class:"9",
        name:"Shitiz",
        chapter:"06",
        chaptername:"मेरे बचपन के दिन",
        pdf:class9shitizch6
    },{
        class:"9",
        name:"Shitiz",
        chapter:"07",
        chaptername:"साखियाँ एवं सबद",
        pdf:class9shitizch7
    },{
        class:"9",
        name:"Shitiz",
        chapter:"08",
        chaptername:"वाख",
        pdf:class9shitizch8
    },{
        class:"9",
        name:"Shitiz",
        chapter:"09",
        chaptername:"सवैये",
        pdf:class9shitizch9
    },{
        class:"9",
        name:"Shitiz",
        chapter:"10",
        chaptername:"कैदी और कोकिला",
        pdf:class9shitizch10
    },{
        class:"9",
        name:"Shitiz",
        chapter:"11",
        chaptername:"ग्राम श्री",
        pdf:class9shitizch11
    },{
        class:"9",
        name:"Shitiz",
        chapter:"12",
        chaptername:"मेघ आए",
        pdf:class9shitizch12
    },{
        class:"9",
        name:"Shitiz",
        chapter:"13",
        chaptername:"बच्चे काम पर जा रहे हैं",
        pdf:class9shitizch13
    },{
        class:"9",
        name:"Geography",
        chapter:"01",
        chaptername:"India – Size and Location",
        pdf:class9geoch1
    },{
        class:"9",
        name:"Geography",
        chapter:"02",
        chaptername:"Physical Features of India",
        pdf:class9geoch2
    },{
        class:"9",
        name:"Geography",
        chapter:"03",
        chaptername:"Drainage",
        pdf:class9geoch3
    },{
        class:"9",
        name:"Geography",
        chapter:"04",
        chaptername:"Climate",
        pdf:class9geoch4
    },{
        class:"9",
        name:"Geography",
        chapter:"05",
        chaptername:"Natural Vegetation and Wildlife",
        pdf:class9geoch5
    },{
        class:"9",
        name:"Geography",
        chapter:"06",
        chaptername:"Population",
        pdf:class9geoch6
    },{
        class:"9",
        name:"Economics",
        chapter:"01",
        chaptername:"The Story of Village Palampur",
        pdf:class9ecoch1
    },{
        class:"9",
        name:"Economics",
        chapter:"02",
        chaptername:"People as Resource ",
        pdf:class9ecoch2
    },{
        class:"9",
        name:"Economics",
        chapter:"03",
        chaptername:"Poverty as a Challenge",
        pdf:class9ecoch3
    },{
        class:"9",
        name:"Economics",
        chapter:"04",
        chaptername:"Food Security in India",
        pdf:class9ecoch4
    },{
        class:"9",
        name:"History",
        chapter:"01",
        chaptername:"The French Revolution",
        pdf:class9historych1
    },{
        class:"9",
        name:"History",
        chapter:"02",
        chaptername:"Socialism in Europe and the Russian Revolution",
        pdf:class9historych2
    },{
        class:"9",
        name:"History",
        chapter:"03",
        chaptername:"Nazism and the Rise of Hitler",
        pdf:class9historych3
    },{
        class:"9",
        name:"History",
        chapter:"04",
        chaptername:"Forest Society and Colonialism",
        pdf:class9historych4
    },{
        class:"9",
        name:"History",
        chapter:"05",
        chaptername:"Pastoralists in the Modern World",
        pdf:class9historych5
    },{
        class:"9",
        name:"Civics",
        chapter:"01",
        chaptername:"What is Democracy? Why Is Democracy?",
        pdf:class9civicsch1
    },{
        class:"9",
        name:"Civics",
        chapter:"02",
        chaptername:"Constitutional Design",
        pdf:class9civicsch2
    },{
        class:"9",
        name:"Civics",
        chapter:"03",
        chaptername:"Electoral Politics",
        pdf:class9civicsch3
    },{
        class:"9",
        name:"Civics",
        chapter:"04",
        chaptername:"Working of Institutions",
        pdf:class9civicsch4
    },{
        class:"9",
        name:"Civics",
        chapter:"05",
        chaptername:"Democratic Rights",
        pdf:class9civicsch5
    },{
        class:"9",
        name:"Sanskrit",
        chapter:"01",
        chaptername:"लाख की चूड़ियाँ",
        pdf:class9sanskritch1
    },{
        class:"9",
        name:"Sanskrit",
        chapter:"02",
        chaptername:"बस की यात्रा",
        pdf:class9sanskritch2
    },{
        class:"9",
        name:"Sanskrit",
        chapter:"03",
        chaptername:"दीवानों की हस्ती",
        pdf:class9sanskritch3
    },{
        class:"9",
        name:"Sanskrit",
        chapter:"04",
        chaptername:"भगवान के डाकिये",
        pdf:class9sanskritch4
    },{
        class:"9",
        name:"Sanskrit",
        chapter:"05",
        chaptername:"क्या निराश हुआ जाए",
        pdf:class9sanskritch5
    },{
        class:"9",
        name:"Sanskrit",
        chapter:"06",
        chaptername:"यह सबसे कठिन समय नहीं",
        pdf:class9sanskritch6
    },{
        class:"9",
        name:"Sanskrit",
        chapter:"07",
        chaptername:"कबीर की साखियाँ",
        pdf:class9sanskritch7
    },{
        class:"9",
        name:"Sanskrit",
        chapter:"08",
        chaptername:"सुदामा चरित",
        pdf:class9sanskritch8
    },{
        class:"9",
        name:"Sanskrit",
        chapter:"09",
        chaptername:"जहाँ पहिया हैं",
        pdf:class9sanskritch9
    },{
        class:"9",
        name:"Sanskrit",
        chapter:"10",
        chaptername:"अकबरी लोटा",
        pdf:class9sanskritch10
    },{
        class:"10",
        name:"Maths",
        chapter:"01",
        chaptername:"Real Numbers",
        pdf:class10mathsch1
    },{
        class:"10",
        name:"Maths",
        chapter:"02",
        chaptername:"Polynomials",
        pdf:class10mathsch2
    },{
        class:"10",
        name:"Maths",
        chapter:"03",
        chaptername:"Pair of Linear Equations in Two Variables",
        pdf:class10mathsch3
    },{
        class:"10",
        name:"Maths",
        chapter:"04",
        chaptername:"Quadratic Equations",
        pdf:class10mathsch4
    },{
        class:"10",
        name:"Maths",
        chapter:"05",
        chaptername:"Arithmetic Progressions",
        pdf:class10mathsch5
    },{
        class:"10",
        name:"Maths",
        chapter:"06",
        chaptername:"Triangles",
        pdf:class10mathsch6
    },{
        class:"10",
        name:"Maths",
        chapter:"07",
        chaptername:"Coordinate Geometry",
        pdf:class10mathsch7
    },{
        class:"10",
        name:"Maths",
        chapter:"08",
        chaptername:"Introduction to Trigonometry",
        pdf:class10mathsch8
    },{
        class:"10",
        name:"Maths",
        chapter:"09",
        chaptername:"Some Applications of Trigonometry",
        pdf:class10mathsch9
    },{
        class:"10",
        name:"Maths",
        chapter:"10",
        chaptername:"Circles",
        pdf:class10mathsch10
    },{
        class:"10",
        name:"Maths",
        chapter:"11",
        chaptername:"Areas Related to Circles",
        pdf:class10mathsch11
    },{
        class:"10",
        name:"Maths",
        chapter:"12",
        chaptername:"Surface Areas and Volumes<",
        pdf:class10mathsch12
    },{
        class:"10",
        name:"Maths",
        chapter:"13",
        chaptername:"Statistics",
        pdf:class10mathsch13
    },{
        class:"10",
        name:"Maths",
        chapter:"14",
        chaptername:"Probability",
        pdf:class10mathsch14
    },{
        class:"10",
        name:"Sanskrit",
        chapter:"01",
        chaptername:"शुचिपर्यावरणम्",
        pdf:class10sanskritch1
    },{
        class:"10",
        name:"Sanskrit",
        chapter:"02",
        chaptername:"बुद्धिर्बलवती सदा",
        pdf:class10sanskritch2
    },{
        class:"10",
        name:"Sanskrit",
        chapter:"03",
        chaptername:"शिशुलालनम",
        pdf:class10sanskritch3
    },{
        class:"10",
        name:"Sanskrit",
        chapter:"04",
        chaptername:"जननी तुल्यवत्सला",
        pdf:class10sanskritch4
    },{
        class:"10",
        name:"Sanskrit",
        chapter:"05",
        chaptername:"सुभाषितानि",
        pdf:class10sanskritch5
    },{
        class:"10",
        name:"Sanskrit",
        chapter:"06",
        chaptername:"सौहार्दं प्रकृतेः शोभा",
        pdf:class10sanskritch6
    },{
        class:"10",
        name:"Sanskrit",
        chapter:"07",
        chaptername:"विचित्रः साक्षी",
        pdf:class10sanskritch7
    },{
        class:"10",
        name:"Sanskrit",
        chapter:"08",
        chaptername:"सूक्तयः",
        pdf:class10sanskritch8
    },{
        class:"10",
        name:"Sanskrit",
        chapter:"09",
        chaptername:"भूकंपविभीषिका",
        pdf:class10sanskritch9
    },{
        class:"10",
        name:"Sanskrit",
        chapter:"10",
        chaptername:"अनयोक्त्यः",
        pdf:class10sanskritch10
    },{
        class:"10",
        name:"Science",
        chapter:"01",
        chaptername:"Chemical Reactions and Equations",
        pdf:class10sciencech1
    },{
        class:"10",
        name:"Science",
        chapter:"02",
        chaptername:"Acids Bases and Salt",
        pdf:class10sciencech2
    },{
        class:"10",
        name:"Science",
        chapter:"03",
        chaptername:"Metals and Non-metals",
        pdf:class10sciencech3
    },{
        class:"10",
        name:"Science",
        chapter:"04",
        chaptername:"Carbon and Its Compounds",
        pdf:class10sciencech4
    },{
        class:"10",
        name:"Science",
        chapter:"05",
        chaptername:"Life Process",
        pdf:class10sciencech5
    },{
        class:"10",
        name:"Science",
        chapter:"06",
        chaptername:"Control and Coordination",
        pdf:class10sciencech6
    },{
        class:"10",
        name:"Science",
        chapter:"07",
        chaptername:"How Do Organisms Reproduce?",
        pdf:class10sciencech7
    },{
        class:"10",
        name:"Science",
        chapter:"08",
        chaptername:"Heredity and Evolution",
        pdf:class10sciencech8
    },{
        class:"10",
        name:"Science",
        chapter:"09",
        chaptername:"Light – Reflection and Refraction",
        pdf:class10sciencech9
    },{
        class:"10",
        name:"Science",
        chapter:"10",
        chaptername:"The Human Eye and The Colourful World",
        pdf:class10sciencech10
    },{
        class:"10",
        name:"Science",
        chapter:"11",
        chaptername:"Electricity",
        pdf:class10sciencech11
    },{
        class:"10",
        name:"Science",
        chapter:"12",
        chaptername:"Magnetic Effects of Electric Current",
        pdf:class10sciencech12
    },{
        class:"10",
        name:"Science",
        chapter:"13",
        chaptername:"Our Environment",
        pdf:class10sciencech13
    },{
        class:"10",
        name:"Kritika",
        chapter:"01",
        chaptername:"माता का आँचल",
        pdf:class10kritikach1
    },{
        class:"10",
        name:"Kritika",
        chapter:"02",
        chaptername:"साना-साना हाथ जोड़ि",
        pdf:class10kritikach2
    },{
        class:"10",
        name:"Kritika",
        chapter:"03",
        chaptername:"मैं क्यों लिखता हूँ?",
        pdf:class10kritikach3
    },{
        class:"10",
        name:"Shitiz",
        chapter:"01",
        chaptername:"पद",
        pdf:class10shitizch1
    },{
        class:"10",
        name:"Shitiz",
        chapter:"02",
        chaptername:"राम-लक्ष्मण-परशुराम संवाद",
        pdf:class10shitizch2
    },{
        class:"10",
        name:"Shitiz",
        chapter:"03",
        chaptername:"आत्मकथ्य",
        pdf:class10shitizch3
    },{
        class:"10",
        name:"Shitiz",
        chapter:"04",
        chaptername:"उत्साह और अट नहीं रही",
        pdf:class10shitizch4
    },{
        class:"10",
        name:"Shitiz",
        chapter:"05",
        chaptername:"यह दंतुरहित मुस्कान और फसल",
        pdf:class10shitizch5
    },{
        class:"10",
        name:"Shitiz",
        chapter:"06",
        chaptername:"संगतकार",
        pdf:class10shitizch6
    },{
        class:"10",
        name:"Shitiz",
        chapter:"07",
        chaptername:"नेताजी का चश्मा",
        pdf:class10shitizch7
    },{
        class:"10",
        name:"Shitiz",
        chapter:"08",
        chaptername:"बालगोबिन भगत",
        pdf:class10shitizch8
    },{
        class:"10",
        name:"Shitiz",
        chapter:"09",
        chaptername:"लखनवी अंदाज़",
        pdf:class10shitizch9
    },{
        class:"10",
        name:"Shitiz",
        chapter:"10",
        chaptername:"एक कहानी यह भी",
        pdf:class10shitizch10
    },{
        class:"10",
        name:"Shitiz",
        chapter:"11",
        chaptername:"नौबतखाने में इबादत",
        pdf:class10shitizch11
    },{
        class:"10",
        name:"Shitiz",
        chapter:"12",
        chaptername:"संस्कृति",
        pdf:class10shitizch12
    },{
        class:"10",
        name:"First Flight",
        chapter:"01",
        chaptername:"A Letter to God ,  Dust of Snow and Fire and Ice",
        pdf:class10firstflightch1
    },{
        class:"10",
        name:"First Flight",
        chapter:"02",
        chaptername:"Nelson Mandela: Long Walk to Freedom and A Tiger in the Zoo",
        pdf:class10firstflightch2
    },{
        class:"10",
        name:"First Flight",
        chapter:"03",
        chaptername:"Stories about Flying I. His First Flight II. Black Aeroplane and How to Tell Wild Animals ,The Ball Poem",
        pdf:class10firstflightch3
    },{
        class:"10",
        name:"First Flight",
        chapter:"04",
        chaptername:"From the Diary of Anne Frank and Amanda!",
        pdf:class10firstflightch4
    },{
        class:"10",
        name:"First Flight",
        chapter:"05",
        chaptername:"Glimpses of India I. A Baker from Goa II. Coorg III. Tea from Assam The Trees",
        pdf:class10firstflightch5
    },{
        class:"10",
        name:"First Flight",
        chapter:"06",
        chaptername:"Mijbil the Otter and Fog",
        pdf:class10firstflightch6
    },{
        class:"10",
        name:"First Flight",
        chapter:"07",
        chaptername:"Madam Rides the Bus and The Tale of Custard the Dragon",
        pdf:class10firstflightch7
    },{
        class:"10",
        name:"First Flight",
        chapter:"08",
        chaptername:"The Sermon at Benares and For Anne Gregory",
        pdf:class10firstflightch8
    },{
        class:"10",
        name:"First Flight",
        chapter:"09",
        chaptername:"The Proposal",
        pdf:class10firstflightch9
    },{
        class:"10",
        name:"Foot Print",
        chapter:"01",
        chaptername:"A Triumph of Surgery",
        pdf:class10footch1
    },{
        class:"10",
        name:"Foot Print",
        chapter:"02",
        chaptername:"The Thief’s Story",
        pdf:class10footch2
    },{
        class:"10",
        name:"Foot Print",
        chapter:"03",
        chaptername:"The Midnight Visitor",
        pdf:class10footch3
    },{
        class:"10",
        name:"Foot Print",
        chapter:"04",
        chaptername:"A Question of Trust",
        pdf:class10footch4
    },{
        class:"10",
        name:"Foot Print",
        chapter:"05",
        chaptername:"Footprints without Feet",
        pdf:class10footch5
    },{
        class:"10",
        name:"Foot Print",
        chapter:"06",
        chaptername:"The Making of a Scientist",
        pdf:class10footch6
    },{
        class:"10",
        name:"Foot Print",
        chapter:"07",
        chaptername:"The Necklace",
        pdf:class10footch7
    },{
        class:"10",
        name:"Foot Print",
        chapter:"08",
        chaptername:"Bholi",
        pdf:class10footch8
    },{
        class:"10",
        name:"Foot Print",
        chapter:"09",
        chaptername:"The Book That Saved the Earth",
        pdf:class10footch9
    },{
        class:"10",
        name:"History",
        chapter:"01",
        chaptername:"The Rise of Nationalism in Europe",
        pdf:class10historych1
    },{
        class:"10",
        name:"History",
        chapter:"02",
        chaptername:"Nationalism in India",
        pdf:class10historych2
    },{
        class:"10",
        name:"History",
        chapter:"03",
        chaptername:"The Making of a Global World",
        pdf:class10historych3
    },{
        class:"10",
        name:"History",
        chapter:"04",
        chaptername:"The Age of Industrialisation",
        pdf:class10historych4
    },{
        class:"10",
        name:"History",
        chapter:"05",
        chaptername:"Print Culture and the Modern World",
        pdf:class10historych5
    },{
        class:"10",
        name:"Civics",
        chapter:"01",
        chaptername:"Power-sharing",
        pdf:class10civicsch1
    },{
        class:"10",
        name:"Civics",
        chapter:"02",
        chaptername:"Federalism",
        pdf:class10civicsch2
    },{
        class:"10",
        name:"Civics",
        chapter:"03",
        chaptername:"Gender, Religion and Caste",
        pdf:class10civicsch3
    },{
        class:"10",
        name:"Civics",
        chapter:"04",
        chaptername:"Political Parties",
        pdf:class10civicsch4
    },{
        class:"10",
        name:"Civics",
        chapter:"05",
        chaptername:"Outcomes of Democracy",
        pdf:class10civicsch5
    },{
        class:"10",
        name:"Geography",
        chapter:"01",
        chaptername:"Resources and Development",
        pdf:class10geoch1
    },{
        class:"10",
        name:"Geography",
        chapter:"02",
        chaptername:"Forest and Wildlife Resources",
        pdf:class10geoch2
    },{
        class:"10",
        name:"Geography",
        chapter:"03",
        chaptername:"Water Resources",
        pdf:class10geoch3
    },{
        class:"10",
        name:"Geography",
        chapter:"04",
        chaptername:"Agriculture",
        pdf:class10geoch4
    },{
        class:"10",
        name:"Geography",
        chapter:"05",
        chaptername:"Minerals and Energy Resources",
        pdf:class10geoch5
    },{
        class:"10",
        name:"Geography",
        chapter:"06",
        chaptername:"Manufacturing Industries",
        pdf:class10geoch6
    },{
        class:"10",
        name:"Geography",
        chapter:"07",
        chaptername:"Lifelines of National Economy",
        pdf:class10geoch7
    },{
        class:"10",
        name:"Economics",
        chapter:"01",
        chaptername:"Development",
        pdf:class10ecoch1
    },{
        class:"10",
        name:"Economics",
        chapter:"02",
        chaptername:"Sectors of the Indian Economy",
        pdf:class10ecoch2
    },{
        class:"10",
        name:"Economics",
        chapter:"03",
        chaptername:"Money and Credit",
        pdf:class10ecoch3
    },{
        class:"10",
        name:"Economics",
        chapter:"04",
        chaptername:"Globalisation and the Indian Economy",
        pdf:class10ecoch4
    },{
        class:"10",
        name:"Economics",
        chapter:"05",
        chaptername:"Consumer Rights",
        pdf:class10ecoch5
    },{
        class:"11",
        name:"Accounts",
        chapter:"01",
        chaptername:"Introduction to Accounting",
        pdf:class11accountsch1
    },{
        class:"11",
        name:"Accounts",
        chapter:"02",
        chaptername:"Theory Base of Accounting",
        pdf:class11accountsch2
    },{
        class:"11",
        name:"Accounts",
        chapter:"03",
        chaptername:"Recording of Transactions-I",
        pdf:class11accountsch3
    },{
        class:"11",
        name:"Accounts",
        chapter:"04",
        chaptername:"Recording of Transactions-II",
        pdf:class11accountsch4
    },{
        class:"11",
        name:"Accounts",
        chapter:"05",
        chaptername:"Bank Reconciliation Statement",
        pdf:class11accountsch5
    },{
        class:"11",
        name:"Antra",
        chapter:"01",
        chaptername:"ईदगाह",
        pdf:class11antrach1
    },{
        class:"11",
        name:"Antra",
        chapter:"02",
        chaptername:"दोपहर का भोजन",
        pdf:class11antrach2
    },{
        class:"11",
        name:"Antra",
        chapter:"03",
        chaptername:"टार्च बेचने वाले",
        pdf:class11antrach3
    },{
        class:"11",
        name:"Antra",
        chapter:"04",
        chaptername:"गूँगे",
        pdf:class11antrach4
    },{
        class:"11",
        name:"Antra",
        chapter:"05",
        chaptername:"ज्योतिबा फुले",
        pdf:class11antrach5
    },{
        class:"11",
        name:"Antra",
        chapter:"06",
        chaptername:"खानाबदोश",
        pdf:class11antrach6
    },{
        class:"11",
        name:"Antra",
        chapter:"07",
        chaptername:"उसकी माँ",
        pdf:class11antrach7
    },{
        class:"11",
        name:"Antra",
        chapter:"08",
        chaptername:"भारतवर्ष की उन्नति कैसे हो सकती है?",
        pdf:class11antrach8
    },{
        class:"11",
        name:"Antra",
        chapter:"09",
        chaptername:"अरे इन दोहुन राह न पाई बालम; आवो हमारे गेह रे<",
        pdf:class11antrach9
    },{
        class:"11",
        name:"Antra",
        chapter:"10",
        chaptername:"खेलन में को काको गुसैयाँ, मुरली तऊ गुपालहिं भावति",
        pdf:class11antrach10
    },{
        class:"11",
        name:"Antra",
        chapter:"11",
        chaptername:"हँसी की चोट, सपना, दरबार",
        pdf:class11antrach11
    },{
        class:"11",
        name:"Antra",
        chapter:"12",
        chaptername:"संध्या के बाद",
        pdf:class11antrach12
    },{
        class:"11",
        name:"Antra",
        chapter:"13",
        chaptername:"जाग तुझको दूर जाना, सब आँखों के आँसू उजले",
        pdf:class11antrach13
    },{
        class:"11",
        name:"Antra",
        chapter:"14",
        chaptername:"बादल को घिरते देखा है",
        pdf:class11antrach14
    },{
        class:"11",
        name:"Antra",
        chapter:"15",
        chaptername:"हस्तक्षेप",
        pdf:class11antrach15
    },{
        class:"11",
        name:"Antra",
        chapter:"16",
        chaptername:"घर में वापसी",
        pdf:class11antrach16
    },{
        class:"11",
        name:"Biology",
        chapter:"01",
        chaptername:"The Living World",
        pdf:class11bioch1
    },{
        class:"11",
        name:"Biology",
        chapter:"02",
        chaptername:"Biological Classification",
        pdf:class11bioch2
    },{
        class:"11",
        name:"Biology",
        chapter:"03",
        chaptername:"Plant Kingdom",
        pdf:class11bioch3
    },{
        class:"11",
        name:"Biology",
        chapter:"04",
        chaptername:"Animal Kingdom",
        pdf:class11bioch4
    },{
        class:"11",
        name:"Biology",
        chapter:"05",
        chaptername:"Morphology of Flowering Plants",
        pdf:class11bioch5
    },{
        class:"11",
        name:"Biology",
        chapter:"06",
        chaptername:"Anatomy of Flowering Plants",
        pdf:class11bioch6
    },{
        class:"11",
        name:"Biology",
        chapter:"07",
        chaptername:"Structural Organisation in Animals",
        pdf:class11bioch7
    },{
        class:"11",
        name:"Biology",
        chapter:"08",
        chaptername:"Cell The Unit of Life",
        pdf:class11bioch8
    },{
        class:"11",
        name:"Biology",
        chapter:"09",
        chaptername:"Biomolecules",
        pdf:class11bioch9
    },{
        class:"11",
        name:"Biology",
        chapter:"10",
        chaptername:"Cell Cycle and Cell Division",
        pdf:class11bioch10
    },{
        class:"11",
        name:"Biology",
        chapter:"11",
        chaptername:"Photosynthesis in Higher Plants",
        pdf:class11bioch11
    },{
        class:"11",
        name:"Biology",
        chapter:"12",
        chaptername:"Respiration in Plants",
        pdf:class11bioch12
    },{
        class:"11",
        name:"Biology",
        chapter:"13",
        chaptername:"Plant Growth and Development",
        pdf:class11bioch13
    },{
        class:"11",
        name:"Biology",
        chapter:"14",
        chaptername:"Breathing and Exchange of Gases",
        pdf:class11bioch14
    },{
        class:"11",
        name:"Biology",
        chapter:"15",
        chaptername:"Body Fluids and Circulation",
        pdf:class11bioch15
    },{
        class:"11",
        name:"Biology",
        chapter:"16",
        chaptername:"Excretory Products and their Elimination",
        pdf:class11bioch16
    },{
        class:"11",
        name:"Biology",
        chapter:"17",
        chaptername:"Locomotion and Movement",
        pdf:class11bioch17
    },{
        class:"11",
        name:"Biology",
        chapter:"18",
        chaptername:"Neural Control and Coordination",
        pdf:class11bioch18
    },{
        class:"11",
        name:"Biology",
        chapter:"19",
        chaptername:"Chemical Coordination and integration",
        pdf:class11bioch19
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"01",
        chaptername:"Introduction",
        pdf:class11biotechch1
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"02",
        chaptername:"Cellular Organelles",
        pdf:class11biotechch2
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"03",
        chaptername:"Biomolecules",
        pdf:class11biotechch3
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"04",
        chaptername:"Enzymes and Bioenergetics",
        pdf:class11biotechch4
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"05",
        chaptername:"Cellular Processes",
        pdf:class11biotechch5
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"06",
        chaptername:"Basic Principles of Inheritance",
        pdf:class11biotechch6
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"07",
        chaptername:"Basic Processes",
        pdf:class11biotechch7
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"08",
        chaptername:"Genetic Disorder",
        pdf:class11biotechch8
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"09",
        chaptername:"Introduction to Bioinformatics",
        pdf:class11biotechch9
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"10",
        chaptername:"Protein Informatics and Cheminformatics",
        pdf:class11biotechch10
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"11",
        chaptername:"Programming & Systems Biology",
        pdf:class11biotechch11
    },{
        class:"11",
        name:"Biotechnology",
        chapter:"12",
        chaptername:"Tools Ands Technologies",
        pdf:class11biotechch12
    },{
        class:"11",
        name:"Business Studies",
        chapter:"01",
        chaptername:"Business, Trade and Commerce",
        pdf:class11bussstudych1
    },{
        class:"11",
        name:"Business Studies",
        chapter:"02",
        chaptername:"Forms of Business Organisation",
        pdf:class11bussstudych2
    },{
        class:"11",
        name:"Business Studies",
        chapter:"03",
        chaptername:"Private, Public and Global Enterprises",
        pdf:class11bussstudych3
    },{
        class:"11",
        name:"Business Studies",
        chapter:"04",
        chaptername:"Business Services",
        pdf:class11bussstudych4
    },{
        class:"11",
        name:"Business Studies",
        chapter:"05",
        chaptername:"Emerging Modes of Business",
        pdf:class11bussstudych5
    },{
        class:"11",
        name:"Business Studies",
        chapter:"06",
        chaptername:"Social Responsibilities of Business and Business Ethics",
        pdf:class11bussstudych6
    },{
        class:"11",
        name:"Business Studies",
        chapter:"07",
        chaptername:"Formation of a Company",
        pdf:class11bussstudych7
    },{
        class:"11",
        name:"Business Studies",
        chapter:"08",
        chaptername:"Sources of Business Finance",
        pdf:class11bussstudych8
    },{
        class:"11",
        name:"Business Studies",
        chapter:"09",
        chaptername:"Small Business",
        pdf:class11bussstudych9
    },{
        class:"11",
        name:"Business Studies",
        chapter:"10",
        chaptername:"Internal Trade",
        pdf:class11bussstudych10
    },{
        class:"11",
        name:"Business Studies",
        chapter:"11",
        chaptername:"International Business",
        pdf:class11bussstudych11
    },{
        class:"11",
        name:"Chemistry Part 1",
        chapter:"01",
        chaptername:"Some Basic Concepts of Chemistry",
        pdf:class11chemistry1ch1
    },{
        class:"11",
        name:"Chemistry Part 1",
        chapter:"02",
        chaptername:"Structure of Atom",
        pdf:class11chemistry1ch2
    },{
        class:"11",
        name:"Chemistry Part 1",
        chapter:"03",
        chaptername:"Classification of Elements and Periodicity in Properties",
        pdf:class11chemistry1ch3
    },{
        class:"11",
        name:"Chemistry Part 1",
        chapter:"04",
        chaptername:"Chemical Bonding and Molecular Structure",
        pdf:class11chemistry1ch4
    },{
        class:"11",
        name:"Chemistry Part 1",
        chapter:"05",
        chaptername:"Thermodynamics",
        pdf:class11chemistry1ch5
    },{
        class:"11",
        name:"Chemistry Part 1",
        chapter:"06",
        chaptername:"Equilibrium",
        pdf:class11chemistry1ch6
    },{
        class:"11",
        name:"Chemistry Part 2",
        chapter:"01",
        chaptername:"Redox Reactions",
        pdf:class11chemistry2ch1
    },{
        class:"11",
        name:"Chemistry Part 2",
        chapter:"02",
        chaptername:"Organic Chemistry - Some Basic Principles and Techniques",
        pdf:class11chemistry2ch2
    },{
        class:"11",
        name:"Chemistry Part 2",
        chapter:"03",
        chaptername:"Hydrocarbons",
        pdf:class11chemistry2ch3
    },{
        class:"11",
        name:"Computer Science",
        chapter:"01",
        chaptername:"Computer System",
        pdf:class11csch1
    },{
        class:"11",
        name:"Computer Science",
        chapter:"02",
        chaptername:"Encoding Schemes and Number Systems",
        pdf:class11csch2
    },{
        class:"11",
        name:"Computer Science",
        chapter:"03",
        chaptername:"Emerging Trends",
        pdf:class11csch3
    },{
        class:"11",
        name:"Computer Science",
        chapter:"04",
        chaptername:"Introduction to Problem-Solving",
        pdf:class11csch4
    },{
        class:"11",
        name:"Computer Science",
        chapter:"05",
        chaptername:"Getting Started with Python",
        pdf:class11csch5
    },{
        class:"11",
        name:"Computer Science",
        chapter:"06",
        chaptername:"Flow of Control",
        pdf:class11csch6
    },{
        class:"11",
        name:"Computer Science",
        chapter:"07",
        chaptername:"Functions",
        pdf:class11csch7
    },{
        class:"11",
        name:"Computer Science",
        chapter:"08",
        chaptername:"Strings",
        pdf:class11csch8
    },{
        class:"11",
        name:"Computer Science",
        chapter:"09",
        chaptername:"Lists",
        pdf:class11csch9
    },{
        class:"11",
        name:"Computer Science",
        chapter:"10",
        chaptername:"Tuples and Dictionaries",
        pdf:class11csch10
    },{
        class:"11",
        name:"Computer Science",
        chapter:"11",
        chaptername:"Societal Impact",
        pdf:class11csch11
    },{
        class:"11",
        name:"Economics",
        chapter:"01",
        chaptername:"Indian Economy on the Eve of Independence",
        pdf:class11ecoch1
    },{
        class:"11",
        name:"Economics",
        chapter:"02",
        chaptername:"Indian Economy 1950-1990",
        pdf:class11ecoch2
    },{
        class:"11",
        name:"Economics",
        chapter:"03",
        chaptername:"Liberalisation, Privatisation and Globalisation -An Appraisal",
        pdf:class11ecoch3
    },{
        class:"11",
        name:"Economics",
        chapter:"04",
        chaptername:"Human Capital Formation in India",
        pdf:class11ecoch4
    },{
        class:"11",
        name:"Economics",
        chapter:"05",
        chaptername:"Rural Development",
        pdf:class11ecoch5
    },{
        class:"11",
        name:"Economics",
        chapter:"06",
        chaptername:"Employment-Growth, Informalisation and Related Issues",
        pdf:class11ecoch6
    },{
        class:"11",
        name:"Economics",
        chapter:"07",
        chaptername:"Environment Sustainable Development",
        pdf:class11ecoch7
    },{
        class:"11",
        name:"Economics",
        chapter:"08",
        chaptername:"Comparative Development Experience of India with its Neighbours",
        pdf:class11ecoch8
    },{
        class:"11",
        name:"Fine Art",
        chapter:"01",
        chaptername:"Prehistoric Rock Paintings",
        pdf:class11fineartch1
    },{
        class:"11",
        name:"Fine Art",
        chapter:"02",
        chaptername:"Arts of the Indus Valley",
        pdf:class11fineartch2
    },{
        class:"11",
        name:"Fine Art",
        chapter:"03",
        chaptername:"Arts of the Mauryan Period",
        pdf:class11fineartch3
    },{
        class:"11",
        name:"Fine Art",
        chapter:"04",
        chaptername:"Post-Mauryan Trends in Indian Art and Architecture",
        pdf:class11fineartch4
    },{
        class:"11",
        name:"Fine Art",
        chapter:"05",
        chaptername:"Later Mural Traditions",
        pdf:class11fineartch5
    },{
        class:"11",
        name:"Fine Art",
        chapter:"06",
        chaptername:"Temple Architecture and Sculpture",
        pdf:class11fineartch6
    },{
        class:"11",
        name:"Fine Art",
        chapter:"07",
        chaptername:"Indian Bronze Sculpture",
        pdf:class11fineartch7
    },{
        class:"11",
        name:"Fine Art",
        chapter:"01",
        chaptername:"Some Aspects of Indo-Islamic Architecture",
        pdf:class11fineartch8
    },{
        class:"11",
        name:"Geography",
        chapter:"01",
        chaptername:"Geography as a Discipline",
        pdf:class11geoch1
    },{
        class:"11",
        name:"Geography",
        chapter:"02",
        chaptername:"The Origin and Evolution of the Earth",
        pdf:class11geoch2
    },{
        class:"11",
        name:"Geography",
        chapter:"03",
        chaptername:"Interior of the Earth",
        pdf:class11geoch3
    },{
        class:"11",
        name:"Geography",
        chapter:"04",
        chaptername:"Distribution of Oceans and Continents",
        pdf:class11geoch4
    },{
        class:"11",
        name:"Geography",
        chapter:"05",
        chaptername:"Geomorphic Processes",
        pdf:class11geoch5
    },{
        class:"11",
        name:"Geography",
        chapter:"06",
        chaptername:"Landforms and their Evolution",
        pdf:class11geoch6
    },{
        class:"11",
        name:"Geography",
        chapter:"07",
        chaptername:"Composition and Structure of Atmosphere",
        pdf:class11geoch7
    },{
        class:"11",
        name:"Geography",
        chapter:"08",
        chaptername:"Solar Radiation, Heat balance and Temperature",
        pdf:class11geoch8
    },{
        class:"11",
        name:"Geography",
        chapter:"09",
        chaptername:"Atmospheric Circulation and Weather Systems",
        pdf:class11geoch9
    },{
        class:"11",
        name:"Geography",
        chapter:"10",
        chaptername:"Water in the Atmosphere",
        pdf:class11geoch10
    },{
        class:"11",
        name:"Geography",
        chapter:"11",
        chaptername:"World Climate and Climate Change",
        pdf:class11geoch11
    },{
        class:"11",
        name:"Geography",
        chapter:"12",
        chaptername:"Water (Oceans)",
        pdf:class11geoch12
    },{
        class:"11",
        name:"Geography",
        chapter:"13",
        chaptername:"Movements of Ocean Water",
        pdf:class11geoch13
    },{
        class:"11",
        name:"Geography",
        chapter:"14",
        chaptername:"Biodiversity and Conservation",
        pdf:class11geoch14
    },{
        class:"11",
        name:"History",
        chapter:"01",
        chaptername:"Writing and City Life",
        pdf:class11historych1
    },{
        class:"11",
        name:"History",
        chapter:"02",
        chaptername:"An Empire Across Three Continents",
        pdf:class11historych2
    },{
        class:"11",
        name:"History",
        chapter:"03",
        chaptername:"Nomadic Empires",
        pdf:class11historych3
    },{
        class:"11",
        name:"History",
        chapter:"04",
        chaptername:"The Three Orders",
        pdf:class11historych4
    },{
        class:"11",
        name:"History",
        chapter:"05",
        chaptername:"Changing Cultural Traditions",
        pdf:class11historych5
    },{
        class:"11",
        name:"History",
        chapter:"06",
        chaptername:"Displacing Indigenous Peoples",
        pdf:class11historych6
    },{
        class:"11",
        name:"History",
        chapter:"07",
        chaptername:"Paths to Modernisation",
        pdf:class11historych7
    },{
        class:"11",
        name:"Hornbill",
        chapter:"01",
        chaptername:"The Portrait of a Lady",
        pdf:class11hornbellch1
    },{
        class:"11",
        name:"Hornbill",
        chapter:"02",
        chaptername:"We’re Not Afraid to Die…if We Can All Be Together",
        pdf:class11hornbellch2
    },{
        class:"11",
        name:"Hornbill",
        chapter:"03",
        chaptername:"Discovering Tut: the Saga Continues",
        pdf:class11hornbellch3
    },{
        class:"11",
        name:"Hornbill",
        chapter:"04",
        chaptername:"The Ailing Planet: the Green Movement’s Role",
        pdf:class11hornbellch4
    },{
        class:"11",
        name:"Hornbill",
        chapter:"05",
        chaptername:"The Adventure",
        pdf:class11hornbellch5
    },{
        class:"11",
        name:"Hornbill",
        chapter:"06",
        chaptername:"Silk Road",
        pdf:class11hornbellch6
    },{
        class:"11",
        name:"Hornbill",
        chapter:"07",
        chaptername:"Note Making",
        pdf:class11hornbellch7
    },{
        class:"11",
        name:"Hornbill",
        chapter:"08",
        chaptername:"Summarising",
        pdf:class11hornbellch8
    },{
        class:"11",
        name:"Hornbill",
        chapter:"09",
        chaptername:"Sub-titling",
        pdf:class11hornbellch9
    },{
        class:"11",
        name:"Hornbill",
        chapter:"10",
        chaptername:"Essay writing",
        pdf:class11hornbellch10
    },{
        class:"11",
        name:"Hornbill",
        chapter:"11",
        chaptername:"Letter Writing",
        pdf:class11hornbellch11
    },{
        class:"11",
        name:"Hornbill",
        chapter:"12",
        chaptername:"Creative Writing",
        pdf:class11hornbellch12
    },{
        class:"11",
        name:"Human Studies 1",
        chapter:"01",
        chaptername:"Human Ecology and Family Sciences",
        pdf:class11humanstd1ch1
    },{
        class:"11",
        name:"Human Studies 1",
        chapter:"02",
        chaptername:"Understanding Oneself: Adolescence",
        pdf:class11humanstd1ch2
    },{
        class:"11",
        name:"Human Studies 1",
        chapter:"03",
        chaptername:"Food, Nutrition, Health and Fitness",
        pdf:class11humanstd1ch3
    },{
        class:"11",
        name:"Human Studies 1",
        chapter:"04",
        chaptername:"Management of Resources",
        pdf:class11humanstd1ch4
    },{
        class:"11",
        name:"Human Studies 1",
        chapter:"05",
        chaptername:"Fabrics Around Us",
        pdf:class11humanstd1ch5
    },{
        class:"11",
        name:"Human Studies 1",
        chapter:"06",
        chaptername:"Media and Communication Technology",
        pdf:class11humanstd1ch6
    },{
        class:"11",
        name:"Human Studies 1",
        chapter:"07",
        chaptername:"Understanding Family, Community and Society",
        pdf:class11humanstd1ch7
    },{
        class:"11",
        name:"Human Studies 2",
        chapter:"01",
        chaptername:"Nutrition, Health and Well-being",
        pdf:class11humanstd2ch1
    },{
        class:"11",
        name:"Human Studies 2",
        chapter:"02",
        chaptername:"Our Apparel",
        pdf:class11humanstd2ch2
    },{
        class:"11",
        name:"Human Studies 2",
        chapter:"03",
        chaptername:"Adulthood",
        pdf:class11humanstd2ch3
    },{
        class:"11",
        name:"Human Studies 2",
        chapter:"04",
        chaptername:"Care and Maintenance of Fabrics",
        pdf:class11humanstd2ch4
    },{
        class:"11",
        name:"Information Technology",
        chapter:"01",
        chaptername:"Computer System",
        pdf:class11ipch1
    },{
        class:"11",
        name:"Information Technology",
        chapter:"02",
        chaptername:"Emerging Trends",
        pdf:class11ipch2
    },{
        class:"11",
        name:"Information Technology",
        chapter:"03",
        chaptername:"Brief Overview of Python",
        pdf:class11ipch3
    },{
        class:"11",
        name:"Information Technology",
        chapter:"04",
        chaptername:"Working with Lists and Dictionaries",
        pdf:class11ipch4
    },{
        class:"11",
        name:"Information Technology",
        chapter:"05",
        chaptername:"Understanding Data",
        pdf:class11ipch5
    },{
        class:"11",
        name:"Information Technology",
        chapter:"06",
        chaptername:"Introduction to NumPy",
        pdf:class11ipch6
    },{
        class:"11",
        name:"Information Technology",
        chapter:"07",
        chaptername:"Database Concepts",
        pdf:class11ipch7
    },{
        class:"11",
        name:"Information Technology",
        chapter:"08",
        chaptername:"Introduction to Structured Query Language (SQL)",
        pdf:class11ipch8
    },{
        class:"11",
        name:"Maths",
        chapter:"01",
        chaptername:"Sets",
        pdf:class11mathsch1
    },{
        class:"11",
        name:"Maths",
        chapter:"02",
        chaptername:"Relations and Functions",
        pdf:class11mathsch2
    },{
        class:"11",
        name:"Maths",
        chapter:"03",
        chaptername:"Trigonometric Functions",
        pdf:class11mathsch3
    },{
        class:"11",
        name:"Maths",
        chapter:"04",
        chaptername:"Complex Numbers and Quadratic Equations",
        pdf:class11mathsch4
    },{
        class:"11",
        name:"Maths",
        chapter:"05",
        chaptername:"Linear Inequalities",
        pdf:class11mathsch5
    },{
        class:"11",
        name:"Maths",
        chapter:"06",
        chaptername:"Permutations and Combinations",
        pdf:class11mathsch6
    },{
        class:"11",
        name:"Maths",
        chapter:"07",
        chaptername:"Binomial Theorem",
        pdf:class11mathsch7
    },{
        class:"11",
        name:"Maths",
        chapter:"08",
        chaptername:"Sequences and Series",
        pdf:class11mathsch8
    },{
        class:"11",
        name:"Maths",
        chapter:"09",
        chaptername:"Straight Lines",
        pdf:class11mathsch9
    },{
        class:"11",
        name:"Maths",
        chapter:"10",
        chaptername:"Conic Sections",
        pdf:class11mathsch10
    },{
        class:"11",
        name:"Maths",
        chapter:"11",
        chaptername:"Introduction to Three Dimensional Geometry",
        pdf:class11mathsch11
    },{
        class:"11",
        name:"Maths",
        chapter:"12",
        chaptername:"Limits and Derivatives",
        pdf:class11mathsch12
    },{
        class:"11",
        name:"Maths",
        chapter:"13",
        chaptername:"Statistics",
        pdf:class11mathsch13
    },{
        class:"11",
        name:"Maths",
        chapter:"14",
        chaptername:"Probability",
        pdf:class11mathsch14
    },{
        class:"11",
        name:"Physical Education",
        chapter:"01",
        chaptername:"Physical Education",
        pdf:class11phyeduch1
    },{
        class:"11",
        name:"Physical Education",
        chapter:"02",
        chaptername:"Understanding Health",
        pdf:class11phyeduch2
    },{
        class:"11",
        name:"Physical Education",
        chapter:"03",
        chaptername:"Physical and Physiological Aspects of Physical Education and Sports",
        pdf:class11phyeduch3
    },{
        class:"11",
        name:"Physical Education",
        chapter:"04",
        chaptername:"Individual Games",
        pdf:class11phyeduch4
    },{
        class:"11",
        name:"Physical Education",
        chapter:"05",
        chaptername:"Team Games",
        pdf:class11phyeduch5
    },{
        class:"11",
        name:"Physical Education",
        chapter:"06",
        chaptername:"Yoga and its Relevance in the Modern Times",
        pdf:class11phyeduch6
    },{
        class:"11",
        name:"Physical Education",
        chapter:"07",
        chaptername:"Safety and Security",
        pdf:class11phyeduch7
    },{
        class:"11",
        name:"Physical Education",
        chapter:"08",
        chaptername:"Health Related Physical Fitness",
        pdf:class11phyeduch8
    },{
        class:"11",
        name:"Physical Education",
        chapter:"09",
        chaptername:"Measurement and Evaluation",
        pdf:class11phyeduch9
    },{
        class:"11",
        name:"Physical Education",
        chapter:"10",
        chaptername:"Tournaments and Competitionss",
        pdf:class11phyeduch10
    },{
        class:"11",
        name:"Physical Education",
        chapter:"11",
        chaptername:"Adventure Sports",
        pdf:class11phyeduch11
    },{
        class:"11",
        name:"Physics Part 1",
        chapter:"01",
        chaptername:"Units and Measurement",
        pdf:class11phy1ch1
    },{
        class:"11",
        name:"Physics Part 1",
        chapter:"02",
        chaptername:"Motion in a Straight Line",
        pdf:class11phy1ch2
    },{
        class:"11",
        name:"Physics Part 1",
        chapter:"03",
        chaptername:"Motion in a Plane",
        pdf:class11phy1ch3
    },{
        class:"11",
        name:"Physics Part 1",
        chapter:"04",
        chaptername:"Law of Motion",
        pdf:class11phy1ch4
    },{
        class:"11",
        name:"Physics Part 1",
        chapter:"05",
        chaptername:"Work, Energy and Power",
        pdf:class11phy1ch5
    },{
        class:"11",
        name:"Physics Part 1",
        chapter:"06",
        chaptername:"Systems of Particles and Rotational Motion",
        pdf:class11phy1ch6
    },{
        class:"11",
        name:"Physics Part 1",
        chapter:"07",
        chaptername:"Gravitation",
        pdf:class11phy1ch7
    },{
        class:"11",
        name:"Physics Part 2",
        chapter:"01",
        chaptername:"Mechanical Properties of Solids",
        pdf:class11phy2ch1
    },{
        class:"11",
        name:"Physics Part 2",
        chapter:"02",
        chaptername:"Mechanical Properties of Fluids",
        pdf:class11phy2ch2
    },{
        class:"11",
        name:"Physics Part 2",
        chapter:"03",
        chaptername:"Thermal Properties of Matter",
        pdf:class11phy2ch3
    },{
        class:"11",
        name:"Physics Part 2",
        chapter:"04",
        chaptername:"Thermodynamics",
        pdf:class11phy2ch4
    },{
        class:"11",
        name:"Physics Part 2",
        chapter:"05",
        chaptername:"Kinetic Theory",
        pdf:class11phy2ch5
    },{
        class:"11",
        name:"Physics Part 2",
        chapter:"06",
        chaptername:"Oscillations",
        pdf:class11phy2ch6
    },{
        class:"11",
        name:"Physics Part 2",
        chapter:"07",
        chaptername:"Waves",
        pdf:class11phy2ch7
    },{
        class:"11",
        name:"Political Science",
        chapter:"01",
        chaptername:"Constitution: Why and How?",
        pdf:class11polsciencech1
    },{
        class:"11",
        name:"Political Science",
        chapter:"02",
        chaptername:"Rights in the Indian Constitution",
        pdf:class11polsciencech2
    },{
        class:"11",
        name:"Political Science",
        chapter:"03",
        chaptername:"Election and Representation",
        pdf:class11polsciencech3
    },{
        class:"11",
        name:"Political Science",
        chapter:"04",
        chaptername:"Executive",
        pdf:class11polsciencech4
    },{
        class:"11",
        name:"Political Science",
        chapter:"05",
        chaptername:"Legislature",
        pdf:class11polsciencech5
    },{
        class:"11",
        name:"Political Science",
        chapter:"06",
        chaptername:"Judiciary",
        pdf:class11polsciencech6
    },{
        class:"11",
        name:"Political Science",
        chapter:"07",
        chaptername:"Federalism",
        pdf:class11polsciencech7
    },{
        class:"11",
        name:"Political Science",
        chapter:"08",
        chaptername:"Local Governments",
        pdf:class11polsciencech8
    },{
        class:"11",
        name:"Psycology",
        chapter:"01",
        chaptername:"What is Psychology?",
        pdf:class11pyscologych1
    },{
        class:"11",
        name:"Psycology",
        chapter:"02",
        chaptername:"Methods of Enquiry in Psychology",
        pdf:class11pyscologych2
    },{
        class:"11",
        name:"Psycology",
        chapter:"03",
        chaptername:"Human Development",
        pdf:class11pyscologych3
    },{
        class:"11",
        name:"Psycology",
        chapter:"04",
        chaptername:"Sensory, Attentional and Perceptual Processes",
        pdf:class11pyscologych4
    },{
        class:"11",
        name:"Psycology",
        chapter:"05",
        chaptername:"Learning",
        pdf:class11pyscologych5
    },{
        class:"11",
        name:"Psycology",
        chapter:"06",
        chaptername:"Human Memory",
        pdf:class11pyscologych6
    },{
        class:"11",
        name:"Psycology",
        chapter:"07",
        chaptername:"Thinking",
        pdf:class11pyscologych7
    },{
        class:"11",
        name:"Psycology",
        chapter:"08",
        chaptername:"Motivation and Emotion",
        pdf:class11pyscologych8
    },{
        class:"11",
        name:"Sangeet",
        chapter:"01",
        chaptername:"Introduction",
        pdf:class11sangetch1
    },{
        class:"11",
        name:"Sangeet",
        chapter:"02",
        chaptername:"Cellular Organelles",
        pdf:class11sangetch2
    },{
        class:"11",
        name:"Sangeet",
        chapter:"03",
        chaptername:"Biomolecules",
        pdf:class11sangetch3
    },{
        class:"11",
        name:"Sangeet",
        chapter:"04",
        chaptername:"Enzymes and Bioenergetics",
        pdf:class11sangetch4
    },{
        class:"11",
        name:"Sangeet",
        chapter:"05",
        chaptername:"Cellular Processes",
        pdf:class11sangetch5
    },{
        class:"11",
        name:"Sangeet",
        chapter:"06",
        chaptername:"Basic Principles of Inheritance",
        pdf:class11sangetch6
    },{
        class:"11",
        name:"Sangeet",
        chapter:"07",
        chaptername:"Basic Processes",
        pdf:class11sangetch7
    },{
        class:"11",
        name:"Sangeet",
        chapter:"08",
        chaptername:"Genetic Disorder",
        pdf:class11sangetch8
    },{
        class:"11",
        name:"Snapshot",
        chapter:"01",
        chaptername:"The Summer of the Beautiful White Horse",
        pdf:class11sanpshotch1
    },{
        class:"11",
        name:"Snapshot",
        chapter:"02",
        chaptername:"The Address",
        pdf:class11sanpshotch2
    },{
        class:"11",
        name:"Snapshot",
        chapter:"03",
        chaptername:"Mother’s Day",
        pdf:class11sanpshotch3
    },{
        class:"11",
        name:"Snapshot",
        chapter:"04",
        chaptername:"Birth",
        pdf:class11sanpshotch4
    },{
        class:"11",
        name:"Snapshot",
        chapter:"05",
        chaptername:"The Tale of Melon City Summary",
        pdf:class11sanpshotch5
    },{
        class:"11",
        name:"Sociology",
        chapter:"01",
        chaptername:"Sociology and Society",
        pdf:class11sociologych1
    },{
        class:"11",
        name:"Sociology",
        chapter:"02",
        chaptername:"Terms, Concepts and Their Use in Sociology",
        pdf:class11sociologych2
    },{
        class:"11",
        name:"Sociology",
        chapter:"03",
        chaptername:"Understanding Social Institutions",
        pdf:class11sociologych3
    },{
        class:"11",
        name:"Sociology",
        chapter:"04",
        chaptername:"Culture and Socialisation",
        pdf:class11sociologych4
    },{
        class:"11",
        name:"Sociology",
        chapter:"05",
        chaptername:"Doing Sociology: Research Methods",
        pdf:class11sociologych5
    },{
        class:"11",
        name:"Srijan",
        chapter:"01",
        chaptername:"DoinMethods",
        pdf:class11srijanch1
    },{
        class:"11",
        name:"Srijan",
        chapter:"02",
        chaptername:"Literary Writing",
        pdf:class11srijanch2
    },{
        class:"11",
        name:"Srijan",
        chapter:"03",
        chaptername:"Media",
        pdf:class11srijanch3
    },{
        class:"11",
        name:"Srijan",
        chapter:"04",
        chaptername:"Translation",
        pdf:class11srijanch4
    },{
        class:"11",
        name:"Vitan",
        chapter:"01",
        chaptername:"भारतीय गायिकाओं में बेजोड़ : लता मंगेशकर",
        pdf:class11vitanch1
    },{
        class:"11",
        name:"Vitan",
        chapter:"02",
        chaptername:"राजस्थान की रजत बूँदें",
        pdf:class11vitanch2
    },{
        class:"11",
        name:"Vitan",
        chapter:"03",
        chaptername:"आलो-आँधारि",
        pdf:class11vitanch3
    },{
        class:"11",
        name:"Vitan",
        chapter:"04",
        chaptername:"भारतीय कलाएँ",
        pdf:class11vitanch4
    },{
        class:"11",
        name:"Sanskrit",
        chapter:"01",
        chaptername:"अनुशासनम् ",
        pdf:class11sanskritch1
    },{
        class:"11",
        name:"Sanskrit",
        chapter:"02",
        chaptername:"मातुराज्ञा गरीयसी",
        pdf:class11sanskritch2
    },{
        class:"11",
        name:"Sanskrit",
        chapter:"03",
        chaptername:"प्रजानुरञ्जको नृपः",
        pdf:class11sanskritch3
    },{
        class:"11",
        name:"Sanskrit",
        chapter:"04",
        chaptername:"दौवारिकस्य निष्ठा",
        pdf:class11sanskritch4
    },{
        class:"11",
        name:"Sanskrit",
        chapter:"05",
        chaptername:"सूक्ति-सौरभम्",
        pdf:class11sanskritch5
    },{
        class:"11",
        name:"Sanskrit",
        chapter:"06",
        chaptername:"नैकेनापि समं गता वसुमती",
        pdf:class11sanskritch6
    },{
        class:"11",
        name:"Sanskrit",
        chapter:"07",
        chaptername:"हल्दीघाटी",
        pdf:class11sanskritch7
    },{
        class:"11",
        name:"Sanskrit",
        chapter:"08",
        chaptername:"मदालसा",
        pdf:class11sanskritch8
    },{
        class:"11",
        name:"Sanskrit",
        chapter:"09",
        chaptername:"कार्याकार्यव्यवस्थिति:",
        pdf:class11sanskritch9
    },{
        class:"11",
        name:"Sanskrit",
        chapter:"10",
        chaptername:"विद्यास्थानानि",
        pdf:class11sanskritch10
    },{
        class:"12",
        name:"Accounts 1",
        chapter:"01",
        chaptername:"Accounting for Partnership : Basic Concepts",
        pdf:class12accounts1ch1
    },{
        class:"12",
        name:"Accounts 1",
        chapter:"02",
        chaptername:"Reconstitution of a Partnership Firm – Admission of a Partner",
        pdf:class12accounts1ch2
    },{
        class:"12",
        name:"Accounts 1",
        chapter:"03",
        chaptername:"Reconstitution of a Partnership Firm – Retirement/Death of a Partner",
        pdf:class12accounts1ch3
    },{
        class:"12",
        name:"Accounts 1",
        chapter:"04",
        chaptername:"Dissolution of Partnership Firm",
        pdf:class12accounts1ch4
    },{
        class:"12",
        name:"Accounts 2",
        chapter:"01",
        chaptername:"Accounting for Share Capital",
        pdf:class12accounts2ch1
    },{
        class:"12",
        name:"Accounts 2",
        chapter:"02",
        chaptername:"Issue and Redemption of Debentures",
        pdf:class12accounts2ch2
    },{
        class:"12",
        name:"Accounts 2",
        chapter:"03",
        chaptername:"Financial Statements of a Company",
        pdf:class12accounts2ch3
    },{
        class:"12",
        name:"Accounts 2",
        chapter:"04",
        chaptername:"Analysis of Financial Statements",
        pdf:class12accounts2ch4
    },{
        class:"12",
        name:"Accounts 2",
        chapter:"05",
        chaptername:"Accounting Ratios",
        pdf:class12accounts2ch5
    },{
        class:"12",
        name:"Accounts 2",
        chapter:"06",
        chaptername:"Cash Flow Statement",
        pdf:class12accounts2ch6
    },{
        class:"12",
        name:"Antra",
        chapter:"01",
        chaptername:"देवसेना का गीत",
        pdf:class12antrach1
    },{
        class:"12",
        name:"Antra",
        chapter:"02",
        chaptername:"सरोज स्मृति",
        pdf:class12antrach2
    },{
        class:"12",
        name:"Antra",
        chapter:"03",
        chaptername:"यह दीप अकेला",
        pdf:class12antrach3
    },{
        class:"12",
        name:"Antra",
        chapter:"04",
        chaptername:"बनारस",
        pdf:class12antrach4
    },{
        class:"12",
        name:"Antra",
        chapter:"05",
        chaptername:"वसंत आया",
        pdf:class12antrach5
    },{
        class:"12",
        name:"Antra",
        chapter:"06",
        chaptername:"भरत-राम का प्रेम",
        pdf:class12antrach6
    },{
        class:"12",
        name:"Antra",
        chapter:"07",
        chaptername:"बारहमासा",
        pdf:class12antrach7
    },{
        class:"12",
        name:"Antra",
        chapter:"08",
        chaptername:"पद",
        pdf:class12antrach8
    },{
        class:"12",
        name:"Antra",
        chapter:"09",
        chaptername:"कवित्त",
        pdf:class12antrach9
    },{
        class:"12",
        name:"Antra",
        chapter:"10",
        chaptername:"प्रेमघन की छाया-स्मृति",
        pdf:class12antrach10
    },{
        class:"12",
        name:"Antra",
        chapter:"11",
        chaptername:"सुमिरिनी के मनके",
        pdf:class12antrach11
    },{
        class:"12",
        name:"Antra",
        chapter:"12",
        chaptername:"संवदिया",
        pdf:class12antrach12
    },{
        class:"12",
        name:"Antra",
        chapter:"13",
        chaptername:"गांधी, नेहरू और यास्सेर अराफ़ात",
        pdf:class12antrach13
    },{
        class:"12",
        name:"Antra",
        chapter:"14",
        chaptername:"शेर",
        pdf:class12antrach14
    },{
        class:"12",
        name:"Antra",
        chapter:"15",
        chaptername:"जहाँ कोई वापसी नहीं",
        pdf:class12antrach15
    },{
        class:"12",
        name:"Antra",
        chapter:"16",
        chaptername:"दूसरा देवदास",
        pdf:class12antrach16
    },{
        class:"12",
        name:"Antra",
        chapter:"17",
        chaptername:"कुटज",
        pdf:class12antrach17
    },{
        class:"12",
        name:"Biology",
        chapter:"01",
        chaptername:"Sexual Reproduction in Flowering Plants",
        pdf:class12bioch1
    },{
        class:"12",
        name:"Biology",
        chapter:"02",
        chaptername:"Human Reproduction",
        pdf:class12bioch2
    },{
        class:"12",
        name:"Biology",
        chapter:"03",
        chaptername:"Reproductive Health",
        pdf:class12bioch3
    },{
        class:"12",
        name:"Biology",
        chapter:"04",
        chaptername:"Principles of Inheritance and Variation",
        pdf:class12bioch4
    },{
        class:"12",
        name:"Biology",
        chapter:"05",
        chaptername:"Molecular Basis of Inheritance",
        pdf:class12bioch5
    },{
        class:"12",
        name:"Biology",
        chapter:"06",
        chaptername:"Evolution",
        pdf:class12bioch6
    },{
        class:"12",
        name:"Biology",
        chapter:"07",
        chaptername:"Human Health and Disease",
        pdf:class12bioch7
    },{
        class:"12",
        name:"Biology",
        chapter:"08",
        chaptername:"Microbes in Human Welfare",
        pdf:class12bioch8
    },{
        class:"12",
        name:"Biology",
        chapter:"09",
        chaptername:"Biotechnology: Principles and Processes",
        pdf:class12bioch9
    },{
        class:"12",
        name:"Biology",
        chapter:"10",
        chaptername:"Biotechnology and its Applications",
        pdf:class12bioch10
    },{
        class:"12",
        name:"Biology",
        chapter:"11",
        chaptername:"Organisms and Populations",
        pdf:class12bioch11
    },{
        class:"12",
        name:"Biology",
        chapter:"12",
        chaptername:"Ecosystem",
        pdf:class12bioch12
    },{
        class:"12",
        name:"Biology",
        chapter:"13",
        chaptername:"Biodiversity and Conservation",
        pdf:class12bioch13
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"01",
        chaptername:"Recombinant DNA Technology",
        pdf:class12biotechnoch1
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"02",
        chaptername:"Host–Vector System",
        pdf:class12biotechnoch2
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"03",
        chaptername:"Gene Cloning",
        pdf:class12biotechnoch3
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"04",
        chaptername:"Applications of Recombinant DNA Technology",
        pdf:class12biotechnoch4
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"05",
        chaptername:"Genome Technology and Engineering",
        pdf:class12biotechnoch5
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"06",
        chaptername:"Microbial Culture",
        pdf:class12biotechnoch6
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"07",
        chaptername:"Plant Tissue Culture",
        pdf:class12biotechnoch7
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"08",
        chaptername:"Animal Cell Culture",
        pdf:class12biotechnoch8
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"09",
        chaptername:"Stem Cell Culture and Organ Culture",
        pdf:class12biotechnoch9
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"10",
        chaptername:"Bioprocessing and Biomanufacturing",
        pdf:class12biotechnoch10
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"11",
        chaptername:"Bioremediation",
        pdf:class12biotechnoch11
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"12",
        chaptername:"Recent Innovations in Biotechnology",
        pdf:class12biotechnoch12
    },{
        class:"12",
        name:"Biotechnology",
        chapter:"13",
        chaptername:"Entrepreneurship",
        pdf:class12biotechnoch13
    },{
        class:"12",
        name:"Business Studies 1",
        chapter:"01",
        chaptername:"Nature and Significance of Management",
        pdf:class12bussstud1ch1
    },{
        class:"12",
        name:"Business Studies 1",
        chapter:"02",
        chaptername:"Principles of Management",
        pdf:class12bussstud1ch2
    },{
        class:"12",
        name:"Business Studies 1",
        chapter:"03",
        chaptername:"Business Environment",
        pdf:class12bussstud1ch3
    },{
        class:"12",
        name:"Business Studies 1",
        chapter:"04",
        chaptername:"Planning",
        pdf:class12bussstud1ch4
    },{
        class:"12",
        name:"Business Studies 1",
        chapter:"05",
        chaptername:"Organising",
        pdf:class12bussstud1ch5
    },{
        class:"12",
        name:"Business Studies 1",
        chapter:"06",
        chaptername:"Staffing",
        pdf:class12bussstud1ch6
    },{
        class:"12",
        name:"Business Studies 1",
        chapter:"07",
        chaptername:"Directing",
        pdf:class12bussstud1ch7
    },{
        class:"12",
        name:"Business Studies 1",
        chapter:"08",
        chaptername:"Controlling",
        pdf:class12bussstud1ch8
    },{
        class:"12",
        name:"Business Studies 2",
        chapter:"01",
        chaptername:"Financial Management",
        pdf:class12bussstud2ch1
    },{
        class:"12",
        name:"Business Studies 2",
        chapter:"02",
        chaptername:"Marketing",
        pdf:class12bussstud2ch2
    },{
        class:"12",
        name:"Business Studies 2",
        chapter:"03",
        chaptername:"Consumer Protection",
        pdf:class12bussstud2ch3
    },{
        class:"12",
        name:"Chemistry 1",
        chapter:"01",
        chaptername:"Solutions",
        pdf:class12chemistry1ch1
    },{
        class:"12",
        name:"Chemistry 1",
        chapter:"02",
        chaptername:"Electrochemistry",
        pdf:class12chemistry1ch2
    },{
        class:"12",
        name:"Chemistry 1",
        chapter:"03",
        chaptername:"Chemical Kinetics",
        pdf:class12chemistry1ch3
    },{
        class:"12",
        name:"Chemistry 1",
        chapter:"04",
        chaptername:"d -and f -Block Elements",
        pdf:class12chemistry1ch4
    },{
        class:"12",
        name:"Chemistry 1",
        chapter:"05",
        chaptername:"Coordination Compounds",
        pdf:class12chemistry1ch5
    },{
        class:"12",
        name:"Chemistry 2",
        chapter:"01",
        chaptername:"Haloalkanes and Haloarenes",
        pdf:class12chemistry2ch1
    },{
        class:"12",
        name:"Chemistry 2",
        chapter:"02",
        chaptername:"Alcohols, Phenols and Ethers",
        pdf:class12chemistry2ch2
    },{
        class:"12",
        name:"Chemistry 2",
        chapter:"03",
        chaptername:"Aldehydes, Ketones and Carboxylic Acids",
        pdf:class12chemistry2ch3
    },{
        class:"12",
        name:"Chemistry 2",
        chapter:"04",
        chaptername:"Amines",
        pdf:class12chemistry2ch4
    },{
        class:"12",
        name:"Chemistry 2",
        chapter:"05",
        chaptername:"Biomolecules",
        pdf:class12chemistry2ch5
    },{
        class:"12",
        name:"Computer Science",
        chapter:"01",
        chaptername:"Exception Handling in Python",
        pdf:class12csch1
    },{
        class:"12",
        name:"Computer Science",
        chapter:"02",
        chaptername:"File Handling in Python",
        pdf:class12csch2
    },{
        class:"12",
        name:"Computer Science",
        chapter:"03",
        chaptername:"Stack",
        pdf:class12csch3
    },{
        class:"12",
        name:"Computer Science",
        chapter:"04",
        chaptername:"Queue",
        pdf:class12csch4
    },{
        class:"12",
        name:"Computer Science",
        chapter:"05",
        chaptername:"Sorting",
        pdf:class12csch5
    },{
        class:"12",
        name:"Computer Science",
        chapter:"06",
        chaptername:"Searching",
        pdf:class12csch6
    },{
        class:"12",
        name:"Computer Science",
        chapter:"07",
        chaptername:"Understanding Data",
        pdf:class12csch7
    },{
        class:"12",
        name:"Computer Science",
        chapter:"08",
        chaptername:"Database Concepts",
        pdf:class12csch8
    },{
        class:"12",
        name:"Computer Science",
        chapter:"09",
        chaptername:"Structured Query Language (SQL)",
        pdf:class12csch9
    },{
        class:"12",
        name:"Computer Science",
        chapter:"10",
        chaptername:"Computer Networks",
        pdf:class12csch10
    },{
        class:"12",
        name:"Computer Science",
        chapter:"11",
        chaptername:"Data Communication",
        pdf:class12csch11
    },{
        class:"12",
        name:"Computer Science",
        chapter:"12",
        chaptername:"Security Aspects",
        pdf:class12csch12
    },{
        class:"12",
        name:"Computer Science",
        chapter:"13",
        chaptername:"Project Based Learning",
        pdf:class12csch13
    },{
        class:"12",
        name:"Economics 1",
        chapter:"01",
        chaptername:"Introduction",
        pdf:class12eco1ch1
    },{
        class:"12",
        name:"Economics 1",
        chapter:"02",
        chaptername:"Theory of Customer Behaviour",
        pdf:class12eco1ch2
    },{
        class:"12",
        name:"Economics 1",
        chapter:"03",
        chaptername:"Production and Costs",
        pdf:class12eco1ch3
    },{
        class:"12",
        name:"Economics 1",
        chapter:"04",
        chaptername:"The Theory of the Firm under Perfect Competiton",
        pdf:class12eco1ch4
    },{
        class:"12",
        name:"Economics 1",
        chapter:"05",
        chaptername:"Market Equilibrium",
        pdf:class12eco1ch5
    },{
        class:"12",
        name:"Economics 2",
        chapter:"01",
        chaptername:"Introduction",
        pdf:class12eco2ch1
    },{
        class:"12",
        name:"Economics 2",
        chapter:"02",
        chaptername:"National Income Accounting",
        pdf:class12eco2ch2
    },{
        class:"12",
        name:"Economics 2",
        chapter:"03",
        chaptername:"Money and Banking",
        pdf:class12eco2ch3
    },{
        class:"12",
        name:"Economics 2",
        chapter:"04",
        chaptername:"Determination of Income and Employment",
        pdf:class12eco2ch4
    },{
        class:"12",
        name:"Economics 2",
        chapter:"05",
        chaptername:"Government Budget and the Economy",
        pdf:class12eco2ch5
    },{
        class:"12",
        name:"Economics 2",
        chapter:"06",
        chaptername:"Open Economy Macroeconomics",
        pdf:class12eco2ch6
    },{
        class:"12",
        name:"Fineart",
        chapter:"01",
        chaptername:"The Manuscript Painting Tradition",
        pdf:class12fineartch1
    },{
        class:"12",
        name:"Fineart",
        chapter:"02",
        chaptername:"The Rajasthani Schools of Painting",
        pdf:class12fineartch2
    },{
        class:"12",
        name:"Fineart",
        chapter:"03",
        chaptername:"The Mughal School of Miniature Painting",
        pdf:class12fineartch3
    },{
        class:"12",
        name:"Fineart",
        chapter:"04",
        chaptername:"The Deccani Schools of Painting",
        pdf:class12fineartch4
    },{
        class:"12",
        name:"Fineart",
        chapter:"05",
        chaptername:"The Pahari Schools of Painting",
        pdf:class12fineartch5
    },{
        class:"12",
        name:"Fineart",
        chapter:"06",
        chaptername:"The Bengal School and Cultural Nationalism",
        pdf:class12fineartch6
    },{
        class:"12",
        name:"Fineart",
        chapter:"07",
        chaptername:"The Modern Indian Art",
        pdf:class12fineartch7
    },{
        class:"12",
        name:"Fineart",
        chapter:"08",
        chaptername:"The Living Art Traditions of India",
        pdf:class12fineartch8
    },{
        class:"12",
        name:"Flamingo",
        chapter:"01",
        chaptername:"The Last Lesson",
        pdf:class12flamingoch1
    },{
        class:"12",
        name:"Flamingo",
        chapter:"02",
        chaptername:"Lost Spring",
        pdf:class12flamingoch2
    },{
        class:"12",
        name:"Flamingo",
        chapter:"03",
        chaptername:"Deep Water",
        pdf:class12flamingoch3
    },{
        class:"12",
        name:"Flamingo",
        chapter:"04",
        chaptername:"The Rattrap",
        pdf:class12flamingoch4
    },{
        class:"12",
        name:"Flamingo",
        chapter:"05",
        chaptername:"Indigo",
        pdf:class12flamingoch5
    },{
        class:"12",
        name:"Flamingo",
        chapter:"06",
        chaptername:"Poets and Pancakes",
        pdf:class12flamingoch6
    },{
        class:"12",
        name:"Flamingo",
        chapter:"07",
        chaptername:"The Interview",
        pdf:class12flamingoch7
    },{
        class:"12",
        name:"Flamingo",
        chapter:"08",
        chaptername:"Going Places",
        pdf:class12flamingoch8
    },{
        class:"12",
        name:"Flamingo",
        chapter:"09",
        chaptername:"My Mother at Sixty-Six",
        pdf:class12flamingoch9
    },{
        class:"12",
        name:"Flamingo",
        chapter:"10",
        chaptername:"Keeping Quiet",
        pdf:class12flamingoch10
    },{
        class:"12",
        name:"Flamingo",
        chapter:"11",
        chaptername:"A Thing of Beauty",
        pdf:class12flamingoch11
    },{
        class:"12",
        name:"Flamingo",
        chapter:"12",
        chaptername:"A Roadside Stand",
        pdf:class12flamingoch12
    },{
        class:"12",
        name:"Flamingo",
        chapter:"13",
        chaptername:"Aunt Jennifer’s Tigers",
        pdf:class12flamingoch13
    },{
        class:"12",
        name:"Geography",
        chapter:"01",
        chaptername:"Human Geography",
        pdf:class12geoch1
    },{
        class:"12",
        name:"Geography",
        chapter:"02",
        chaptername:"The World Population Density Distribution and Growth",
        pdf:class12geoch2
    },{
        class:"12",
        name:"Geography",
        chapter:"03",
        chaptername:"Human Development",
        pdf:class12geoch3
    },{
        class:"12",
        name:"Geography",
        chapter:"04",
        chaptername:"Primary Activities",
        pdf:class12geoch4
    },{
        class:"12",
        name:"Geography",
        chapter:"05",
        chaptername:"Secondary Activities",
        pdf:class12geoch5
    },{
        class:"12",
        name:"Geography",
        chapter:"06",
        chaptername:"Tertiary and Quaternary Activities",
        pdf:class12geoch6
    },{
        class:"12",
        name:"Geography",
        chapter:"07",
        chaptername:"Transport, Communication and Trade",
        pdf:class12geoch7
    },{
        class:"12",
        name:"Geography",
        chapter:"08",
        chaptername:"International Trade",
        pdf:class12geoch8
    },{
        class:"12",
        name:"History 1",
        chapter:"01",
        chaptername:"Bricks, Beads and Bones The Harappa Civilisation",
        pdf:class12history1ch1
    },{
        class:"12",
        name:"History 1",
        chapter:"02",
        chaptername:"Kings, Farmers and Towns Early States and Economies",
        pdf:class12history1ch2
    },{
        class:"12",
        name:"History 1",
        chapter:"03",
        chaptername:"Kingship, Caste and class Early Societies",
        pdf:class12history1ch3
    },{
        class:"12",
        name:"History 1",
        chapter:"04",
        chaptername:"Thinkers, Beliefs and Buildings Cultural Developments",
        pdf:class12history1ch4
    },{
        class:"12",
        name:"History 2",
        chapter:"01",
        chaptername:"Through the eyes of Travellers Perceptions of Society",
        pdf:class12history2ch1
    },{
        class:"12",
        name:"History 2",
        chapter:"02",
        chaptername:"Bhakti-Sufi Traditions Changes in Religious Beliefs and Devotional Texts",
        pdf:class12history2ch2
    },{
        class:"12",
        name:"History 2",
        chapter:"03",
        chaptername:"An Imperial Capital – Vijayanagar",
        pdf:class12history2ch3
    },{
        class:"12",
        name:"History 2",
        chapter:"04",
        chaptername:"Peasants, zamindars and the States Agrarian Society and the Mughal Empire",
        pdf:class12history2ch4
    },{
        class:"12",
        name:"Human Studies 1",
        chapter:"01",
        chaptername:"Work, Livelihood and Career",
        pdf:class12humanstdy1ch1
    },{
        class:"12",
        name:"Human Studies 1",
        chapter:"02",
        chaptername:"Clinical Nutrition and Dietetics",
        pdf:class12humanstdy1ch2
    },{
        class:"12",
        name:"Human Studies 1",
        chapter:"03",
        chaptername:"Public Nutrition And Health",
        pdf:class12humanstdy1ch3
    },{
        class:"12",
        name:"Human Studies 1",
        chapter:"04",
        chaptername:"Food Processing And Technology",
        pdf:class12humanstdy1ch4
    },{
        class:"12",
        name:"Human Studies 1",
        chapter:"05",
        chaptername:"Food Quality and Food Safety",
        pdf:class12humanstdy1ch5
    },{
        class:"12",
        name:"Human Studies 1",
        chapter:"06",
        chaptername:"Early Childhood Care and Education",
        pdf:class12humanstdy1ch6
    },{
        class:"12",
        name:"Human Studies 1",
        chapter:"07",
        chaptername:"Management of Support Services, Institutions and Programmes for Children, Youth and Elderly",
        pdf:class12humanstdy1ch7
    },{
        class:"12",
        name:"Human Studies 2",
        chapter:"01",
        chaptername:"FABRIC AND APPAREL",
        pdf:class12humanstdy2ch1
    },{
        class:"12",
        name:"Human Studies 2",
        chapter:"02",
        chaptername:"Fashion Design and Merchandising",
        pdf:class12humanstdy2ch2
    },{
        class:"12",
        name:"Human Studies 2",
        chapter:"03",
        chaptername:"Care and Maintenance of Fabrics in Institutions",
        pdf:class12humanstdy2ch3
    },{
        class:"12",
        name:"Human Studies 2",
        chapter:"04",
        chaptername:"Hospitality Management",
        pdf:class12humanstdy2ch4
    },{
        class:"12",
        name:"Human Studies 2",
        chapter:"05",
        chaptername:"Consumer Education and Protection",
        pdf:class12humanstdy2ch5
    },{
        class:"12",
        name:"Human Studies 2",
        chapter:"06",
        chaptername:"Development Communication And Journalism",
        pdf:class12humanstdy2ch6
    },{
        class:"12",
        name:"Human Studies 2",
        chapter:"07",
        chaptername:"Corporate Communication And Public Relations",
        pdf:class12humanstdy2ch7
    },{
        class:"12",
        name:"Information Technology",
        chapter:"01",
        chaptername:"Querying and SQL Functions",
        pdf:class12itch1
    },{
        class:"12",
        name:"Information Technology",
        chapter:"02",
        chaptername:"Data Handling Using Pandas - I",
        pdf:class12itch2
    },{
        class:"12",
        name:"Information Technology",
        chapter:"03",
        chaptername:"Data Handling Using Pandas - II",
        pdf:class12itch3
    },{
        class:"12",
        name:"Information Technology",
        chapter:"04",
        chaptername:"Plotting Data using Matplotlib",
        pdf:class12itch4
    },{
        class:"12",
        name:"Information Technology",
        chapter:"05",
        chaptername:"Internet and Web",
        pdf:class12itch5
    },{
        class:"12",
        name:"Information Technology",
        chapter:"06",
        chaptername:"Societal Impacts",
        pdf:class12itch6
    },{
        class:"12",
        name:"Information Technology",
        chapter:"07",
        chaptername:"Project Based Learning",
        pdf:class12itch7
    },{
        class:"12",
        name:"Maths 1",
        chapter:"01",
        chaptername:"Projecarning",
        pdf:class12maths1ch1
    },{
        class:"12",
        name:"Maths 1",
        chapter:"01",
        chaptername:"Relations and Functions",
        pdf:class12maths1ch1
    },{
        class:"12",
        name:"Maths 1",
        chapter:"02",
        chaptername:"Inverse Trigonometric Functions",
        pdf:class12maths1ch2
    },{
        class:"12",
        name:"Maths 1",
        chapter:"03",
        chaptername:"Matrices",
        pdf:class12maths1ch3
    },{
        class:"12",
        name:"Maths 1",
        chapter:"04",
        chaptername:"Determinants",
        pdf:class12maths1ch4
    },{
        class:"12",
        name:"Maths 1",
        chapter:"05",
        chaptername:"Continuity and Differentiability",
        pdf:class12maths1ch5
    },{
        class:"12",
        name:"Maths 1",
        chapter:"06",
        chaptername:"Applications of Derivatives",
        pdf:class12maths1ch6
    },{
        class:"12",
        name:"Maths 2",
        chapter:"01",
        chaptername:"Integrals",
        pdf:class12maths2ch1
    },{
        class:"12",
        name:"Maths 2",
        chapter:"02",
        chaptername:"Applications of the Integrals",
        pdf:class12maths2ch2
    },{
        class:"12",
        name:"Maths 2",
        chapter:"03",
        chaptername:"Differential Equations",
        pdf:class12maths2ch3
    },{
        class:"12",
        name:"Maths 2",
        chapter:"04",
        chaptername:"Vectors",
        pdf:class12maths2ch4
    },{
        class:"12",
        name:"Maths 2",
        chapter:"05",
        chaptername:"Three - dimensional Geometry",
        pdf:class12maths2ch5
    },{
        class:"12",
        name:"Maths 2",
        chapter:"06",
        chaptername:"Linear Programming",
        pdf:class12maths2ch6
    },{
        class:"12",
        name:"Maths 2",
        chapter:"07",
        chaptername:"Probability",
        pdf:class12maths2ch7
    },{
        class:"12",
        name:"Physics 1",
        chapter:"01",
        chaptername:"Electric Charges and Fields",
        pdf:class12physics1ch1
    },{
        class:"12",
        name:"Physics 1",
        chapter:"02",
        chaptername:"Electrostatic Potential and Capacitance",
        pdf:class12physics1ch2
    },{
        class:"12",
        name:"Physics 1",
        chapter:"03",
        chaptername:"Current Electricity",
        pdf:class12physics1ch3
    },{
        class:"12",
        name:"Physics 1",
        chapter:"04",
        chaptername:"Moving Charges and Magnetism",
        pdf:class12physics1ch4
    },{
        class:"12",
        name:"Physics 1",
        chapter:"05",
        chaptername:"Magnetism and Matter",
        pdf:class12physics1ch5
    },{
        class:"12",
        name:"Physics 1",
        chapter:"06",
        chaptername:"Electromagnetic Induction",
        pdf:class12physics1ch6
    },{
        class:"12",
        name:"Physics 1",
        chapter:"07",
        chaptername:"Alternating Current",
        pdf:class12physics1ch7
    },{
        class:"12",
        name:"Physics 1",
        chapter:"08",
        chaptername:"Electromagnetic Waves",
        pdf:class12physics1ch8
    },{
        class:"12",
        name:"Physics 2",
        chapter:"01",
        chaptername:"Ray Optics and Optical Instruments",
        pdf:class12physics2ch1
    },{
        class:"12",
        name:"Physics 2",
        chapter:"02",
        chaptername:"Wave Optics",
        pdf:class12physics2ch2
    },{
        class:"12",
        name:"Physics 2",
        chapter:"03",
        chaptername:"Dual Nature of Radiation and Matter",
        pdf:class12physics2ch3
    },{
        class:"12",
        name:"Physics 2",
        chapter:"04",
        chaptername:"Atoms",
        pdf:class12physics2ch4
    },{
        class:"12",
        name:"Physics 2",
        chapter:"05",
        chaptername:"Nuclei",
        pdf:class12physics2ch5
    },{
        class:"12",
        name:"Physics 2",
        chapter:"06",
        chaptername:"Semiconductor Electronics: Materials, Devices and Simple Circuits",
        pdf:class12physics2ch6
    },{
        class:"12",
        name:"Political Science",
        chapter:"01",
        chaptername:"Constitution: Why and How?",
        pdf:class12polsciencech1
    },{
        class:"12",
        name:"Political Science",
        chapter:"02",
        chaptername:"Rights in the Indian Constitution",
        pdf:class12polsciencech2
    },{
        class:"12",
        name:"Political Science",
        chapter:"03",
        chaptername:"Election and Representation",
        pdf:class12polsciencech3
    },{
        class:"12",
        name:"Political Science",
        chapter:"04",
        chaptername:"Executive",
        pdf:class12polsciencech4
    },{
        class:"12",
        name:"Political Science",
        chapter:"05",
        chaptername:"Legislature",
        pdf:class12polsciencech5
    },{
        class:"12",
        name:"Political Science",
        chapter:"06",
        chaptername:"Judiciary",
        pdf:class12polsciencech6
    },{
        class:"12",
        name:"Political Science",
        chapter:"07",
        chaptername:"Federalism",
        pdf:class12polsciencech7
    },{
        class:"12",
        name:"Psycology",
        chapter:"01",
        chaptername:"VARIATIONS IN PSYCHOLOGICAL ATTRIBUTES TRIBUTES",
        pdf:class12psycoch1
    },{
        class:"12",
        name:"Psycology",
        chapter:"02",
        chaptername:"SELF AND PERSONALITY",
        pdf:class12psycoch2
    },{
        class:"12",
        name:"Psycology",
        chapter:"03",
        chaptername:"MEETING LIFE CHALLENGES",
        pdf:class12psycoch3
    },{
        class:"12",
        name:"Psycology",
        chapter:"04",
        chaptername:"PSYCHOLOGICAL DISORDERS",
        pdf:class12psycoch4
    },{
        class:"12",
        name:"Psycology",
        chapter:"05",
        chaptername:"THERAPEUTIC APPROACHES",
        pdf:class12psycoch5
    },{
        class:"12",
        name:"Psycology",
        chapter:"06",
        chaptername:"ATTITUDE AND SOCIAL COGNITION",
        pdf:class12psycoch6
    },{
        class:"12",
        name:"Psycology",
        chapter:"07",
        chaptername:"SOCIAL INFLUENCE AND GROUP PROCESSES",
        pdf:class12psycoch7
    },{
        class:"12",
        name:"Sangeet",
        chapter:"01",
        chaptername:"ताल की अवधारणा तथा संगीत में इसका महत",
        pdf:class12sangeetch1
    },{
        class:"12",
        name:"Sangeet",
        chapter:"02",
        chaptername:"संगीत लिपि पद्धति का स �क्षिप्‍त इतिहास",
        pdf:class12sangeetch2
    },{
        class:"12",
        name:"Sangeet",
        chapter:"03",
        chaptername:"तबला और पखावज के स्‍वतंत्र वादन में बं�दिशों का महत्‍",
        pdf:class12sangeetch3
    },{
        class:"12",
        name:"Sangeet",
        chapter:"04",
        chaptername:"विभिन् न वाद्यों का परिचय",
        pdf:class12sangeetch4
    },{
        class:"12",
        name:"Sangeet",
        chapter:"05",
        chaptername:"तबला एव पखावज वाद्य की उत्पत्ति तथा विकास",
        pdf:class12sangeetch5
    },{
        class:"12",
        name:"Sangeet",
        chapter:"06",
        chaptername:"कर्नाटक ताल–लिपि पद्धति की अवधारणा",
        pdf:class12sangeetch6
    },{
        class:"12",
        name:"Sangeet",
        chapter:"07",
        chaptername:"जीवन परिचय",
        pdf:class12sangeetch7
    },{
        class:"12",
        name:"Sociology",
        chapter:"01",
        chaptername:"Introducing Indian Society",
        pdf:class12sociologych1
    },{
        class:"12",
        name:"Sociology",
        chapter:"02",
        chaptername:"The Demographic Structure of the Indian Society",
        pdf:class12sociologych2
    },{
        class:"12",
        name:"Sociology",
        chapter:"03",
        chaptername:"Social Institutions: Continuity and Change",
        pdf:class12sociologych3
    },{
        class:"12",
        name:"Sociology",
        chapter:"04",
        chaptername:"The Market as a Social Institution",
        pdf:class12sociologych4
    },{
        class:"12",
        name:"Sociology",
        chapter:"05",
        chaptername:"Patterns of Social Inequality and Exclusion",
        pdf:class12sociologych5
    },{
        class:"12",
        name:"Sociology",
        chapter:"06",
        chaptername:"The Challenges of Cultural Diversity",
        pdf:class12sociologych6
    },{
        class:"12",
        name:"Sociology",
        chapter:"07",
        chaptername:"Suggestions for Project Work",
        pdf:class12sociologych7
    },{
        class:"12",
        name:"Srijan",
        chapter:"01",
        chaptername:"Literary Writing",
        pdf:class12srijanch1
    },{
        class:"12",
        name:"Srijan",
        chapter:"02",
        chaptername:"Media Writing",
        pdf:class12srijanch2
    },{
        class:"12",
        name:"Srijan",
        chapter:"03",
        chaptername:"The Process of Translation",
        pdf:class12srijanch3
    },{
        class:"12",
        name:"Srijan",
        chapter:"04",
        chaptername:"Editing",
        pdf:class12srijanch4
    },{
        class:"12",
        name:"Vistas",
        chapter:"01",
        chaptername:"The Third Level",
        pdf:class12vistasch1
    },{
        class:"12",
        name:"Vistas",
        chapter:"02",
        chaptername:"The Tiger King",
        pdf:class12vistasch2
    },{
        class:"12",
        name:"Vistas",
        chapter:"03",
        chaptername:"Journey to the end of the Earth",
        pdf:class12vistasch3
    },{
        class:"12",
        name:"Vistas",
        chapter:"04",
        chaptername:"The Enemy",
        pdf:class12vistasch4
    },{
        class:"12",
        name:"Vistas",
        chapter:"05",
        chaptername:"On The Face Of It",
        pdf:class12vistasch5
    },{
        class:"12",
        name:"Vistas",
        chapter:"06",
        chaptername:"Memories of Childhood",
        pdf:class12vistasch6
    },{
        class:"12",
        name:"Vitan",
        chapter:"01",
        chaptername:"भारतीय गायिकाओं में बेजोड़ : लता मंगेशकर",
        pdf:class12vitanch1
    },{
        class:"12",
        name:"Vitan",
        chapter:"02",
        chaptername:"राजस्थान की रजत बूँदें",
        pdf:class12vitanch2
    },{
        class:"12",
        name:"Vitan",
        chapter:"03",
        chaptername:"आलो-आँधारि",
        pdf:class12vitanch3
    }
    
    

]
export default all_chapters;