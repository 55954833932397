import React, { createContext } from "react";
import all_books from "../Assets/allbooks";
export const Bookcontext= createContext(null);

const BookcontextProvider =(props)=>{

    const contextvalue = {all_books};
     return(
        <Bookcontext.Provider value={contextvalue}>
            {props.children}
        </Bookcontext.Provider>
     )
}
export default BookcontextProvider;