import React from 'react'
import './Sryinconv.css'
import clickhere from '../../Assets/clickhere.webp'
// import all_chapters from '../../Assets/allchapters'
const Sryinconv = () => {
    // const fetchdata=(value)=>{
    //   console.log(all_chapters.filter((user)=>{
    //     return user && user.class && user.class.includes(value);
    //   }))
    // }
   
  return (
    <div>
        <div class="srymsgDIV">
        <p class="srymsg">  SORRY FOR INCONVENIENCE </p>
        <p class="srymessage">
            <br/>this content has not been uploaded yet, 
            <br/>we will definitely upload it soon.
            <br/>To get daily updates about the uploaded content.
        </p>
        <a href="https://www.instagram.com/saatvikstudies/" target="_blank"><img src={clickhere} alt=""/></a>
        </div>
    </div>
  )
}

export default Sryinconv