import React from "react";
import "./Register.css";
import sslogo from "../Assets/saatvik logo.png";
import { Link } from "react-router-dom";
const Register = () => {
  const [result, setResult] = React.useState("");

  const onSubmitre = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "dcb25ac1-c962-4fa9-bebe-b77988c2a08b");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
  async function created(){
    await delay(300);
    alert("Account Created Successfully")
  }
  return (
    <div>
      <div className="register">
        <div className="regis">
          <img src={sslogo} alt="" className="sslogoregister" />
          <p className="heading">Registeration Form</p>
          <form className="form" onSubmit={onSubmitre}>
            <label>First Name</label>
            <input type="text" name="First Name" placeholder="First name " />
            <label>Last Name</label>
            <input type="text" name="Second Name" placeholder="Second name" />
            <label>Class</label>
            <select name="Class" className="select">
              <option value="">--Please choose your class--</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <label>Email</label>
            <input
              className="email"
              name="Email"
              type="email"
              placeholder="Email"
            />
            <label>Phone Number</label>
            <input name="Phone Number" type="tel" placeholder="Phone number" />
            <div className="btn_create">
              <button className="createaccount" type="submit" onClick={created}>
                Create Account
              </button>
            </div>
          </form>
          <div className="divider">
            <hr />
            <p>Or continue with</p>
            <hr />
          </div>
          <p className="register_link">
            Already have an account?{" "}
            <Link style={{ textDecoration: "none" }} to={"/login"}>
              Sign In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
