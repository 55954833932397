import class1maths from './class 1/ncert class 1 maths.jpg';
import class1english from './class 1/ncert class 1 english.jpg';
import class1hindi from './class 1/ncert class 1 hindi.jpg';
import class2maths from './class 2/ncert class 2 maths.jpg';
import class2hindi from './class 2/ncert class 2 hindi.jpg';
import class2english from './class 2/ncert class 2 english.jpg';
import class3maths from './class 3/ncert class 3 maths book.jpg';
import class3english from './class 3/ncert class 3 english book.jpg';
import class3hindi from './class 3/ncert class 3 hindi book.jpg';
import class4hindi from './class 4/Ncert class 4 hindi.jpg';
import class4english from './class 4/Ncert class 4 english.jpg';
import class4maths from './class 4/Ncert class 4 maths.jpg';
import class4evs from './class 5/Ncert class 5 evs.jpg';
import class5maths from './class 5/Ncert class 5 maths.jpg';
import class5hindi from './class 5/Ncert class 5 hindi.jpg';
import class5english from './class 5/Ncert class 5 english.jpg';
import class5evs from './class 5/Ncert class 5 evs.jpg';
import class6english from './class 6/class 6 Ncert book english.jpg';
import class6hindi from './class 6/class 6 Ncert book.jpg';
import class6science from './class 6/class 6 Ncert book science.jpg';
import class6sanskrti from './class 6/class 6 Ncert book sanskrit.jpg';
import class6sst from './class 6/class 6 Ncert book sst.jpg';
import class7maths from './class 7/Ncert class 7 maths.jpg';
import class7science from './class 7/Ncert class 7 science.jpg';
import class7civics from './class 7/Ncert class 7 civics.jpg';
import class7geo from './class 7/Ncert class 7 geo.jpg';
import class7history from './class 7/Ncert class 7 history.jpg';
import class7english1 from './class 7/Ncert class 7 english honeycomb.jpg';
import class7english2 from './class 7/Ncert class 7 english an alien hand.jpg';
import class7hindi1 from './class 7/Ncert class 7 vasant.jpg';
import class7hindi2 from './class 7/Ncert class 7 mahabharat.jpg';
import class7sanskrit from './class 7/Ncert class 7 sanskrit.jpg';
import class8maths from './class 8/Ncert class 8 maths.jpg';
import class8science from './class 8/Ncert class 8 science.jpg';
import class8civics from './class 8/Ncert class 8 civics.jpg';
import class8geo from './class 8/Ncert class 8 geo.jpg';
import class8history from './class 8/Ncert class 8 history.jpg';
import class8english1 from './class 8/Ncert class 8 honeydew.jpg';
import class8english2 from './class 8/Ncert class 8 so happened.jpg';
import class8hindi1 from './class 8/Ncert class 8 vasant.jpg';
import class8hindi2 from './class 8/Ncert class 8 bharat ki khoj.jpg';
import class9maths from './class 9/Ncert class 9 maths.jpg';
import class9science from './class 9/Ncert class 9 science.jpg';
import class9civics from './class 9/Ncert class 9 civics.jpg';
import class9geo from './class 9/Ncert class 9 geo.jpg';
import class9eco from './class 9/Ncert class 9 ec.jpg';
import class9history from './class 9/Ncert class 9 history.jpg';
import class9english1 from './class 9/Ncert class 9 beehive.jpg';
import class9english2 from './class 9/Ncert class 9 momentssup.jpg';
import class9hindi1 from './class 9/Ncert class 9 shitiz.jpg';
import class9hindi2 from './class 9/Ncert class 9 kritika.jpg';
import class9sanskrit from './class 9/Ncert class 9 sanskrit.jpg';
import class10maths from './class 10/Ncert class 10 maths.jpg';
import class10science from './class 10/Ncert class 10 science.jpg';
import class10civics from './class 10/Ncert class 10 civics.jpg';
import class10geo from './class 10/Ncert class 10 geo.jpg';
import class10eco from './class 10/Ncert class 10 eco.jpg';
import class10history from './class 10/Ncert class 10 history.jpg';
import class10english1 from './class 10/Ncert class 10 footprint.jpg';
import class10english2 from './class 10/Ncert class 10 firstflight.jpg';
import class10hindi1 from './class 10/Ncert class 10 kritika.jpg';
import class10hindi2 from './class 10/Ncert class 10 shitiz.jpg';
import class10sanskrit from './class 10/Ncert class 10 sanskrit.jpg';
import class11maths from './class 11/Ncert class 11 maths.jpg';
import class11physics1 from './class 11/Ncert class 11 physics.jpg';
import class11physics2 from './class 11/Ncert class 11 physics 2.jpg';
import class11chemistry1 from './class 11/Ncert class 11 chemistry.jpg';
import class11chemsirty2 from './class 11/Ncert class 11 chem 2.jpg';
import class11hornbill from './class 11/Ncert class 11 hornbell.jpg';
import class11snapshot from './class 11/Ncert class 11 snapshot.jpg';
import class11sanskrit from './class 11/Ncert class 11 Sanskrit.jpg';
import class11antra from './class 11/Ncert class 11 antra.jpg';
import class11vitan from './class 11/Ncert class 11 vitan.jpg';
import class11cs from './class 11/Ncert class 11 computer science.jpeg';
import class11it from './class 11/Ncert class 11 Ip.jpg';
import class11bio from './class 11/Ncert class 11 Biology.jpg';
import class11accounts from './class 11/Ncert class 11 Acoounts.jpg';
import class11eco from './class 11/Ncert class 11 eco.jpg';
import class11history from './class 11/Ncert class 11 history.jpg';
import class11geo from './class 11/Ncert class 11 geo.jpg';
import class11polscience from  './class 11/Ncert class 11 poltical science.jpg';
import class11biotech from './class 11/Ncert class 11 biotechno.jpg';
import class11humanstud from './class 11/Ncert class 11 human std.jpg';
import class11humanstud2 from './class 11/Ncert class 11 human std 2.jpg';
import class11phyedu from './class 11/Ncert class 11 physicaledu.jpg';
import class11fineart from './class 11/Ncert class 11 fine art.jpg';
import class11srijan from './class 11/Ncert class 11 sangeet.jpg';
import class11sangeet from './class 11/Ncert class 11 sangeet.jpg'
import class11sciology from './class 11/Ncert class 11 sociology.jpg';


import class12physics1 from './class 12/Ncert class 12  Phy 1.jpg';
import class12maths from './class 12/Ncert class 12 maths.jpg';
import class12maths2 from './class 12/Ncert class 12  maths 2.jpg';
import class12physics2 from './class 12/Ncert class 12  Phy 2.jpg';
import class12chemistry1 from './class 12/Ncert class 12 Chemistry 1.jpg';
import class12chemsirty2 from './class 12/Ncert class 12 Chemistry 2.jpg';
import class12flamingo from './class 12/Ncert class 12 flamingo.jpg';
import class12vistas from './class 12/Ncert class 12 Vistas.jpg';
import class12sanskrit from './class 12/Ncert class 12 Sanskrit.jpg';
import class12antra from './class 12/Ncert class 12 Antra.jpg';
import class12vitan from './class 12/Ncert class 12 vitan.jpg';
import class12cs from './class 12/Ncert class 12 cs.jpg';
import class12it from './class 12/Ncert class 12 it.jpg';
import class12bio from './class 12/Ncert class 12 Bio.jpg';
import class12busstd from './class 12/Ncert class 12 busStudy 1.jpg';
import class12busstd2 from './class 12/Ncert class 12 busStudy 2.jpg';
import class12accounts from './class 12/Ncert class 12  Acc 1.jpg';
import class12accounts2 from './class 12/Ncert class 12  Acc 2.jpg';
import class12eco from './class 12/Ncert class 12 eco 1.jpg';
import class12eco2 from './class 12/Ncert class 12 eco 2.jpg';
import class12history from './class 12/Ncert class 12 History 1.jpg';
import class12history2 from './class 12/Ncert class 12 History 2.jpg';
import class12geo from './class 12/Ncert class 12 Geo.jpg';
import class12polscience from  './class 12/Ncert class 12 pol science.jpg';
import class12biotech from './class 12/Ncert class 12 BioTech.jpg';
import class12fineart from './class 12/Ncert class 12 fineart.jpg';
import class12srijan from './class 12/Ncert class 12 Srijan.jpg';
import class12sangeet from './class 12/Ncert class 12 Sangeet.jpg';
import class12sciology from './class 12/Ncert class 12 Sociology.jpg';
import class12humanstudy1 from './class 12/Ncert class 12 homeSc 1.jpg';
import class12humanstudy2 from './class 12/Ncert class 12 homeSc 2.jpg';
import class12pyscho from './class 12/Ncert class 12 Psycology.jpg';

let all_books=[
    {
        name:"Maths",
        class:"1",
        nm:1,
        image:class1maths
    },
    {
        name:"English",
        class:"1",
        image:class1english
    },{
        name:"Hindi",
        class:"1",
        image:class1hindi
    },{
        name:"Maths",
        class:"2",
        image:class2maths
    },{
        name:"English",
        class:"2",
        image:class2english
    },{
        name:"Hindi",
        class:"2",
        image:class2hindi
    },{
        name:"Maths",
        class:"3",
        image:class3maths
    },{
        name:"English",
        class:"3",
        image:class3english
    },{
        name:"Hindi",
        class:"3",
        image:class3hindi
    },{
        name:"Maths",
        class:"4",
        image:class4maths
    },{
        name:"Hindi",
        class:"4",
        image:class4hindi
    },{
        name:"English",
        class:"4",
        image:class4english
    },{
        name:"EVS",
        class:"4",
        image:class4evs
    },{
        name:"Maths",
        class:"5",
        image:class5maths
    },{
        name:"Hindi",
        class:"5",
        image:class5hindi
    },{
        name:"English",
        class:"5",
        image:class5english
    },{
        name:"EVS",
        class:"5",
        image:class5evs
    }
    ,{
        name:"Science",
        class:"6",
        image:class6science
    },{
        name:"English",
        class:"6",
        image:class6english
    },{
        name:"Hindi",
        class:"6",
        image:class6hindi
    },{
        name:"SST",
        class:"6",
        image:class6sst
    },{
        name:"Sanskrit",
        class:"6",
        image:class6sanskrti
    },{
        name:"Maths",
        class:"7",
        image:class7maths
    },{
        name:"Vasant",
        class:"7",
        image:class7hindi1
    },{
        name:"Mahabharat",
        class:"7",
        image:class7hindi2
    },{
        name:"Honeycomb",
        class:"7",
        image:class7english1
    },{
        name:"An Alien Hand",
        class:"7",
        image:class7english2
    },{
        name:"History",
        class:"7",
        image:class7history
    },{
        name:"Civics",
        class:"7",
        image:class7civics
    },{
        name:"Geography",
        class:"7",
        image:class7geo
    },{
        name:"Science",
        class:"7",
        image:class7science
    },
    {
        name:"Sanskrit",
        class:"7",
        image:class7sanskrit
    },{
        name:"Maths",
        class:"8",
        image:class8maths
    },{
        name:"Science",
        class:"8",
        image:class8science
    },{
        name:"Civics",
        class:"8",
        image:class8civics
    },{
        name:"History",
        class:"8",
        image:class8history
    },{
        name:"Geography",
        class:"8",
        image:class8geo
    },{
        name:"Honeydew",
        class:"8",
        image:class8english1
    },{
        name:"It so Happened",
        class:"8",
        image:class8english2
    },{
        name:"Vasant",
        class:"8",
        image:class8hindi1
    },{
        name:"Bharat Ki Khoj",
        class:"8",
        image:class8hindi2
    },{
        name:"Maths",
        class:"9",
        image:class9maths
    },{
        name:"Science",
        class:"9",
        image:class9science
    },{
        name:"Civics",
        class:"9",
        image:class9civics
    },{
        name:"History",
        class:"9",
        image:class9history
    },{
        name:"Geography",
        class:"9",
        image:class9geo
    },{
        name:"Economics",
        class:"9",
        image:class9eco
    },{
        name:"Beehive",
        class:"9",
        image:class9english1
    },{
        name:"Moments",
        class:"9",
        image:class9english2
    },{
        name:"Sanskrit",
        class:"9",
        image:class9sanskrit
    },{
        name:"Kritika",
        class:"9",
        image:class9hindi1
    },{
        name:"Shitiz",
        class:"9",
        image:class9hindi2
    },{
        name:"Maths",
        class:"10",
        image:class10maths
    },{
        name:"Science",
        class:"10",
        image:class10science
    },{
        name:"Footprint",
        class:"10",
        image:class10english1
    },{
        name:"First Flight",
        class:"10",
        image:class10english2
    },{
        name:"Shitiz",
        class:"10",
        image:class10hindi2
    },{
        name:"Kritika",
        class:"10",
        image:class10hindi1
    },{
        name:"History",
        class:"10",
        image:class10history
    },{
        name:"Geography",
        class:"10",
        image:class10geo
    },{
        name:"Civics",
        class:"10",
        image:class10civics
    },{
        name:"Economics",
        class:"10",
        image:class10eco
    },{
        name:"Sanskrit",
        class:"10",
        image:class10sanskrit
    },{
        name:"Hornbill",
        class:"11",
        image:class11hornbill
    },{
        name:"Snapshot",
        class:"11",
        image:class11snapshot
    },{
        name:"Antra",
        class:"11",
        image:class11antra
    },{
        name:"Vitan",
        class:"11",
        image:class11vitan
    },{
        name:"Maths",
        class:"11",
        image:class11maths
    },{
        name:"Chemistry Part 1",
        class:"11",
        image:class11chemistry1
    },{
        name:"Chemistry Part 2",
        class:"11",
        image:class11chemsirty2
    },{
        name:"Physics Part 1",
        class:"11",
        image:class11physics1
    },{
        name:"Physics Part 2",
        class:"11",
        image:class11physics2
    },{
        name:"Computer Science",
        class:"11",
        image:class11cs
    },{
        name:"Information Technology",
        class:"11",
        image:class11it
    },{
        name:"Biology",
        class:"11",
        image:class11bio
    },{
        name:"Accounts",
        class:"11",
        image:class11accounts
    },{
        name:"Bussiness Studies",
        class:"11",
        image:class11eco
    },{
        name:"Economics",
        class:"11",
        image:class11eco
    },{
        name:"History",
        class:"11",
        image:class11history
    },{
        name:"Geography",
        class:"11",
        image:class11geo
    },{
        name:"Political Science",
        class:"11",
        image:class11polscience
    },{
        name:"Bio Technology",
        class:"11",
        image:class11biotech
    },{
        name:"Human Studies 1",
        class:"11",
        image:class11humanstud
    },{
        name:"Human Studies 2",
        class:"11",
        image:class11humanstud2
    },{
        name:"Physical Education",
        class:"11",
        image:class11phyedu
    },{
        name:"Fine Art",
        class:"11",
        image:class11fineart
    },{
        name:"Srijan",
        class:"11",
        image:class11srijan
    },{
        name:"Sangeet",
        class:"11",
        image:class11sangeet
    },{
        name:"Sanskrit",
        class:"11",
        image:class11sanskrit
    },{
        name:"Sociology",
        class:"11",
        image:class11sciology
    },{
        name:"Flamingo",
        class:"12",
        image:class12flamingo
    },{
        name:"Vistas",
        class:"12",
        image:class12vistas
    },{
        name:"Antra",
        class:"12",
        image:class12antra
    },{
        name:"Vitan",
        class:"12",
        image:class12vitan
    },{
        name:"Maths 1",
        class:"12",
        image:class12maths
    },{
        name:"Maths 2",
        class:"12",
        image:class12maths2
    },{
        name:"Chemistry 1",
        class:"12",
        image:class12chemistry1
    },{
        name:"Chemistry 2",
        class:"12",
        image:class12chemsirty2
    },{
        name:"Physics 1",
        class:"12",
        image:class12physics1
    },{
        name:"Physics 2",
        class:"12",
        image:class12physics2
    },{
        name:"Computer Science",
        class:"12",
        image:class12cs
    },{
        name:"Information Technology",
        class:"12",
        image:class12it
    },{
        name:"Biology",
        class:"12",
        image:class12bio
    },{
        name:"Accounts 1",
        class:"12",
        image:class12accounts
    },{
        name:"Accounts 2",
        class:"12",
        image:class12accounts2
    },{
        name:"Bussiness Studies 1",
        class:"12",
        image:class12busstd
    },{
        name:"Bussiness Studies 2",
        class:"12",
        image:class12busstd2
    },{
        name:"Economics 1",
        class:"12",
        image:class12eco
    },{
        name:"Economics 2",
        class:"12",
        image:class12eco2
    },{
        name:"History 1",
        class:"12",
        image:class12history
    },{
        name:"History 2",
        class:"12",
        image:class12history2
    },{
        name:"Political Science",
        class:"12",
        image:class12polscience
    },{
        name:"Geography",
        class:"12",
        image:class12geo
    },{
        name:"Bio Technology",
        class:"12",
        image:class12biotech
    },{
        name:"Human Studies 1",
        class:"12",
        image:class12humanstudy1
    },{
        name:"Human Studies 2",
        class:"12",
        image:class12humanstudy2
    },{
        name:"Pyscology",
        class:"12",
        image:class12pyscho
    },{
        name:"Fine Art",
        class:"12",
        image:class12fineart
    },{
        name:"Srijan",
        class:"12",
        image:class12srijan
    },{
        name:"Sangeet",
        class:"12",
        image:class12sangeet
    },{
        name:"Sociology",
        class:"12",
        image:class12sciology
    }   
]
 export default all_books;