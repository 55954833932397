import { useContext } from "react"
import React from 'react'
import ChapterDisplay from "../Components/Chapter/ChapterDisplay"
import {Chaptercontext} from '../Context/Chapterscontext'
import './Chapter.css'

const Chapter = (props) => {
  const {all_chapters} = useContext(Chaptercontext);
  console.log(props.chapter);
  return (
    <div>
      <div className="main_c">
        <div className="chapter-heading">
        <h1>Class {props.class} {props.name}</h1>
        </div>
        <div className="chapters">
          {all_chapters.map((item,i)=>{
            if(props.class===item.class && props.name===item.name) {
              return <ChapterDisplay chapter={item.chapter}  chaptername={item.chaptername} pdf={item.pdf}/>;
            }else{
              return null;
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default Chapter