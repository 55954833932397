import React from "react";
import "./NoticeBoard.css";
import notice_logo from '../../Assets/notice logo.png'
import { Link } from "react-router-dom";
const NoticeBoard = () => {
  return (
    <div className="notice">
      <div className="notice-board">
        <img src={notice_logo} alt="notice"></img>
        <marquee>
          <Link style={{textDecoration:'none'}} to={'/chapterwisenotes/class7/An Alien Hand'}>
        • NCERT Class 7 English An Alien Hand Chapterwise Summary Uploaded 
        </Link><Link style={{textDecoration:'none'}} to={'/exercisesolutions/class7/An Alien Hand'}> &nbsp; &nbsp;&nbsp;•&nbsp;    NCERT Class 7 English An Alien Hand Excercise Solutions Uploaded</Link>
        </marquee>
      </div>
    </div>
  );
};

export default NoticeBoard;
