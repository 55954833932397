import React, { createContext } from "react";
import all_chapters from "../Assets/allchapters";
export const Chaptercontext= createContext(null);

const ChaptercontextProvider =(props)=>{

    const contextvalue = {all_chapters};
     return(
        <Chaptercontext.Provider value={contextvalue}>
            {props.children}
        </Chaptercontext.Provider>
     )
}
export default ChaptercontextProvider;