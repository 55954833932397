import React ,{useState} from 'react'
import './Feedback.css'
import cross from '../../Assets/cross.png'
import send from '../../Assets/paper-plane.png'
import angry from '../../Assets/angry.png'
import sad from '../../Assets/sad.png'
import neutral from '../../Assets/normal.png'
import happy from '../../Assets/happy.png'
import veryhappy from '../../Assets/very happy.png'
import { Link } from 'react-router-dom'
const Feedback = () => {
//     const [feedbackform , setfeedbackform] = useState(false);
//   const userfeedback =()=>{
//     feedbackform? setfeedbackform(false):setfeedbackform(true);
    
//   }
  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
  async function submittedclicked(){
    await delay(300);
    alert("Your feedback has been Submitted....")
  }

const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "dcb25ac1-c962-4fa9-bebe-b77988c2a08b");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <div className='main-feed'>
        <p className='main-feedhead'>Feedback Form</p>
        <div className='user-form'>
          <form onSubmit={onSubmit}>
            <div className='cross'><Link style={{textDecoration:'none'} } to={'/'}><img  src={cross} alt=''/></Link></div>
            <p className='basedon'>Based on your recent Experience, please Rate Us. This way we can improve our Website</p>
            


            <input type="radio" id='bad' name='rating' value="bad"></input>
            <label className='label-form' for="bad">Bad <img className='emoji' src={angry}/></label><br/>
            <input type='radio' id='Dislike' name='rating' value="Dislike"></input>
            <label className='label-form' for="Dislike">Dislike <img className='emoji' src={sad}/></label><br/>
            <input type='radio' id='Neutral' name='rating' value="Neutral"></input>
            <label className='label-form' for="Neutral">Neutral <img className='emoji' src={neutral}/></label><br/>
            <input type='radio' id='Like' name='rating' value="Like"></input>
            <label className='label-form' for="Like">Like <img className='emoji' src={happy}/></label><br/>
            <input type='radio' id='Happy' name='rating' value="Happy"></input>
            <label className='label-form' for="Happy">Happy <img className='emoji' src={veryhappy}/></label><br/>

            <p className='wouldulike'>Would you like to add a comment/suggestion?</p> 
            <textarea className='mesaage' placeholder='Write your Message/Suggestion Here...' name="message" rows="3" cols="30"></textarea>
            <div className='submit_div'><button className='submit-feedback' type="submit" onClick={submittedclicked}>Submit <img src={send} alt=''/></button></div>
          </form>
          {/* <Link style={{textDecoration:'none'} } to={'/'}></Link> */}
        </div>
    </div>
  )
}

export default Feedback