import React, { useState } from 'react'
import './Footer.css'
import phone from '../../Assets/telephone.png'
import email from '../../Assets/gmail.png'
import location from '../../Assets/placeholder.png'
import telegram from '../../Assets/telegram.png'
import instagram from '../../Assets/instagram (3).png'
import youtube from '../../Assets/youtube.png'
import cross from '../../Assets/cross.png'
import send from '../../Assets/paper-plane.png'
import angry from '../../Assets/angry.png'
import sad from '../../Assets/sad.png'
import neutral from '../../Assets/normal.png'
import happy from '../../Assets/happy.png'
import veryhappy from '../../Assets/very happy.png'
import feedback from '../../Assets/feedback.png'

const Footer = () => {
  const [mobileMenu , setMobileMenu] = useState(true);
  const toggleMenu =()=>{
    mobileMenu? setMobileMenu( false ) : setMobileMenu( true );
  }
  const [feedbackform , setfeedbackform] = useState(false);
  const userfeedback =()=>{
    feedbackform? setfeedbackform(false):setfeedbackform(true);
    
  }
  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
  async function submittedclicked(){
    await delay(300);
    alert("Your feedback has been Submitted....")
  }
  
  const submitted=()=>{
    userfeedback();
    submittedclicked();

  }
  const [ratingpoint , setratingpoint] = useState(true);
  const userrating1 = ()=>{
    ratingpoint? setratingpoint(false):setratingpoint(true);
  }
  const [ratingpoint2 , setratingpoint2] = useState(true);
  const userrating2 = ()=>{
    ratingpoint2? setratingpoint2(false):setratingpoint2(true);
  }
  const [ratingpoint3 , setratingpoint3] = useState(true);
  const userrating3 = ()=>{
    ratingpoint3? setratingpoint3(false):setratingpoint3(true);
  }
  const [ratingpoint4 , setratingpoint4] = useState(true);
  const userrating4 = ()=>{
    ratingpoint4? setratingpoint4(false):setratingpoint4(true);
  }
  const [ratingpoint5 , setratingpoint5] = useState(true);
  const userrating5 = ()=>{
    ratingpoint5? setratingpoint5(false):setratingpoint5(true);
  }
  const [ratingpoint6 , setratingpoint6] = useState(true);
  const userrating6 = ()=>{
    ratingpoint6?setratingpoint6(false):setratingpoint6(true);
  }
  const [ratingpoint7 , setratingpoint7] = useState(true);
  const userrating7 = ()=>{
    ratingpoint7? setratingpoint7(false):setratingpoint7(true);
  }
  const [ratingpoint8 , setratingpoint8] = useState(true);
  const userrating8 = ()=>{
    ratingpoint8? setratingpoint8(false):setratingpoint8(true);
  }
  const [ratingpoint9 , setratingpoint9] = useState(true);
  const userrating9 = ()=>{
    ratingpoint9? setratingpoint9(false):setratingpoint9(true);
  }
  const [ratingpoint10 , setratingpoint10] = useState(true);
  const userrating10 = ()=>{
    ratingpoint10? setratingpoint10(false):setratingpoint10(true);
  }



  const [result, setResult] = React.useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "dcb25ac1-c962-4fa9-bebe-b77988c2a08b");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <div className='main-footer'>
        <div className='sep'></div>
        {/* <div className='main-feedback' id="mainfeedback">
        <div className={feedbackform?'feedback': 'feedback feedback_righter' } onClick={userfeedback}>
          <p>F</p>
          <p>E</p>
          <p>E</p>
          <p>D</p>
          <p>B</p>
          <p>A</p>
          <p>C</p>
          <p>K</p>
        </div>
        <div className={feedbackform?'user-form ': 'user-form hide_feedback' }>
          <form onSubmit={onSubmit}>
            <div className='cross'><img  src={cross} alt=''  onClick={userfeedback}/></div>
            <p>Based on your recent Experience, please Rate Us. This way we can improve our Website</p>
            


            <input type="radio" id='bad' name='rating' value="bad"></input>
            <label className='label-form' for="bad">Bad <img className='emoji' src={angry}/></label><br/>
            <input type='radio' id='Dislike' name='rating' value="Dislike"></input>
            <label className='label-form' for="Dislike">Dislike <img className='emoji' src={sad}/></label><br/>
            <input type='radio' id='Neutral' name='rating' value="Neutral"></input>
            <label className='label-form' for="Neutral">Neutral <img className='emoji' src={neutral}/></label><br/>
            <input type='radio' id='Like' name='rating' value="Like"></input>
            <label className='label-form' for="Like">Like <img className='emoji' src={happy}/></label><br/>
            <input type='radio' id='Happy' name='rating' value="Happy"></input>
            <label className='label-form' for="Happy">Happy <img className='emoji' src={veryhappy}/></label><br/>

            <p className='wouldulike'>Would you like to add a comment/suggestion?</p> 
            <textarea className='mesaage' placeholder='Write your Message/Suggestion Here...' name="message" rows="2" cols="30"></textarea>
            <div className='submit_div'><button className='submit-feedback' type="submit" onClick={submitted}>Submit <img src={send} alt=''/></button></div>
          </form>
        </div>
        
      </div> */}
      <div className='links'>
        <div className='follow'>
            <h2>Follow Us</h2>
            <ul>
                <li><a href="https://www.instagram.com/saatvikstudies/" target="_blank"><img src={instagram}/>Instagram</a></li>
                <li><img src={youtube}/>Youtube</li>
                <li><a href="mailto: sss@saatvikstudies.com"><img src={email}/>Email</a></li>
                <li><a href="https://t.me/SaatvikStudies" target="_blank"><img src={telegram}/>Telegram</a></li>
            </ul>
        </div>
        <div className='contact'>
        <h2>Contact Us</h2>
            <ul>
                <li><img src={phone}/>Phone: <a href="tel:9773888338">+91 9773888338 </a>,<a href="tel:8383030389">+91 8383030389</a></li>
                <li><a href="mailto: sss@saatvikstudies.com"><img src={email}/>Email: sss@saatvikstudies.com</a></li>
                <li><img src={location}/>Address: 400 , SultanPur Dabas , N-delhi 110039</li>
            </ul>
        </div>
      </div>
      
      <div className='footer'>Copyright © 2024 SAATVIK STUDY SATION</div>
    </div>
  )
}

export default Footer
