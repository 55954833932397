import React from 'react'
import './ChapterDisplay.css'
import downlod from '../../Assets/downloads.png'
const ChapterDisplay=(props)=>{
    return (
        <div className='chapternames'>
          <div className='chapterName'>
          <p className="chpnum">{props.chapter}</p>
          <a href={props.pdf} target="_blank" >{props.chaptername} <img src={downlod}/></a>
          </div>
        </div>
      )
}
export default ChapterDisplay